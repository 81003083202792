import React, { useCallback } from 'react';

import { MenuItem } from '@szhsin/react-menu';
import { Avatar } from 'workmotion-design-system';

import { EmployeeResponseDTO } from 'types/employee';

const itemTextStyles: React.CSSProperties = {
    fontFamily: 'Work Sans',
    fontSize: 14,
    fontWeight: 400,
};

interface EmployeeMenuItemProps {
    employee: EmployeeResponseDTO;
    itemRef?: React.Ref<any>;
    handleSelectEmployee: (employee: EmployeeResponseDTO) => void;
}

const EmployeeMenuItem: React.FC<EmployeeMenuItemProps> = ({
    employee,
    itemRef,
    handleSelectEmployee,
    ...rest
}) => {
    const { id, firstName, lastName } = employee;

    const fullName = `${firstName} ${lastName}`;

    const onClick = useCallback(() => {
        handleSelectEmployee(employee);
    }, [handleSelectEmployee, employee]);

    return (
        <MenuItem
            data-testid={`employee-menu-item-${id}`}
            onClick={onClick}
            ref={itemRef}
            {...rest}
        >
            <Avatar text={{ title: `${fullName}` }} size="small" />
        </MenuItem>
    );
};

export { EmployeeMenuItem, itemTextStyles };
