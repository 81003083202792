import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { addBreadcrumb } from '@sentry/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useTimesheetFileAction } from 'networking/timesheet-api/timesheet-mutations';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import {
    getUploadTimesheetInitialValues,
    submitTimesheetValidation,
} from './formik-values-setup';
import { MonthPicker } from './month-picker';
import {
    errorBox,
    MainContainer,
    MainText,
    ModalButtonsFooterCustomStyle,
} from './styled-components';
import { TemplateHint } from './template-hint';
import { TimesheetsUploader } from './timesheet-uploader';
import { useUserIdentity } from '../../../../authentication/use-user-identity';

const {
    employeeTimesheet: {
        submitWithMonthModal: {
            modalTitle,
            mainText,
            cancelBtn,
            saveBtn,
            successMessage,
        },
        uploadModal: {
            modalFields: { month, timesheetFile },
        },
    },
} = en;

type ModalProps = {
    modalIsOpen: boolean;
    closeModal: () => void;
    timesheetsList: TimesheetListingResponseDTO[];
};

export const SubmitWithMonthModal: React.FC<ModalProps> = ({
    modalIsOpen,
    closeModal,
    timesheetsList,
}) => {
    const { isLoading, mutate } = useTimesheetFileAction(false); //that means that only upload possible from this modal
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const resetAndCloseModal = useCallback(() => {
        setSubmitDisabled(false);
        closeModal();
    }, [closeModal, setSubmitDisabled]);

    const handleSubmitTimesheet = useCallback(
        (timesheetData: any) => {
            addBreadcrumb({
                type: 'info',
                message: `start submitting file for ${
                    timesheetData[month.fieldName]
                }`,
            });

            const timesheetPlayload = {
                yearMonth: timesheetData[month.fieldName],
                timesheetFile: timesheetData[timesheetFile.fieldName][0],
            };

            mutate(timesheetPlayload, {
                onSuccess: () => {
                    toast(successMessage, {
                        type: 'success',
                    });

                    resetAndCloseModal();
                },
            });
        },
        [resetAndCloseModal, mutate]
    );

    const handleCancelBtn = useCallback(() => {
        resetAndCloseModal();
    }, [resetAndCloseModal]);

    const {
        data: { talentId },
    } = useUserIdentity();

    return (
        <Modal
            {...{
                isOpen: modalIsOpen,
                closeModal: handleCancelBtn,
                header: modalTitle,
            }}
        >
            <Formik
                initialValues={getUploadTimesheetInitialValues()}
                onSubmit={handleSubmitTimesheet}
                validationSchema={submitTimesheetValidation}
            >
                {({ values }) => (
                    <Form
                        style={{
                            width: '100%',
                            fontWeight: 400,
                        }}
                    >
                        <MainContainer>
                            <MainText>{mainText}</MainText>

                            <MonthPicker
                                timesheetsList={timesheetsList}
                                setSubmitDisabled={setSubmitDisabled}
                            />

                            <TimesheetsUploader />

                            {values['payroll-month'] != '' &&
                                !isSubmitDisabled && (
                                    <TemplateHint
                                        talentId={talentId}
                                        yearMonth={values['payroll-month']}
                                    />
                                )}

                            {isSubmitDisabled && (
                                <Box sx={errorBox}>
                                    This month is already submitted
                                </Box>
                            )}

                            <ModalButtonsFooter
                                placement="space-between"
                                buttonsInfo={[
                                    {
                                        text: cancelBtn,
                                        btnType: 'secondaryGrey',
                                        onClick: handleCancelBtn,
                                        disabled: isLoading,
                                        dataCy: 'cancel-btn',
                                    },
                                    {
                                        text: isLoading ? (
                                            <Spinner size={30} />
                                        ) : (
                                            saveBtn
                                        ),

                                        type: 'submit',
                                        dataCy: 'submit-btn',
                                        name: 'submit-btn',
                                        disabled: isSubmitDisabled,
                                    },
                                ]}
                                customStyle={ModalButtonsFooterCustomStyle}
                            />
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
