import { useCallback } from 'react';

import type { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

/**
 * This file should be extracted to the separate library in order to unify the access to the userIdentity.
 * This library should decorate the okta functionality plus shortcuts for easy access to the user roles.
 * The further steps should be discussed in the RFC format.
 *
 * This file is an enhanced version of the useUserIdentity.ts file from the onboarding-frontend repository.
 */
type UserGroup =
    | 'Everyone'
    | 'Candidates'
    | 'CompanyHrManagers'
    | 'Administrators'
    | 'Operators'
    | 'SuperAdmins'
    | 'Employees'
    | 'LineManagers'
    | 'InternalUsers'
    | 'Interns'
    | 'AddClient'
    | 'ViewClient'
    | 'UpdateClient'
    | 'DeleteClient'
    | 'AddTalent'
    | 'ViewTalent'
    | 'UpdateTalent'
    | 'DeleteTalent'
    | 'AutomationTestUsers'
    | 'PaymentAutomationAdmins'
    | 'ClearanceToolAdmin'
    | 'CompanyAdmin'
    | 'VendorManager'
    | 'ExportLTTRecords';

export interface WMUserClaims extends UserClaims {
    groups: UserGroup[];
    userType: 'internal';
    department: string;
    headers: Record<string, string>;
    /**
     * For HR only
     */
    companyId?: string;
}

export type AuthenticatedIdentity = {
    isAuthenticated: true;
    isAdmin: boolean;
    /**
     * @deprecated isClient is the same as isHrManager
     */
    isClient: boolean;
    isEmployee: boolean;
    isHrManager: boolean;
    isLineManager: boolean;
    isExternalLineManager: boolean;
    groups: UserGroup[];
    name: string;
    email: string;
    companyId?: string;
    talentId?: string;
};

export type UnAuthenticatedIdentity = {
    isAuthenticated: false;
};

export type UserIdentityDetails =
    | AuthenticatedIdentity
    | UnAuthenticatedIdentity;

export type AsyncResult<T> =
    | PendingAsyncResult
    | SuccessAsyncResult<T>
    | FailedAsyncResult;

type FailedAsyncResult = [isLoading: false, result: null, error: Error];

type SuccessAsyncResult<T> = [isLoading: false, result: T, error: null];

type PendingAsyncResult = [isLoading: true, result: null, error: null];

const isHrManager = (groups: UserGroup[]) =>
    groups.includes('CompanyHrManagers');

const isEmployee = (groups: UserGroup[]) =>
    groups.includes('Employees') || groups.includes('Candidates');

const isAdmin = (groups: UserGroup[]): boolean =>
    groups.includes('Administrators') ||
    groups.includes('SuperAdmins') ||
    groups.includes('Operators');

const isLineManager = (groups: UserGroup[]): boolean =>
    groups.includes('LineManagers');

const isCompanyAdmin = (groups: UserGroup[]) => groups.includes('CompanyAdmin');

const isExternalLineManger = (groups: UserGroup[]): boolean =>
    groups.length == 2 &&
    groups.includes('Everyone') &&
    groups.includes('LineManagers');

export function useUserIdentity() {
    const { authState, oktaAuth } = useOktaAuth();

    const loadUserInfo = useCallback(
        async () =>
            oktaAuth.getUser<WMUserClaims>().then(user => {
                Sentry.addBreadcrumb({
                    message: `User is changed`,
                    data: { user },
                    level: 'log',
                });

                if (user != null) {
                    Sentry.setUser({
                        ...user,
                        email: user.email,
                        username: user.preferred_username,
                    });
                } else {
                    Sentry.setUser(null);

                    return { isAuthenticated: false };
                }

                return {
                    isAuthenticated: authState.isAuthenticated,
                    isAdmin: isAdmin(user.groups),
                    isEmployee: isEmployee(user.groups),
                    isHrManager: isHrManager(user.groups),
                    isLineManager: isLineManager(user.groups),
                    isExternalLineManager: isExternalLineManger(user.groups),
                    isClient: isHrManager(user.groups),
                    isCompanyAdmin: isCompanyAdmin(user.groups),
                    email: user.email,
                    groups: user.groups,
                    name: user.name,
                    companyId: user.companyId,
                    talentId: isEmployee(user.groups)
                        ? (user.employeeId as string)
                        : null,
                };
            }),
        [authState?.isAuthenticated, oktaAuth]
    );

    return useQuery({
        queryKey: ['user-details'],
        queryFn: loadUserInfo,
        onError: Sentry.captureException,
        enabled: authState != null,
    });
}
