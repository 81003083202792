import React from 'react';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { ButtonProps } from 'shared-components/filters-menu/menu';

const StyledMenuButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${palette.greyscale.antiFlashWhite};
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 1rem;
    &:hover {
        background-color: ${palette.greyscale.grainOfSalt};
    }
    &:disabled {
        pointer-events: none;
    }
`;

const FiltersButton: React.FC<ButtonProps> = ({ onClick, buttonRef }) => (
    <StyledMenuButton
        ref={buttonRef}
        data-testid="filters-menu-button"
        onClick={onClick}
    >
        <FilterAltRoundedIcon
            style={{
                color: palette.greyscale.sharkMouth,
                width: '1rem',
                height: '1rem',
            }}
        />
    </StyledMenuButton>
);

export { FiltersButton };
