import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ActionsContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const FilterContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem',
    background: palette.secondary.white,
    borderRadius: '0.5rem 0.5rem 0 0',
    zIndex: 1,
    padding: '1.5rem',
    justifyContent: 'flex-end',
});
