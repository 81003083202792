import React, { useCallback } from 'react';

import { TimePickerRecord } from '../time-picker';

const RecordStartTime = ({
    id,
    onUpdate,
    label,
    disabled = false,
    dataCy = '',
    fieldValue = '',
    setIsDisabled,
}) => {
    const handleUpdateTime = useCallback(
        updatedEndTime => {
            onUpdate(updatedEndTime);
        },
        [onUpdate]
    );

    return (
        <TimePickerRecord
            label={label}
            id={`${id}+time-tracking-start-time`}
            dataCy={dataCy}
            fieldValue={fieldValue}
            onChange={handleUpdateTime}
            disabled={disabled}
            setIsDisabled={setIsDisabled}
        />
    );
};

export { RecordStartTime };
