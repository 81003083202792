import { useCallback } from 'react';

import { captureException, addBreadcrumb } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAccessToken } from 'authentication/use-access-token';
import { useUserIdentity } from 'authentication/use-user-identity';

import {
    uploadTimesheet,
    generateAndUploadTimesheet,
    editTimesheet,
    generateTemplate,
    generateTimesheetReport,
} from './timesheet-client';

export const useTimesheetFileAction = (edit: boolean) => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const {
        data: { talentId },
    } = useUserIdentity();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async ({
            yearMonth,
            timesheetFile,
            timesheetId,
        }: {
            yearMonth?: string;
            timesheetFile: File;
            timesheetId?: string;
        }) => {
            addBreadcrumb({
                type: 'info',
                message: `Timesheet file upload mutation`,
            });

            if (edit) {
                return editTimesheet(timesheetId, timesheetFile, accessToken);
            } else {
                return uploadTimesheet(
                    talentId as string,
                    yearMonth,
                    timesheetFile,
                    accessToken
                );
            }
        },
        [talentId, edit, accessToken]
    );

    return useMutation({
        mutationKey: ['uploadPost'],
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries(['TimeSheets']).catch(error => {
                captureException(error, {
                    tags: { request: 'invalidateQueries' },
                });
            });
        },
    });
};

export const useApproveDefaultTimesheet = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const {
        data: { talentId },
    } = useUserIdentity();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (month: string) =>
            generateAndUploadTimesheet(
                { talentId: talentId as string, yearMonth: month },
                accessToken
            ),
        [talentId, accessToken]
    );

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeSheets'])
                .catch(Sentry.captureException);
        },
    });
};

export const useGenerateTemplateTimesheet = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const {
        data: { talentId },
    } = useUserIdentity();

    const mutationFn = useCallback(
        async (month: string) =>
            generateTemplate(
                { talentId: talentId as string, yearMonth: month },
                accessToken
            ),
        [talentId, accessToken]
    );

    return useMutation({
        mutationFn,
    });
};

export const useGenerateTimesheetReport = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const mutationFn = useCallback(
        async ({
            yearMonth,
            countryIsoCode,
            companyId,
        }: {
            yearMonth?: string;
            countryIsoCode?: string;
            companyId: string;
        }) =>
            generateTimesheetReport(
                { yearMonth, countryIsoCode, companyId },
                accessToken
            ),
        [accessToken]
    );

    return useMutation({
        mutationFn,
    });
};
