import React, { useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

const MenuContainer = styled.div`
    position: relative;
`;

const Content = styled.div<{ buttonHeight: number; buttonWidth: number }>`
    position: absolute;
    transform: scaleY(0);
    transition: all 0.2s ease;
    visibility: hidden;
    &[data-alignment='bottom'] {
        transform-origin: top;
        top: ${({ buttonHeight }) => buttonHeight + 2}px;
        left: ${({ buttonWidth }) => buttonWidth / 2}px;
        transform: translateX(-50%);
    }
    &[data-alignment='bottom-right'] {
        transform-origin: top;
        top: ${({ buttonHeight }) => buttonHeight + 2}px;
        right: 0;
    }
    &[data-alignment='bottom-left'] {
        transform-origin: top;
        top: ${({ buttonHeight }) => buttonHeight + 2}px;
        left: 0;
    }
    &[data-is-open='true'] {
        visibility: visible;
        transform: scaleY(1);
    }
`;

type MenuAlignment = 'bottom' | 'bottom-left' | 'bottom-right';

interface ButtonProps {
    onClick: () => void;
    buttonRef: React.MutableRefObject<any>;
}

interface MenuProps {
    Button: React.FC<ButtonProps>;
    alignment?: MenuAlignment;
    className?: string;
    isOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Menu: React.FC<MenuProps> = ({
    children,
    Button,
    alignment = 'bottom',
    className,
    isOpen,
    setIsMenuOpen,
    onClose,
}) => {
    const buttonRef = useRef<HTMLButtonElement>();
    const contentRef = useRef<HTMLDivElement>();

    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);

        if (onClose) {
            onClose();
        }
    }, [onClose, setIsMenuOpen]);

    const handleClickOutside = useCallback(
        event => {
            if (
                contentRef.current &&
                !contentRef.current.contains(event.target)
            ) {
                closeMenu();
            }
        },
        [closeMenu]
    );

    const toggleMenu = useCallback(
        () =>
            setIsMenuOpen(prev => {
                if (!prev && onClose) {
                    onClose();
                }

                return !prev;
            }),
        [setIsMenuOpen, onClose]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return (
        <MenuContainer ref={contentRef}>
            <Button buttonRef={buttonRef} onClick={toggleMenu} />

            <Content
                className={className}
                buttonHeight={buttonRef.current?.clientHeight || 40}
                buttonWidth={buttonRef.current?.clientWidth || 40}
                data-alignment={alignment}
                data-is-open={isOpen}
            >
                {children}
            </Content>
        </MenuContainer>
    );
};

export { Menu };

export type { ButtonProps, MenuProps };
