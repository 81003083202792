/* eslint-disable import/named */
import { FormikValues } from 'formik';
import { string as yupString, object as yupObject } from 'yup';

import { getFormattedIsoDate } from 'app/shared/date-utils';
import { en } from 'localization';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

const {
    employeeTimeTracking: {
        createTimeTrackingModal: {
            fields: { startTime, endTime, date },
            validationMessages: { clockInRequired, dateRequired },
        },
    },
} = en;

interface CreateTimeTrackingRecord extends FormikValues {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [startTime.fieldName]: string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [endTime.fieldName]: string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [date.fieldName]: string;
}

const CreateTimeTrackingInitialValues: CreateTimeTrackingRecord = {
    talentId: '',
    [startTime.fieldName]: '09:00',
    [endTime.fieldName]: '',
    [date.fieldName]: getFormattedIsoDate(new Date()),
};

const CreateTimeTrackingSchema = yupObject().shape({
    [startTime.fieldName]: yupString().required(clockInRequired),

    [date.fieldName]: yupString().required(dateRequired),
});

const getInitialValues = (
    selected: TimeTrackingRecordResponseDTO | undefined
): CreateTimeTrackingRecord => {
    if (selected) {
        return {
            recordType: selected.recordType,
            startTime: selected.startTime,
            endTime: selected.endTime,
            talentId: selected.talentId,
            date: selected.date,
            breaks: selected.breaks,
        };
    }

    return CreateTimeTrackingInitialValues;
};

export { CreateTimeTrackingSchema, getInitialValues };

export type { CreateTimeTrackingRecord };
