import { useCallback, useEffect, useState } from 'react';

export const useWindowSize = (
    {
        mobileWidth,
    }: {
        mobileWidth: number;
    } = { mobileWidth: 1160 }
): { isMobileSize: boolean } => {
    const [isMobileSize, setIsMobileSize] = useState(
        window.innerWidth <= mobileWidth
    );

    const update = useCallback(() => {
        setIsMobileSize(window.innerWidth <= mobileWidth);
    }, [mobileWidth]);

    useEffect(() => {
        window.addEventListener('resize', update);

        return () => window.removeEventListener('resize', update);
    }, [update]);

    return {
        isMobileSize,
    };
};
