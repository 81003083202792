import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import { FeatureFlagsWrapper } from '@workmotion/feature-flags-library';
import { BrowserRouter } from 'react-router-dom';
import { Spinner } from 'workmotion-design-system';

import { StyledToastContainer } from 'design-components/styled-toast-container';

import { AppRoutes } from './app';
import { AuthenticationContext } from './authentication/authentication-context';
import { NetworkContext } from './networking/network-context';
import { ErrorBoundary } from './tracking/error-boundary';

const WrappedRootComponent: React.FC = () => (
    <Suspense fallback={<Spinner />}>
        <NetworkContext>
            <BrowserRouter>
                <AuthenticationContext>
                    <ErrorBoundary>
                        <FeatureFlagsWrapper>
                            <AppRoutes />
                        </FeatureFlagsWrapper>
                    </ErrorBoundary>
                </AuthenticationContext>
            </BrowserRouter>
        </NetworkContext>

        <StyledToastContainer icon={false} />
    </Suspense>
);

export default Sentry.withProfiler(WrappedRootComponent);
