import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { toast } from 'react-toastify';
import { Pagination } from 'workmotion-design-system';

import { DefaultFiltersMenu } from 'app/shared/default-menu';
import { FiltersContainer } from 'app/shared/filters-container';
import { useUserIdentity } from 'authentication/use-user-identity';
import {
    COUNTRY_FILTER,
    MONTH_FILTER,
    SUPPORTED_LISTING_PARAMS,
    TALENT_ID,
} from 'constant';
import { DownloadBtn } from 'design-components/download-btn';
import { getCurrentCompanyId } from 'helpers/get-current-company';
import { useDeepCompareMemoize } from 'helpers/use-deep-compare-memoize';
import { usePagination } from 'helpers/use-pagination';
import { en } from 'localization';
import { useGenerateTimesheetReport } from 'networking/timesheet-api/timesheet-mutations';
import { useTimesheetRequests } from 'networking/timesheet-api/timesheet-network-requests';
import { ApiPaginationData } from 'types';
import { EmployeeResponseDTO } from 'types/employee';

import { EmployeeFilter } from './employee-filter';
import { TimesheetList } from './timesheet-list';

const {
    managedTimeSheetList: { downloadReportBtn },
} = en;

export const ManageTimesheetView: React.FC = () => {
    const [pagesInfo, setPagInfo] = useState<ApiPaginationData>({});

    const filters = [
        TALENT_ID,
        MONTH_FILTER,
        COUNTRY_FILTER,
        ...SUPPORTED_LISTING_PARAMS,
    ];

    const {
        handleChangeFilter,
        removeFilter,
        currentPage,
        requestParams,
        currentPageSize,
        handleChangePage,
        handleChangePageSize,
        removeFilters,
        handleChangeFilters,
    } = usePagination(filters);

    const selectedCompanyId = getCurrentCompanyId();

    const {
        data: { isAdmin, companyId: userCompanyId },
    } = useUserIdentity();

    const companyId = useMemo(() => {
        if (isAdmin) {
            return selectedCompanyId;
        }

        return userCompanyId;
    }, [isAdmin, selectedCompanyId, userCompanyId]);

    const requestParamsFormatted = useDeepCompareMemoize(
        companyId
            ? {
                  ...requestParams,
                  companyId,
              }
            : requestParams
    );

    const onEmployeeSelect = useCallback(
        (employee: EmployeeResponseDTO) => {
            handleChangeFilter(TALENT_ID, employee.id);
        },
        [handleChangeFilter]
    );

    const onEmployeeRemove = useCallback(
        () => removeFilter(TALENT_ID),
        [removeFilter]
    );

    const { data, isLoading } = useTimesheetRequests({
        ...requestParamsFormatted,
    });

    const { mutate: generateTimesheetReport, isLoading: reportIsLoading } =
        useGenerateTimesheetReport();

    const handleDownloadReport = useCallback(() => {
        generateTimesheetReport(
            {
                yearMonth: requestParamsFormatted.yearMonth || undefined,
                countryIsoCode:
                    requestParamsFormatted.countryIsoCode || undefined,
                companyId: requestParamsFormatted.companyId || undefined,
            },
            {
                onSuccess: report => {
                    window.open(report.downloadUrl, '_blank');
                },
                onError: (err: any) => {
                    toast(err, {
                        type: 'error',
                    });
                },
            }
        );
    }, [generateTimesheetReport, requestParamsFormatted]);

    useEffect(() => {
        if (data?.page) setPagInfo(data?.page);
    }, [data?.page]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <DownloadBtn
                    isLoading={reportIsLoading}
                    download={handleDownloadReport}
                    title={downloadReportBtn}
                    dataCy={'DOWNLOAD-REPORT-BTN'}
                />
            </div>

            <FiltersContainer>
                <EmployeeFilter
                    onSelect={onEmployeeSelect}
                    onRemove={onEmployeeRemove}
                />

                <DefaultFiltersMenu
                    handleChangeFilters={handleChangeFilters}
                    removeFilters={removeFilters}
                    requestParams={requestParamsFormatted}
                    withCountry
                />
            </FiltersContainer>

            <TimesheetList
                isLoading={isLoading}
                timesheetData={data?.content}
            />

            {!!pagesInfo?.totalElements && !isLoading && (
                <Pagination
                    total={pagesInfo?.totalElements}
                    currentPage={parseInt(currentPage, 10)}
                    onChange={(selected: number) => handleChangePage(selected)}
                    pageSize={parseInt(currentPageSize)}
                    onPageSizeChanged={handleChangePageSize}
                />
            )}
        </>
    );
};
