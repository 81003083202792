import React from 'react';

import { captureException, addBreadcrumb } from '@sentry/react';
import { useField, useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { FileUploader, FormField } from 'workmotion-design-system';

import { en } from 'localization';

import { CreateTimesheetSchema } from './formik-values-setup';

const {
    employeeTimesheet: {
        uploadModal: {
            filePlaceholder,
            modalFields: {
                timesheetFile: { label, fieldName },
            },
        },
    },
} = en;

const XLS_EXTENSIONS =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const TimesheetsUploader: React.FC = () => {
    const [field, meta] = useField(fieldName);

    const { setFieldValue } = useFormikContext<CreateTimesheetSchema>();

    const handleOnChange = (filesArray: File[]) => {
        addBreadcrumb({
            type: 'info',
            message: `FileUploader: onDrop fn started , ${
                filesArray.length && filesArray[0].name
            }`,
        });

        try {
            setFieldValue(fieldName, filesArray);
        } catch (error) {
            captureException(error, {
                tags: { component: 'TimesheetsUploader' },
            });

            toast.error('Something went wrong, please try again later');
        }
    };

    return (
        <FormField
            errorMessage={meta.error}
            showError={!!(meta.touched && meta.error)}
        >
            <FileUploader
                files={field.value}
                onDrop={handleOnChange}
                label={`${label}*`}
                multiple={false}
                maxSize={3145728}
                texts={{
                    placeholderText: filePlaceholder,
                    errorMessages: { key: meta.error },
                }}
                fileExtension={XLS_EXTENSIONS}
                dataTestId="timesheet-uploader"
                attrs={{ input: { 'data-testid': 'input-uploader' } }}
            />
        </FormField>
    );
};
