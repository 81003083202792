import React, { RefObject, useEffect, useRef } from 'react';

import type { MenuState } from '@szhsin/react-menu';
import { ControlledMenu } from '@szhsin/react-menu';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';
import styled from 'styled-components';
import { Spinner } from 'workmotion-design-system';

import { useElementOnScreen } from 'helpers/use-element-on-screen';
import { en } from 'localization';
import { ApiPaginationData } from 'types';
import { EmployeeResponseDTO } from 'types/employee';

import { EmployeeMenuItem, itemTextStyles } from './employe-menu-item';

const Menu = styled(ControlledMenu)({
    [menuSelector.name]: {
        minHeight: 40,
        maxHeight: 240,
        overflowY: 'auto',
        zIndex: 3000,
        marginTop: '1rem !important',
    },
    [menuItemSelector.name]: {
        height: 40,
        zIndex: 3000,
    },
});

interface SearchResultsMenuProps {
    anchorRef: RefObject<HTMLDivElement> | null;
    menuState: MenuState;
    results: EmployeeResponseDTO[] | undefined;
    pageInfo: ApiPaginationData;
    isLoading: boolean;
    searchTerm: string;
    fetchEmployees: (
        searchTerm: string,
        page?: number
    ) => EmployeeResponseDTO[] | null;
    handleSelectEmployee: (employee: EmployeeResponseDTO) => void;
    setEmployees: React.Dispatch<
        React.SetStateAction<EmployeeResponseDTO[] | null>
    >;
}

const SearchResultsMenu: React.FC<SearchResultsMenuProps> = ({
    anchorRef,
    menuState,
    results,
    pageInfo,
    isLoading,
    searchTerm,
    fetchEmployees,
    handleSelectEmployee,
    setEmployees,
}) => {
    const {
        managedTimeSheetList: {
            employeeFilter: { noEmployeesFound },
        },
    } = en;

    const menuBoxRef = useRef();

    const { elementRef: lastEmployeeMenuItemRef, isVisible } =
        useElementOnScreen<HTMLDivElement>({
            root: menuBoxRef?.current,
        });

    useEffect(() => {
        if (isVisible && !pageInfo?.last) {
            (async () => {
                const content = await fetchEmployees(
                    searchTerm,
                    (pageInfo?.number || 0) + 1
                );

                if (content) {
                    setEmployees(prev => (prev ? [...prev, ...content] : null));
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, fetchEmployees, searchTerm]);

    return (
        <Menu
            anchorRef={anchorRef}
            state={menuState}
            ref={menuBoxRef}
            captureFocus={false}
        >
            {!!results && !results.length && !isLoading && (
                <span
                    style={{
                        height: 40,
                        ...itemTextStyles,
                        padding: '0.375rem 1.5rem',
                    }}
                >
                    {noEmployeesFound}
                </span>
            )}

            {!!results?.length &&
                results.map((el, index, arr) => (
                    <EmployeeMenuItem
                        itemRef={
                            index === arr.length - 1
                                ? lastEmployeeMenuItemRef
                                : null
                        }
                        key={el.id}
                        employee={el}
                        handleSelectEmployee={handleSelectEmployee}
                    />
                ))}

            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spinner data-testid="employees-loading" size={32} />
                </div>
            )}
        </Menu>
    );
};

export { SearchResultsMenu };
