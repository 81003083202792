import React from 'react';

import { Fallback } from 'workmotion-design-system';

import { en } from 'localization';

import NoTimeTrackingRecords from './empty-time-tracking-illustrations.png';

const {
    employeeTimeTracking: {
        noTimeTrackingRecords: { title, message },
    },
} = en;

export const NoTimeTracking: React.FC = () => (
    <Fallback
        content={
            <div style={{ marginTop: '2rem' }}>
                <img
                    src={NoTimeTrackingRecords}
                    alt="No Time Tracking Illustration"
                />
            </div>
        }
        title={title}
        subtitle={message}
    />
);
