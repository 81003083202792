import React from 'react';

import { en } from 'localization';

import ActionSection from '../actions-section';
import BreakSection from '../break-section';
import { ScheduleType } from '../constant';
import {
    StyledContainer,
    StyledDayHeader,
    StyledDayTitle,
    StyledTimeContainer,
} from '../styled-components';
import TimeSection from '../time-section';

const {
    employeeTimeTracking: {
        defineTimeTrackingScheduleModal: {
            fields: { breakStartTime, breakEndTime, startTime, endTime },
            breakLabel,
            copyAll,
            addBreakLabel,
        },
    },
} = en;

interface DayProps {
    day?: ScheduleType;
    title?: string;
    hasCopyToAll?: boolean;
    copyToAll?: (day: string) => void;
    addBreak?: (day: string) => void;
    removeBreak?: (breakIndex: number) => void;
    onDayChange?: (field: string, value: string) => void;
    onBreakChange?: (field: string, value: string, breakIndex: number) => void;
    setIsDisabled?: (isDisabled: boolean) => void;
}

export const Day: React.FC<DayProps> = ({
    title,
    day,
    copyToAll,
    hasCopyToAll,
    addBreak,
    removeBreak,
    onDayChange,
    onBreakChange,
    setIsDisabled,
}: DayProps) => (
    <StyledContainer>
        <StyledDayHeader>
            <StyledDayTitle>
                <div>{title}</div>
            </StyledDayTitle>
        </StyledDayHeader>

        <StyledTimeContainer>
            <TimeSection
                day={day}
                title={title}
                startTime={startTime}
                endTime={endTime}
                onDayChange={onDayChange}
                setIsDisabled={setIsDisabled}
            />

            <BreakSection
                day={day}
                title={title}
                breakLabel={breakLabel}
                breakStartTime={breakStartTime}
                breakEndTime={breakEndTime}
                onBreakChange={onBreakChange}
                removeBreak={removeBreak}
                setIsDisabled={setIsDisabled}
            />

            <ActionSection
                title={title}
                addBreakLabel={addBreakLabel}
                hasCopyToAll={hasCopyToAll}
                copyToAll={copyToAll}
                addBreak={addBreak}
                copyAll={copyAll}
            />
        </StyledTimeContainer>
    </StyledContainer>
);
