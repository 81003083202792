import { useCallback, useEffect, useState } from 'react';

import type { AccessToken } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export function useAccessToken(): {
    accessToken: AccessToken;
    errorHandler: (error: Error) => void;
} {
    const { authState, oktaAuth } = useOktaAuth();

    const [accessToken, setAccessToken] = useState<AccessToken>(
        authState.accessToken as AccessToken
    );

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const initialTokenValue = (await oktaAuth.tokenManager.get(
                    'accessToken'
                )) as AccessToken;

                console.debug('Token initialized: ', {
                    token: initialTokenValue,
                });

                oktaAuth.tokenManager.on('renewed', (key, token, oldToken) => {
                    if (key === 'accessToken') {
                        console.debug(
                            'Updating access token because of the renewal.',
                            {
                                token,
                                oldToken,
                            }
                        );

                        setAccessToken(token as AccessToken);
                    }
                });

                setAccessToken(initialTokenValue);
            } catch (e) {
                console.debug('Handling error from getting access token');
                console.error(e);
            }
        };

        getAccessToken();
    }, [oktaAuth.tokenManager]);

    /**
     * It should be an error handler for 401 and 403 requests.
     */
    const errorHandler = useCallback(
        error => {
            if (error && error.errorCode === 'login_required') {
                return oktaAuth.signOut();
            }

            console.error(error);
        },
        [oktaAuth]
    );

    return { accessToken, errorHandler };
}
