import React from 'react';

import styled from 'styled-components';

import { DayIcon } from './day-icon';
import { Days } from '../constant';

const StyledDaysScheduleContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledTitle = styled.div({
    color: '#00234B',
});

const StyledDaysIconsContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.75rem',
});

interface DaysScheduleProps {
    days: string[];
    addDay?: (day: string) => void;
    removeDay?: (day: string) => void;
}

export const DaysSchedule: React.FC<DaysScheduleProps> = (
    props: DaysScheduleProps
) => (
    <StyledDaysScheduleContainer>
        <StyledTitle>Select days</StyledTitle>

        <StyledDaysIconsContainer>
            {Days.map((day: { label: string; value: string }) => (
                <DayIcon
                    key={day.value}
                    day={day}
                    addDay={() => {
                        props.addDay(day.value);
                    }}
                    removeDay={() => {
                        props.removeDay(day.value);
                    }}
                    isSelectedDay={props.days.includes(day.value)}
                />
            ))}
        </StyledDaysIconsContainer>
    </StyledDaysScheduleContainer>
);
