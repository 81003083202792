import React, { useState } from 'react';

import styled from 'styled-components';
import { Button, palette } from 'workmotion-design-system';

const StyledDayButton = styled(Button)<{ selected: boolean }>`
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
        props.selected
            ? palette.primary.genericViridian
            : palette.tertiary.aquaHaze};
    color: ${props =>
        props.selected
            ? palette.secondary.white
            : palette.primary.genericViridian};
`;

interface DayIconProps {
    day: { label: string; value: string };
    addDay?: (day: string) => void;
    removeDay?: (day: string) => void;
    isSelectedDay: boolean;
}

export const DayIcon: React.FC<DayIconProps> = ({
    addDay,
    removeDay,
    day,
    isSelectedDay,
}: DayIconProps) => {
    const [isSelected, setIsSelected] = useState(isSelectedDay);

    const onDayClick = (selectedDay: string) => {
        setIsSelected(!isSelected);

        if (isSelected) {
            removeDay?.(selectedDay);
        } else {
            addDay?.(selectedDay);
        }
    };

    return (
        <StyledDayButton
            selected={isSelected}
            onClick={() => onDayClick(day.value)}
        >
            {day.label}
        </StyledDayButton>
    );
};
