import React, { useCallback } from 'react';

import { SelectAutocomplete } from 'workmotion-design-system';
import type { SelectOption } from 'workmotion-design-system';
interface CountryFilterProps {
    value: string;
    onCountryChange: (value: string) => void;
    options: SelectOption<string>[];
}

const CountryFilter: React.FC<CountryFilterProps> = ({
    value,
    onCountryChange,
    options,
}) => {
    const onChange = useCallback(
        (optionValue: string) => {
            onCountryChange(optionValue);
        },
        [onCountryChange]
    );

    return (
        <SelectAutocomplete
            options={options}
            value={options?.find(el => el?.value === value)?.value}
            onChange={onChange}
            id="Country-Filter-input"
        />
    );
};

export { CountryFilter };
