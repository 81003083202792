import React from 'react';

import { Box } from '@mui/material';

import { en } from 'localization';

import {
    CardContentBox,
    ConfirmationCardContainer,
    ConfirmationCardContent,
    ConfirmationCardContentInfo,
    ConfirmationCardTitle,
    ConfirmationCardWorkingHours,
} from './styled-components';
import { InfoSideMenu } from '../info-side-menu';
import { InfoDescription } from '../info-side-menu/info-description';

const {
    employeeTimeTracking: {
        confirmationCard: { title, content, hours, contentInfo },

        sideMenuInfo: { informationTitle },
    },
} = en;

export const ConfirmationBanner: React.FC<{
    name: string;
    workingHours: number;
    dataCy: string;
}> = ({ name, workingHours, dataCy = '' }) => (
    <ConfirmationCardContainer data-testid={dataCy}>
        <Box sx={CardContentBox}>
            <ConfirmationCardTitle>{title(name)}</ConfirmationCardTitle>

            <>
                <>
                    <ConfirmationCardContent>
                        {content}

                        <ConfirmationCardWorkingHours>
                            {workingHours}
                        </ConfirmationCardWorkingHours>

                        {hours}
                    </ConfirmationCardContent>

                    <ConfirmationCardContentInfo>
                        {contentInfo}

                        <InfoSideMenu sideMenuTitle={informationTitle}>
                            <InfoDescription />
                        </InfoSideMenu>
                    </ConfirmationCardContentInfo>
                </>
            </>
        </Box>
    </ConfirmationCardContainer>
);
