import React, { LabelHTMLAttributes, PropsWithChildren } from 'react';

import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

const StyledLabel = styled.label({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '12px',
});

const StyledFieldError = styled.span({
    fontSize: '.75rem',
    color: `${palette.semantic.red}`,
    marginTop: '.5rem',
    marginBottom: '-1.25rem',
    alignSelf: 'flex-start',
});

interface LabelProps
    extends PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>> {
    name?: string;
    optional?: boolean;
    infoIcon?: React.ReactNode;
    textStyles?: React.CSSProperties;
}

const defaultProps: LabelProps = {
    name: '',
    optional: false,
    infoIcon: undefined,
};

const FieldLabel: React.FC<LabelProps> = ({
    name,
    children,
    infoIcon,
    optional = false,
    textStyles,
    ...props
}) => (
    <StyledLabel htmlFor={name} {...props}>
        <span
            style={{
                color: palette.greyscale.persianBlue,
                fontSize: '.875rem',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                ...textStyles,
            }}
        >
            {children}

            {infoIcon}
        </span>

        {optional && (
            <span
                style={{
                    color: palette.greyscale.sharkMouth,
                    fontSize: '0.875rem',
                    marginTop: 7,
                }}
            >
                (Optional)
            </span>
        )}
    </StyledLabel>
);

const FieldErrorMessage: React.FC<
    PropsWithChildren<Record<string, unknown>>
> = ({ children, ...rest }) => (
    <StyledFieldError {...rest}>{children}</StyledFieldError>
);

FieldLabel.defaultProps = defaultProps;

export { FieldLabel, FieldErrorMessage };
