import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useSubmitTimeTrackingRecordsStatusV2 } from 'networking/timesheet-api/time-tracking-mutations';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

interface ModalProps {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedTimeTrackingRecordList: TimeTrackingRecordResponseDTO[];
}

const SubmitRecordConfirmationModal: React.FC<ModalProps> = ({
    closeModal,
    modalIsOpen,
    selectedTimeTrackingRecordList,
}) => {
    const {
        employeeTimeTracking: {
            submitTimeTrackingRecordsConfirmationModal: {
                title,
                subtitle,
                cancelButton,
                confirmButton,
                sumbitSuccessMessage,
            },
        },
    } = en;

    const { mutate: submitRecordsStatus, isLoading: isSubmitRecordsLoading } =
        useSubmitTimeTrackingRecordsStatusV2();

    const handleDeleteTimeTrackingRecord = () => {
        const recordIds = selectedTimeTrackingRecordList.map(
            record => record?.id
        );

        submitRecordsStatus(
            {
                recordIds,
            },
            {
                onSuccess: () => {
                    toast(sumbitSuccessMessage, {
                        type: 'success',
                    });

                    closeModal();
                },
            }
        );
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            closeModal={closeModal}
            defaultContent={{
                title,
                text: subtitle,
                icon: <CheckIcon />,
            }}
            type="info"
            footer={
                <ModalButtonsFooter
                    placement="space-between"
                    buttonsInfo={[
                        {
                            text: cancelButton,
                            btnType: 'secondaryGrey',
                            disabled: isSubmitRecordsLoading,
                            onClick: () => closeModal(),
                        },
                        {
                            text: isSubmitRecordsLoading ? (
                                <Spinner size={30} />
                            ) : (
                                confirmButton
                            ),
                            btnType: 'default',
                            onClick: handleDeleteTimeTrackingRecord,
                        },
                    ]}
                />
            }
        />
    );
};

export default SubmitRecordConfirmationModal;
