export const Days = [
    {
        label: 'S',
        value: 'SUNDAY',
    },
    {
        label: 'M',
        value: 'MONDAY',
    },
    {
        label: 'T',
        value: 'TUESDAY',
    },
    {
        label: 'W',
        value: 'WEDNESDAY',
    },
    {
        label: 'T',
        value: 'THURSDAY',
    },
    {
        label: 'F',
        value: 'FRIDAY',
    },
    {
        label: 'S',
        value: 'SATURDAY',
    },
];

export type ScheduleType = {
    [key: string]: {
        startTime: string;
        endTime: string;
        isSelected?: boolean;
        breaks: {
            startTime: string;
            endTime: string;
        }[];
    };
};

export const scheduleArray = [
    {
        MONDAY: {
            startTime: '09:00',
            endTime: '17:00',
        },
    },
    {
        TUESDAY: {
            startTime: '09:00',
            endTime: '17:00',
        },
    },
    {
        WEDNESDAY: {
            startTime: '09:00',
            endTime: '17:00',
        },
    },
    {
        THURSDAY: {
            startTime: '09:00',
            endTime: '17:00',
        },
    },
    {
        FRIDAY: {
            startTime: '09:00',
            endTime: '17:00',
        },
    },
];

export const daysOfWeek = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
];
