import React from 'react';

import * as Sentry from '@sentry/react';
import { ErrorFallback } from 'workmotion-design-system';

export const ErrorBoundary = ({ children }) => (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        {children}
    </Sentry.ErrorBoundary>
);
