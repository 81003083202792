// eslint-disable-next-line import/named
import { SelectOption } from 'workmotion-design-system';

const ALL_QUERY_PARAM = 'all';
const DEFAULT_CURRENT_PAGE = '1';
const DEFAULT_PAGE_SIZE = 10;

const EMPLOYEE_ID_FILTER = 'employeeId';
const TALENT_ID = 'talentId';
const MONTH_FILTER = 'yearMonth';
const STATUS_FILTER = 'status';
const TYPE_FILTER = 'recordType';
const TIME_TRACKING_PATH = '/time-tracking';

const COUNTRY_FILTER = 'countryIsoCode';

const SUPPORTED_LISTING_PARAMS = [
    'page',
    'size',
    'orderBy',
    'direction',
    'assigneeId',
    'subjectPersonId',
    'subjectCompany',
    'triggerPersonId',
    'states',
    'updatedAfter',
    'unassigned',
];

const TIME_TRACKING_RECORD_FILTER_STATUS = {
    ALL: 'All statuses',
    PENDING: 'Pending',
    SUBMITTED: 'Submitted',
};

enum MenuType {
    FILTER = 'FILTER',
    EXPORT = 'EXPORT',
}

const TIME_TRACKING_RECORD_TYPE = {
    ALL: 'All types',
    WORKING_DAY: 'Working day',
    NON_WORKING_DAY: 'Non-working day',
};

const COUNTRY_OPTIONS: SelectOption<string>[] = [
    {
        label: 'All Countries',
        value: 'all',
    },
    {
        label: 'Austria',
        value: 'AT',
    },
    {
        label: 'Croatia',
        value: 'HR',
    },
    {
        label: 'Czech Republic',
        value: 'CZ',
    },
    {
        label: 'Denmark',
        value: 'DK',
    },
    {
        label: 'Finland',
        value: 'FI',
    },
    {
        label: 'France',
        value: 'FR',
    },
    {
        label: 'Germany',
        value: 'DE',
    },
    {
        label: 'Hungary ',
        value: 'HU',
    },
    {
        label: 'Italy',
        value: 'IT',
    },
    {
        label: 'Latvia',
        value: 'LV',
    },
    {
        label: 'Poland',
        value: 'PL',
    },
    {
        label: 'Portugal',
        value: 'PT',
    },
    {
        label: 'Romania',
        value: 'RO',
    },
    {
        label: 'Serbia',
        value: 'RS',
    },
    {
        label: 'Slovakia',
        value: 'SK',
    },
    {
        label: 'Spain',
        value: 'ES',
    },
];

export {
    DEFAULT_PAGE_SIZE,
    SUPPORTED_LISTING_PARAMS,
    DEFAULT_CURRENT_PAGE,
    ALL_QUERY_PARAM,
    EMPLOYEE_ID_FILTER,
    TALENT_ID,
    MONTH_FILTER,
    COUNTRY_FILTER,
    TIME_TRACKING_RECORD_FILTER_STATUS,
    TIME_TRACKING_RECORD_TYPE,
    STATUS_FILTER,
    TYPE_FILTER,
    TIME_TRACKING_PATH,
    COUNTRY_OPTIONS,
    MenuType,
};
