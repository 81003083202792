import React from 'react';

import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import './tracking/setup';
import Root from './root.component';

const lifecycles = singleSpaReact<{ name: string; containerId: string }>({
    React,
    ReactDOM,
    rootComponent: Root,
    domElementGetter: ({ containerId }) => document.getElementById(containerId),
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        return null;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
