import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'constant';
import { getQueryParams } from 'helpers/url';
import { QueryParameters } from 'types';

export interface MapFilter {
    [key: string]: string;
}

interface UsePaginationReturn {
    filter?: MapFilter;
    currentPage: string | null;
    requestParams: QueryParameters;
    currentPageSize: string | null;
    updateURL?: () => void;
    handleChangePage: (newPage: number) => void;
    handleChangeFilter: (name: string, value: string) => void;
    handleChangeFilters?: (filters: MapFilter) => void;
    removeFilter?: (name: string) => void;
    removeFilters?: (name: string[]) => void;
    removeAllFilters?: () => void;
    handleChangePageSize: (newPageSize: number) => void;
}

const usePagination = (
    supportedParamsArray?: Array<string>,
    pageSize?: number,
    entityName?: string
): UsePaginationReturn => {
    const location = useLocation();
    const navigate = useNavigate();

    const [filter, setFilter] = useState<MapFilter>({});

    const urlParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );

    const paramObject = Object.fromEntries(urlParams);

    const currentPageSize = useMemo(
        () => String(urlParams.get('size') || pageSize || DEFAULT_PAGE_SIZE),
        [urlParams, pageSize]
    );

    const queryParams: QueryParameters & { unassigned?: boolean } = useMemo(
        () => getQueryParams(urlParams, supportedParamsArray, pageSize),
        [urlParams, supportedParamsArray, pageSize]
    );

    const updateURL = useCallback(() => {
        // remove explicit page (if default) for cleaner url (getQueryParams() will default to page DEFAULT_CURRENT_PAGE)
        if (urlParams.get('page') === `${DEFAULT_CURRENT_PAGE}`) {
            urlParams.delete('page');
        }

        navigate({
            pathname: location.pathname,
            search: `?${urlParams}`,
        });
    }, [navigate, location.pathname, urlParams]);

    const currentPage = useMemo(
        () => urlParams.get('page') || DEFAULT_CURRENT_PAGE,
        [urlParams]
    );

    const handleChangePage = useCallback(
        (newPage: number) => {
            urlParams.set('page', newPage.toString());
            updateURL();
        },
        [updateURL, urlParams]
    );

    const handleChangePageSize = useCallback(
        (newPageSize: number) => {
            urlParams.set('size', newPageSize.toString());

            handleChangePage(1);
        },
        [handleChangePage, urlParams]
    );

    const handleChangeFilter = (name: string, value: string) => {
        const selectedFilter = { [name]: value };
        setFilter(selectedFilter);
        handleChangePage(1);

        urlParams.set(name, value);

        updateURL();
    };

    const handleChangeFilters = (filters: MapFilter) => {
        if (filters) {
            setFilter(filters);
            handleChangePage(1);

            Object.keys(filters).forEach(key => {
                urlParams.set(key, filters[key]);
            });

            updateURL();
        }
    };

    const removeFilters = useCallback(
        (names: string[]) => {
            const currentFilters = { ...filter };

            if (names) {
                names.forEach(name => {
                    delete currentFilters[name];
                    urlParams.delete(name);
                });
            }

            setFilter(currentFilters);

            updateURL();
        },
        [filter, urlParams, updateURL]
    );

    const removeAllFilters = useCallback(() => {
        removeFilters(Object.keys(filter));
    }, [filter, removeFilters]);

    const removeFilter = useCallback(
        (name: string) => {
            removeFilters([name]);
        },
        [removeFilters]
    );

    // TODO think to enhance
    const getOrderByParam = useMemo(() => {
        if (entityName === 'action-items') {
            return 'state,updatedDate';
        }

        return queryParams?.orderBy || undefined;
    }, [entityName, queryParams?.orderBy]);

    const getDirectionParam = useMemo(() => {
        if (entityName === 'action-items') {
            return 'desc, desc';
        }

        return queryParams?.direction || undefined;
    }, [entityName, queryParams?.direction]);

    const requestParams = useMemo(() =>
        // handles pagination to be mapped as in Backend starts from index 0 so need to subtract 1
        {
            const params = {
                ...queryParams,
                orderBy: getOrderByParam,
                direction: getDirectionParam,
            };

            Object.keys(queryParams).forEach(key => {
                if (key === 'page') {
                    params.page = queryParams?.page ? queryParams?.page - 1 : 0;
                }

                if (
                    ['assigneeId', 'dedicatedAdminId', 'states'].includes(key)
                ) {
                    if (
                        queryParams[key as keyof QueryParameters] ===
                        'unassigned'
                    ) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        params[key as keyof QueryParameters] = undefined;
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        params.unassigned = true;
                    } else {
                        (params[key as keyof QueryParameters] as string) =
                            queryParams[key as keyof QueryParameters] as string;

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        params.unassigned = undefined;
                    }
                }
            });

            return params;
        }, [getDirectionParam, getOrderByParam, queryParams]);

    useEffect(() => {
        Object.keys(paramObject).forEach(key => {
            if (filter[key] !== paramObject[key]) {
                setFilter({ ...filter, [key]: paramObject[key] });
            }
        });
    }, [filter, paramObject, removeFilter]);

    return {
        filter,
        currentPage,
        requestParams: requestParams as QueryParameters,
        currentPageSize,
        updateURL,
        handleChangePage,
        handleChangeFilter,
        handleChangeFilters,
        handleChangePageSize,
        removeFilter,
        removeFilters,
        removeAllFilters,
    };
};

export { usePagination };

export type { UsePaginationReturn };
