import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const MainContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    gap: '2rem',
});

export const MainText = styled.h1({
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.5rem',
    color: palette.greyscale.persianBlue,
    marginBottom: '0.5rem',
});

export const SubText = styled.h2({
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    color: palette.greyscale.UCLABlue,
    marginBottom: '2rem',
});

export const ModalButtonsFooterCustomStyle = {
    padding: '2rem 0 0',
    margin: '0 0',
    borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
};

export const errorBox = {
    color: palette.semantic.red,
    marginBottom: '2rem',
};

export const StyledAddEntryWrapper = styled.div`
    color: ${palette.primary.genericViridian};
    font-size: 14px;
    line-height: 16.42px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
    width: fit-content;

    &:hover {
        cursor: pointer; /* Change cursor to pointer on hover */
    }
`;

export const StyledCopyToAllWrapper = styled.div`
    color: ${palette.semantic.red};
    font-size: 14px;
    line-height: 16.42px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
    width: fit-content;
    &:hover {
        cursor: pointer; /* Change cursor to pointer on hover */
    }
`;

export const IconWrapper = styled.span`
    margin-right: 4px;
    margin-bottom: 2px;
    display: flex;
    font-size: 13px;
    align-items: center;
    cursor: pointer;
`;

export const BreaksHeader = styled.div`
    margin-bottom: 1.5rem;
`;

export const StyledDayHeader = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '1rem',
    gap: '0.5rem',
    borderRadius: '0.5rem 0.5rem 0 0',
    height: '3rem',
    padding: '1rem',
    background: palette.greyscale.antiFlashWhite,
    textTransform: 'uppercase',
    fontWeight: '600',
    marginBottom: '1rem',
});

export const StyledDayTitle = styled.div({
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    '#title-list': {
        display: 'flex',
        gap: '0.5rem',
        '::before': {
            content: "'•'",
            fontSize: '1rem',
        },
    },
});

export const StyledContainer = styled.div({
    border: `0.1rem solid ${palette.greyscale.antiFlashWhite}`,
    borderTop: 'none',
    borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
});

export const StyledTimeContainer = styled.div({
    margin: '1rem',
});

export const StyledBreakHeaderContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

export const StyledBreakTitle = styled.div({
    color: `${palette.primary.genericViridian}`,
    fontWeight: 600,
});

export const StyledRemoveIcon = styled.div({
    color: `${palette.semantic.red}`,
    width: '2.5rem',
    height: '2.5rem',
});
