import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { MONTH_FILTER } from 'constant';
import { QueryParameters } from 'types';

import {
    downloadTimeTrackingRecordsV2,
    getTimeTrackingRecords,
    getTimeTrackingSchedule,
} from './time-tracking-client';
import { useAccessToken } from '../../authentication/use-access-token';

export const useTimeTrackingRecords = (params: QueryParameters) => {
    const { accessToken } = useAccessToken();
    params.orderBy = MONTH_FILTER;

    const getAllTimeTrackingRecords = useCallback(
        async () => getTimeTrackingRecords(params, accessToken.accessToken),
        [accessToken?.accessToken, params]
    );

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['TimeTrackingRecords', params],
        queryFn: getAllTimeTrackingRecords,
        enabled: !!params,
        staleTime: 0,
    });

    return { data, isLoading, refetch };
};

export const useTalentTimeTrackingSchedule = (talentId: any) => {
    const { accessToken } = useAccessToken();

    const getTalentTimeTrackingSchedule = useCallback(
        async () => getTimeTrackingSchedule(talentId, accessToken.accessToken),
        [accessToken?.accessToken, talentId]
    );

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['TimeTrackingSchedule', talentId],
        queryFn: getTalentTimeTrackingSchedule,
        staleTime: 0,
    });

    return { data, isLoading, refetch };
};

export const useDownloadTimeTrackingRecords = (fileKey: string) => {
    const { accessToken } = useAccessToken();

    const getTalentTimeTrackingSchedule = useCallback(
        async () =>
            downloadTimeTrackingRecordsV2(fileKey, accessToken.accessToken),
        [accessToken.accessToken, fileKey]
    );

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['TimeTrackingRecords', fileKey],
        queryFn: getTalentTimeTrackingSchedule,
        staleTime: 0,
    });

    return { data, isLoading, refetch };
};
