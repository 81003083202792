import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { FieldLabel } from './label';

const StyledContainer = styled.div({
    width: '100%',
});

const StyledLabel = styled(FieldLabel)({
    fontSize: '0.875rem',
    display: 'flex',
    minWidth: 150,
    marginBottom: '12px',
    color: palette.greyscale.UCLABlue,
});

const StyledFieldContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    '@media(max-width: 480px)': {
        marginBottom: '1rem',
    },
});

export { StyledContainer, StyledLabel, StyledFieldContainer };
