import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledDatePickerWrapper = styled.div`
    width: 100%;
    height: 42px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid ${palette.greyscale.antiFlashWhite};
    border-radius: 8px;
    padding: 0 8px 0 2px;
    color: ${palette.greyscale.persianBlue};
    background: ${palette.greyscale.ghostWhite};

    &[data-has-error='true'] {
        border-color: #ff1744;
    }

    & .react-datepicker-wrapper {
        width: 100%;
        > div > input {
            width: 100%;
            height: 40px;
            border: 0;
            border-radius: 8px;
            color: ${palette.greyscale.persianBlue};
            font-size: 0.875rem;
            height: 40px;
            padding: 14px 14px;
            cursor: pointer;
            outline: transparent;
            background-color: ${palette.greyscale.ghostWhite};
        }
        > div > input::placeholder {
            color: ${palette.greyscale.sharkMouth};
        }
    }

    & .react-datepicker__triangle {
        display: none;
    }
    & > div > .react-datepicker-popper > div > .react-datepicker {
        border: 1px solid ${palette.greyscale.UCLABlue};
        border-radius: 8px;
        width: 100%;
        color: ${palette.greyscale.UCLABlue};

        & .react-datepicker__month-container {
            & .react-datepicker__header {
                background-color: ${palette.greyscale.ghostWhite};
                border-bottom: 0;
                & > div > div.react-datepicker__day-name {
                    color: ${palette.greyscale.UCLABlue};
                    font-width: 400;
                    margin: 2px 4px;
                }
            }

            & .react-datepicker__current-month {
                color: ${palette.greyscale.UCLABlue};
            }

            & .react-datepicker__header__dropdown {
                margin-top: 12px;
                color: ${palette.greyscale.UCLABlue};
            }

            & .react-datepicker__month {
                & .react-datepicker__week {
                    & > div.react-datepicker__day {
                        color: ${palette.greyscale.UCLABlue};
                        font-width: 400;
                        margin: 2px 4px;
                    }

                    &
                        > div.react-datepicker__day.react-datepicker__day--disabled {
                        cursor: default;
                        color: #ccc;
                    }

                    & > div.react-datepicker__day--selected,
                    & > div.react-datepicker__day--keyboard-selected {
                        background-color: ${palette.primary.genericViridian};
                        color: ${palette.secondary.white};
                        border-radius: 4px;
                        outline: transparent;
                    }
                    & > div.react-datepicker__day:hover {
                        border-radius: 4px;
                        background-color: ${palette.tertiary.aquaHaze};
                    }
                }
            }
        }
    }
`;

export const CustomDatePickerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    font-size: 0.75rem;
`;

export const CustomPlaceholder = styled.span`
    color: ${palette.greyscale.sharkMouth};
`;

export const CustomLabel = styled.label`
    color: ${palette.greyscale.persianBlue};
`;
