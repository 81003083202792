import React from 'react';

import { TimeTrackingRecordStatusDTO } from 'types/timeTracking';

import { StyledStatusLabel } from '../styled-components';

export const StatusLabel: React.FC<{
    status: TimeTrackingRecordStatusDTO;
}> = ({ status }) => (
    <StyledStatusLabel
        as="div"
        style={{ cursor: 'auto' }}
        data-status={status?.toLocaleLowerCase()}
    >
        {status}
    </StyledStatusLabel>
);
