import styled from 'styled-components';

const FiltersContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '2rem',
    padding: '1.5rem',
    background: '#FFFFFF',
    borderRadius: '0.5rem 0.5rem 0 0',
});

export { FiltersContainer };
