import { Document, OpenAPIClientAxios } from 'openapi-client-axios';

import { Client } from 'networking/employee-api/employee-client-types';

import ClientDefinition from './employee-contract.json';

export async function createClient(accessToken: string) {
    if (accessToken == null) {
        return;
    }

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
    };

    const api = new OpenAPIClientAxios({
        definition: ClientDefinition as Document,
        axiosConfigDefaults: {
            headers,
            baseURL: window.env.API_BASE_URL,
            /**
             * We need these parameters to solve internal library issues.
             * Check for version updates in order to fix it
             */
            transitional: {},
        },
    });

    await api.init();

    return api.getClient<Client>();
}
