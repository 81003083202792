import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { Button, palette, Spinner } from 'workmotion-design-system';

import { formatUTCDate } from 'app/shared/date-utils';
import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import { useTalentDetails } from 'networking/employee-api/employee-network-requests';
import { useApproveDefaultTimesheet } from 'networking/timesheet-api/timesheet-mutations';

import SkylarIcon from './skylar.svg';
import {
    ConfirmationCardContainer,
    ConfirmationCardTitle,
    ConfirmationCardContent,
    CardContentBox,
    ButtonsBox,
} from './styled-components';

const WORKING_HOURS = {
    FULL_TIME: 40,
    PART_TIME: 20,
};

const {
    employeeTimesheet: {
        confirmationCard: {
            title,
            content,
            cancelButton,
            confirmButton,
            submittedMonthMessage,
            contentInfo,
        },
        uploadModal: { successMessage },
    },
} = en;

interface ConfirmationBannerProps {
    month: string;
    openModal: (attr: boolean) => void;
    isSubmitted: boolean;
}

export const ConfirmationBanner: React.FC<ConfirmationBannerProps> = ({
    month,
    openModal,
    isSubmitted,
}) => {
    const { isLoading, mutate } = useApproveDefaultTimesheet();

    const currentMonthText = formatUTCDate(month, 'MONTH');

    const {
        data: { name, talentId },
    } = useUserIdentity();

    const talentDetails = useTalentDetails(talentId);

    const confirmButtonAction = useCallback(() => {
        mutate(month, {
            onSuccess: () => {
                toast(successMessage, {
                    type: 'success',
                });
            },
            onError: (err: any) => {
                toast(err, {
                    type: 'error',
                });
            },
        });
    }, [mutate, month]);

    return (
        <ConfirmationCardContainer>
            <SkylarIcon />

            <Box sx={CardContentBox}>
                {name && (
                    <ConfirmationCardTitle>{title(name)}</ConfirmationCardTitle>
                )}

                {isSubmitted && (
                    <ConfirmationCardContent>
                        {submittedMonthMessage(currentMonthText)}
                    </ConfirmationCardContent>
                )}

                {!isSubmitted && (
                    <>
                        {talentDetails?.data && (
                            <>
                                <ConfirmationCardContent>
                                    {content(
                                        WORKING_HOURS[
                                            talentDetails?.data.contractInfo
                                                .employmentType
                                        ],
                                        currentMonthText
                                    )}
                                </ConfirmationCardContent>

                                <ConfirmationCardContent>
                                    {contentInfo}
                                </ConfirmationCardContent>
                            </>
                        )}

                        <Box sx={ButtonsBox}>
                            <Button
                                btnType="transparent"
                                style={{
                                    borderColor: palette.greyscale.UCLABlue,
                                    color: palette.greyscale.UCLABlue,
                                }}
                                onClick={() => openModal(true)}
                                data-testid="upload-own-btn"
                            >
                                {cancelButton}
                            </Button>

                            <Button
                                style={{
                                    width: '11.875rem',
                                }}
                                onClick={confirmButtonAction}
                                data-testid="confirm-btn"
                            >
                                {isLoading ? (
                                    <Spinner
                                        size={30}
                                        data-testid="confirm-spinner"
                                    />
                                ) : (
                                    confirmButton
                                )}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </ConfirmationCardContainer>
    );
};
