import React from 'react';

import { DeleteForeverRounded } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useDeleteTimeTrackingRecord } from 'networking/timesheet-api/time-tracking-mutations';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

interface ModalProps {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedTimeTrackingRecord: TimeTrackingRecordResponseDTO;
}

const DeleteRecordModal: React.FC<ModalProps> = ({
    closeModal,
    modalIsOpen,
    selectedTimeTrackingRecord,
}) => {
    const {
        employeeTimeTracking: {
            deleteTimeTrackingModal: {
                title,
                subtitle,
                cancelButton,
                deleteButton,
                deleteErrorMessage,
                deleteSuccessMessage,
            },
        },
    } = en;

    const { isLoading: isDeleteRecordLoading, mutate: deleteRecord } =
        useDeleteTimeTrackingRecord();

    const handleDeleteTimeTrackingRecord = () => {
        deleteRecord(selectedTimeTrackingRecord?.id, {
            onSuccess: () => {
                toast(deleteSuccessMessage, {
                    type: 'success',
                });
            },
            onError: () => {
                toast(deleteErrorMessage, {
                    type: 'error',
                });
            },
        });

        closeModal();
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            closeModal={closeModal}
            defaultContent={{
                title,
                text: subtitle.replace('{{name}}', 'this record'),
                icon: <DeleteForeverRounded />,
            }}
            type="warning"
            footer={
                <ModalButtonsFooter
                    placement="space-between"
                    buttonsInfo={[
                        {
                            text: cancelButton,
                            btnType: 'secondaryGrey',
                            disabled: isDeleteRecordLoading,
                            onClick: () => closeModal(),
                        },
                        {
                            text: isDeleteRecordLoading ? (
                                <Spinner size={30} />
                            ) : (
                                deleteButton
                            ),
                            btnType: 'error',
                            onClick: handleDeleteTimeTrackingRecord,
                        },
                    ]}
                />
            }
        />
    );
};

export default DeleteRecordModal;
