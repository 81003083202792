import React, { useState, useCallback } from 'react';

import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { CountryFilter } from 'app/shared/country-filter';
import { DateFilter } from 'app/time-sheet/employee-view/filter';
import { COUNTRY_OPTIONS, MenuType } from 'constant';
import { MapFilter } from 'helpers/use-pagination';
import { en } from 'localization';
import { useExportTimeTrackingRecordsV2 } from 'networking/timesheet-api/time-tracking-mutations';
import { FiltersMenu } from 'shared-components/filters-menu';

const ExportRecordsMenu = () => {
    const {
        employeeTimeTracking: { exportMessage },
    } = en;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const currentDate = format(new Date(), 'yyyy-MM-dd');

    const [yearMonth, setYearMonth] = useState<string>(currentDate);
    const defaultCountry = COUNTRY_OPTIONS[1]?.value;

    const [countryIsoCode, setCountryIsoCode] =
        useState<string>(defaultCountry);

    const [exportFilters, setExportFilters] = useState<MapFilter>({});

    const handleChangeFilters = useCallback(
        (filters: MapFilter) => {
            if (filters) {
                setExportFilters(filters);
            }
        },
        [setExportFilters]
    );

    const removeFilters = useCallback(
        (names: string[]) => {
            const currentFilters = { ...exportFilters };

            if (names) {
                names.forEach(name => {
                    delete currentFilters[name];
                });
            }

            setExportFilters(currentFilters);
        },
        [exportFilters]
    );

    const onMonthSelect = useCallback((date: string) => {
        setYearMonth(date);
    }, []);

    const onCountryChange = useCallback((value: string) => {
        setCountryIsoCode(value);
        setIsMenuOpen(true);
    }, []);

    const { mutate: exportRecords } = useExportTimeTrackingRecordsV2();

    const resetFilters = useCallback(() => {
        setYearMonth(currentDate);
        setCountryIsoCode(defaultCountry);
        setIsMenuOpen(false);
    }, [currentDate, defaultCountry]);

    const applyFilters = useCallback(() => {
        let params;

        if (yearMonth) {
            params = { ...params, yearMonth };
        }
        if (countryIsoCode && countryIsoCode !== 'all')
            params = { ...params, countryIsoCode };

        if (countryIsoCode && countryIsoCode === 'all')
            removeFilters(['countryIsoCode']);

        handleChangeFilters(params);

        exportRecords(
            {
                ...params,
            },
            {
                onSuccess: () =>
                    toast(exportMessage, {
                        type: 'success',
                    }),
            }
        );

        setIsMenuOpen(false);
        resetFilters();
    }, [
        countryIsoCode,
        handleChangeFilters,
        removeFilters,
        yearMonth,
        exportRecords,
        resetFilters,
        exportMessage,
    ]);

    const onMenuClose = useCallback(() => {}, []);

    return (
        <FiltersMenu
            isOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            alignment="bottom-right"
            onReset={resetFilters}
            onApply={applyFilters}
            onClose={onMenuClose}
            menuType={MenuType.EXPORT}
        >
            <CountryFilter
                value={countryIsoCode}
                onCountryChange={onCountryChange}
                options={COUNTRY_OPTIONS.slice(1)}
            />

            <DateFilter
                onMonthSelect={onMonthSelect}
                initialSearchValue={yearMonth}
            />
        </FiltersMenu>
    );
};

export { ExportRecordsMenu };
