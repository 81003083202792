import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { QueryParameters } from 'types';

import { fetchAllEmployees, getTalentDetails } from './employee-client';
import { useAccessToken } from '../../authentication/use-access-token';

export const useEmployeeRequests = () => {
    const { accessToken } = useAccessToken();

    const getEmployees = async (params: QueryParameters) =>
        fetchAllEmployees(accessToken?.accessToken, params);

    return { getEmployees };
};

export const useTalentDetails = (talentId: string) => {
    const { accessToken } = useAccessToken();

    const queryFn = useCallback(
        async () => getTalentDetails(talentId, accessToken.accessToken),
        [talentId, accessToken.accessToken]
    );

    return useQuery({
        queryKey: ['contract-details', talentId],
        queryFn,
        enabled: !!talentId,
    });
};
