import React, { useCallback } from 'react';

import { format, formatISO } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import { DatePicker } from 'workmotion-design-system';

import { CreateTimeTrackingRecord } from './formik-values-setup';
import { FieldErrorMessage } from './label';
import {
    StyledContainer,
    StyledFieldContainer,
    StyledLabel,
} from './modal-field-components';

export const RecordDatePicker: React.FC<{
    fieldName: string;
    label: string;
    id: string;
    dataCy: string;
    fieldValue?: string;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
}> = ({
    fieldName,
    fieldValue,
    label,
    minDate,
    maxDate,
    id,
    dataCy,
    disabled = false,
}) => {
    const [field, meta, helpers] = useField(fieldName);

    const { value, name } = field;
    const showError = !!(meta.touched && meta.error);

    const { setFieldValue, setFieldError } =
        useFormikContext<CreateTimeTrackingRecord>();

    const isValidIsoDate = useCallback((date: Date) => {
        try {
            formatISO(date, {
                representation: 'date',
            });

            return true;
        } catch {
            return false;
        }
    }, []);

    const onChange = useCallback(
        (date: Date) => {
            if (isValidIsoDate(date)) {
                setFieldValue(name, String(format(date, 'yyyy-MM-dd')));
            } else {
                setFieldError(name, 'invalidDate');
            }
        },
        [name, setFieldValue, isValidIsoDate, setFieldError]
    );

    const mapValueGMT = (dateValue: string) => {
        const options = { timeZone: 'GMT' };

        return new Date(new Date(dateValue).toLocaleString('en-US', options));
    };

    return (
        <StyledFieldContainer>
            <StyledLabel>{label}</StyledLabel>

            <StyledContainer data-testid={id}>
                <DatePicker
                    {...field}
                    key={field?.name}
                    value={
                        (value && mapValueGMT(value)) ||
                        mapValueGMT(fieldValue) ||
                        new Date()
                    }
                    onChange={onChange}
                    onBlur={() => helpers.setTouched(true)}
                    minDate={minDate || null}
                    maxDate={maxDate || null}
                    id={id}
                    dataCy={dataCy}
                    InputProps={{ type: 'date' }}
                    placeholder="dd/mm/yyyy"
                    hasError={!!showError}
                    disabled={disabled}
                    {...{ label }}
                />

                {showError && (
                    <FieldErrorMessage>{meta.error}</FieldErrorMessage>
                )}
            </StyledContainer>
        </StyledFieldContainer>
    );
};
