import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { MONTH_FILTER } from 'constant';
import { QueryParameters } from 'types';

import { generateTimeSheetTemplates, getTimeSheets } from './timesheet-client';
import { useAccessToken } from '../../authentication/use-access-token';

export const useTimesheetRequests = (params: QueryParameters) => {
    const { accessToken } = useAccessToken();
    params.orderBy = MONTH_FILTER;

    const getAllTimeSheets = useCallback(
        async () => getTimeSheets(params, accessToken.accessToken),
        [accessToken?.accessToken, params]
    );

    const { data, isLoading } = useQuery({
        queryKey: ['TimeSheets', params],
        queryFn: getAllTimeSheets,
        enabled: !!params,
    });

    return { data, isLoading };
};

export const useTimeSheetDrafts = (talentId: string, yearMonth?: string) => {
    const { accessToken } = useAccessToken();

    const queryFn = useCallback(
        async () =>
            generateTimeSheetTemplates(
                talentId,
                yearMonth,
                accessToken.accessToken
            ),
        [talentId, yearMonth, accessToken.accessToken]
    );

    return useQuery({
        queryKey: ['time-sheet', 'drafts', talentId, yearMonth],
        queryFn,
        enabled: yearMonth != '',
    });
};
