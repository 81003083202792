import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { handleRequestError } from 'helpers/handle-request-error';
import { en } from 'localization';
import { RequestError } from 'types';

const {
    generic: { genricError },
} = en;

export const workmotionDefaultOptions = {
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 60 * 60 * 1000,
            refetchOnWindowFocus: false,
            onError(error: RequestError) {
                if (error?.response?.status === 401) {
                    location.replace(location.href);
                } else handleRequestError(error, genricError);
            },
        },
        mutations: {
            onError(error: RequestError) {
                if (error?.response?.status === 401) {
                    location.replace(location.href);
                } else handleRequestError(error, genricError);
            },
        },
    },
};

const queryClient = new QueryClient(workmotionDefaultOptions);

export const NetworkContext = ({ children }) => (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
