import { ScheduleType, daysOfWeek } from './constant';

export const sortScheduleDays = (schedule, days) =>
    schedule.sort((a, b) => {
        const dayA = Object.keys(a)[0];
        const dayB = Object.keys(b)[0];

        return days.indexOf(dayA) - days.indexOf(dayB);
    });

export const mapScheduleRequest = schedule =>
    schedule.reduce((acc, day) => {
        const dayKey = Object.keys(day)[0];
        const dayValue = day[dayKey];
        const { isSelected, ...rest } = dayValue;

        return {
            ...acc,
            [dayKey]: rest,
        };
    }, {});

export const mapScheduleResponse = schedule => {
    const mappedScheduleArray = Object.entries(schedule).map(
        ([day, newSchedule]) => ({
            [day]: {
                ...(newSchedule as object),
                isSelected: true,
            },
        })
    );

    return sortScheduleDays(mappedScheduleArray, daysOfWeek);
};

export const formatScheduleArray = scheduleArray =>
    scheduleArray.map(day => {
        const dayKey = Object.keys(day)[0];
        const dayValue = day[dayKey];

        return {
            [dayKey.toString()]: {
                ...dayValue,
                isSelected: true,
            },
        };
    });

export const handleAddDay = (schedule, setSchedule, day: string) => {
    const existingDay = schedule.find(
        scheduleDay => Object.keys(scheduleDay)[0] === day
    );

    if (existingDay) {
        if (!existingDay[day].isSelected) {
            existingDay[day].isSelected = true;
            setSchedule([...schedule]);
        }

        return;
    }

    const newDay = {
        [day]: {
            startTime: '09:00',
            endTime: '17:00',
            isSelected: true,
        },
    };

    const newSchedule = [...schedule, newDay as unknown as ScheduleType];

    const sortedSchedule = sortScheduleDays(newSchedule, daysOfWeek);

    setSchedule(sortedSchedule);
};

export const handleRemoveDay = (schedule, setSchedule, day: string) => {
    const updatedSchedule = schedule.map(scheduleDay => {
        const dayKey = Object.keys(scheduleDay)[0];

        if (dayKey === day) {
            return {
                [dayKey]: {
                    ...scheduleDay[dayKey],
                    isSelected: !scheduleDay[dayKey].isSelected,
                },
            };
        }

        return scheduleDay;
    });

    setSchedule(updatedSchedule);
};

export const handleCopyToAll = (schedule, setSchedule, currentDay: string) => {
    const currentDaySchedule = schedule.find(
        scheduleDay => Object.keys(scheduleDay)[0] === currentDay
    );

    if (currentDaySchedule) {
        const updatedSchedule = schedule.map(scheduleDay => {
            const day = Object.keys(scheduleDay)[0];

            if (scheduleDay[day].isSelected) {
                const breaks = currentDaySchedule[currentDay].breaks || [];

                return {
                    [day]: {
                        ...scheduleDay[day],
                        ...currentDaySchedule[currentDay],
                        breaks,
                    },
                };
            }

            return scheduleDay;
        });

        setSchedule(updatedSchedule);
    }
};

export const handleAddBreak = (schedule, setSchedule, day: string) => {
    const updatedSchedule = schedule.map(scheduleDay => {
        const dayKey = Object.keys(scheduleDay)[0];

        if (dayKey === day) {
            const breaks = scheduleDay[dayKey].breaks
                ? [...scheduleDay[dayKey].breaks]
                : [];

            breaks.push({
                startTime: '12:00',
                endTime: '13:00',
            });

            return {
                [dayKey]: {
                    ...scheduleDay[dayKey],
                    breaks,
                },
            };
        }

        return scheduleDay;
    });

    setSchedule(updatedSchedule);
};

export const handleRemoveBreak = (
    schedule,
    setSchedule,
    day: string,
    breakIndex: number
) => {
    const updatedSchedule = schedule.map(scheduleDay => {
        const dayKey = Object.keys(scheduleDay)[0];

        if (dayKey === day) {
            const breaks = [...scheduleDay[dayKey].breaks];

            breaks.splice(breakIndex, 1);

            return {
                [dayKey]: {
                    ...scheduleDay[dayKey],
                    breaks,
                },
            };
        }

        return scheduleDay;
    });

    setSchedule(updatedSchedule);
};

export const handleDayInputChange = (
    schedule,
    setSchedule,
    day: string,
    field: string,
    value: string
) => {
    const updatedSchedule = schedule.map(scheduleDay => {
        const dayKey = Object.keys(scheduleDay)[0];

        if (dayKey === day) {
            return {
                [dayKey]: {
                    ...scheduleDay[dayKey],
                    [field]: value,
                },
            };
        }

        return scheduleDay;
    });

    setSchedule(updatedSchedule);
};

export const handleBreakInputChange = (
    schedule,
    setSchedule,
    day: string,
    breakField: string,
    value: string,
    breakIndex: number
) => {
    const updatedSchedule = schedule.map(scheduleDay => {
        const dayKey = Object.keys(scheduleDay)[0];

        if (dayKey === day) {
            const breaks = scheduleDay[dayKey].breaks.map(
                (breakItem, index) => {
                    if (index === breakIndex) {
                        return {
                            ...breakItem,
                            [breakField]: value,
                        };
                    }

                    return breakItem;
                }
            );

            return {
                [dayKey]: {
                    ...scheduleDay[dayKey],
                    breaks,
                },
            };
        }

        return scheduleDay;
    });

    setSchedule(updatedSchedule);
};
