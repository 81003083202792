import React, { useCallback, useEffect } from 'react';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { addBreadcrumb } from '@sentry/react';
import { useNavigate } from 'react-router-dom';

const ISSUER = window.env.OKTA_ISSUER;
const CLIENT_ID = window.env.OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

function initializeOktaInstance() {
    return new OktaAuth({
        issuer: ISSUER,
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
        postLogoutRedirectUri: `${window.location.origin}/login`,
        tokenManager: { autoRenew: true, expireEarlySeconds: 30 },
        scopes: ['email', 'openid', 'offline_access', 'profile'],
    });
}

const oktaAuth =
    window.oktaInstance === undefined
        ? initializeOktaInstance()
        : window.oktaInstance;

window.oktaInstance = oktaAuth;

export const AuthenticationContext: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const navigate = useNavigate();

    const navigateToOrigin = useCallback(
        (_oktaAuth: OktaAuth, originalUri: string) => {
            navigate(
                toRelativeUrl(originalUri || '/', window.location.origin),
                {
                    replace: true,
                }
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const oktaLifecycleLog = useCallback(
        eventName => tokenName => {
            addBreadcrumb({
                message: `Okta event ${eventName} happen!`,
                data: { tokenName },
                level: 'log',
            });
        },
        []
    );

    useEffect(() => {
        oktaAuth.tokenManager.on('error', error => {
            addBreadcrumb({
                level: 'error',
                message: 'Okta error!',
                data: error,
            });
        });

        oktaAuth.tokenManager.on('renewed', oktaLifecycleLog('renewed'));
        oktaAuth.tokenManager.on('added', oktaLifecycleLog('added'));
        oktaAuth.tokenManager.on('expired', oktaLifecycleLog('expired'));
        oktaAuth.tokenManager.on('removed', oktaLifecycleLog('removed'));

        oktaAuth.tokenManager.on(
            'set_storage',
            oktaLifecycleLog('set_storage')
        );

        return () => {
            addBreadcrumb({ message: 'Stopping okta!', level: 'log' });
        };
    }, [oktaLifecycleLog]);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={navigateToOrigin}>
            {children}
        </Security>
    );
};
