import React, { useState, Suspense, useCallback, useEffect } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { Button, Pagination } from 'workmotion-design-system';

import { getFirstDayofMonth } from 'app/shared/date-utils';
import { DefaultFiltersMenu } from 'app/shared/default-menu';
import { useUserIdentity } from 'authentication/use-user-identity';
import { MONTH_FILTER, SUPPORTED_LISTING_PARAMS } from 'constant';
import { DownloadBtn } from 'design-components/download-btn';
import { SpeedDial } from 'design-components/speed-dial';
import { usePagination } from 'helpers/use-pagination';
import { useWindowSize } from 'helpers/use-window-size';
import { en } from 'localization';
import { useGenerateTemplateTimesheet } from 'networking/timesheet-api/timesheet-mutations';
import { useTimesheetRequests } from 'networking/timesheet-api/timesheet-network-requests';
import { ApiPaginationData } from 'types';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { ConfirmationBanner } from './banner';
import { ActionsContainer, FilterContainer } from './styled-components';
import { CurrentMonthUploadModal } from './submit-timesheet/current-month-upload-modal';
import { SubmitWithMonthModal } from './submit-timesheet/submit-with-month-modal';
import { TimesheetList } from './timesheet-list';

export const EmployeeTimesheetView: React.FC = () => {
    const { isMobileSize } = useWindowSize();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [selectedTimesheet, setSelectedTimesheet] =
        useState<TimesheetListingResponseDTO>();

    const currentMonth = getFirstDayofMonth(new Date());

    const [isSubmitWithMonthModalOpen, setSubmitWithMonthModalOpen] =
        useState<boolean>(false);

    const { mutate: getTimesheetDraft, isLoading: templateIsLoading } =
        useGenerateTemplateTimesheet();

    const {
        employeeTimesheet: { downloadTemplateAction, submitTimesheetAction },
    } = en;

    const filters = [MONTH_FILTER, ...SUPPORTED_LISTING_PARAMS];

    const [isCurrentMonthSubmitted, setCurrentMonthSubmitted] =
        useState<boolean>();

    const {
        currentPage,
        requestParams,
        currentPageSize,
        handleChangePage,
        handleChangePageSize,
        handleChangeFilters,
        removeFilters,
    } = usePagination(filters);

    const {
        data: { talentId },
    } = useUserIdentity();

    const { data, isLoading } = useTimesheetRequests({
        ...requestParams,
        talentId: talentId as string,
    });

    const { data: allTimesheets } = useTimesheetRequests({
        talentId: talentId,
    });

    const handleDownload = useCallback(() => {
        getTimesheetDraft(currentMonth, {
            onSuccess: templateData => {
                window.open(templateData.downloadUrl, '_blank');
            },
            onError: (err: any) => {
                toast(err, {
                    type: 'error',
                });
            },
        });
    }, [getTimesheetDraft, currentMonth]);

    const [pagesInfo, setPagInfo] = useState<ApiPaginationData>(data?.page);

    useEffect(() => {
        if (data?.page) setPagInfo(data?.page);
    }, [data?.page]);

    useEffect(() => {
        if (allTimesheets?.content && !isCurrentMonthSubmitted) {
            const lastMonth = allTimesheets.content.findIndex(
                (el: TimesheetListingResponseDTO) =>
                    el.yearMonth === currentMonth
            );

            setCurrentMonthSubmitted(lastMonth >= 0);
        }
    }, [
        allTimesheets,
        currentMonth,
        setCurrentMonthSubmitted,
        isCurrentMonthSubmitted,
    ]);

    return (
        <Box sx={{ paddingBottom: '2rem' }}>
            {!isMobileSize && (
                <ActionsContainer>
                    <DownloadBtn
                        download={handleDownload}
                        isLoading={templateIsLoading}
                        title={downloadTemplateAction}
                        dataCy={'DWNLOAD-TEMPLATE-BTN'}
                    />

                    <Button
                        style={{
                            width: '190px',
                            marginInlineStart: '1rem',
                        }}
                        onClick={() => setSubmitWithMonthModalOpen(true)}
                        data-testid="submit-action"
                        name="submit-action-btn"
                    >
                        {submitTimesheetAction}
                    </Button>
                </ActionsContainer>
            )}

            {data?.content && (
                <ConfirmationBanner
                    month={currentMonth}
                    openModal={setModalOpen}
                    isSubmitted={isCurrentMonthSubmitted}
                />
            )}

            <FilterContainer>
                <DefaultFiltersMenu
                    handleChangeFilters={handleChangeFilters}
                    removeFilters={removeFilters}
                    requestParams={requestParams}
                    withCountry={false}
                />
            </FilterContainer>

            <TimesheetList
                isLoading={isLoading}
                content={data?.content}
                setSelectedTimesheet={setSelectedTimesheet}
                setModalOpen={setModalOpen}
            />

            {!!data?.content && !!data?.page?.totalElements && (
                <Pagination
                    total={pagesInfo?.totalElements}
                    currentPage={parseInt(currentPage, 10)}
                    onChange={(selected: number) => handleChangePage(selected)}
                    pageSize={parseInt(currentPageSize)}
                    onPageSizeChanged={handleChangePageSize}
                />
            )}

            <Suspense fallback={<div />}>
                <CurrentMonthUploadModal
                    modalIsOpen={isModalOpen}
                    closeModal={() => {
                        setModalOpen(false);
                        setSelectedTimesheet(null);
                    }}
                    selectedTimesheet={selectedTimesheet}
                    timesheetMonth={
                        selectedTimesheet
                            ? selectedTimesheet.yearMonth
                            : currentMonth
                    }
                />
            </Suspense>

            <Suspense fallback={<div />}>
                <SubmitWithMonthModal
                    modalIsOpen={isSubmitWithMonthModalOpen}
                    closeModal={() => {
                        setSubmitWithMonthModalOpen(false);
                    }}
                    timesheetsList={allTimesheets?.content}
                />
            </Suspense>

            {isMobileSize && (
                <SpeedDial
                    actionItems={[
                        {
                            name: downloadTemplateAction,
                            icon: <DownloadIcon />,
                            callback: () => handleDownload(),
                        },
                        {
                            name: submitTimesheetAction,
                            icon: <TimerRoundedIcon />,
                            callback: () => setSubmitWithMonthModalOpen(true),
                        },
                    ]}
                />
            )}
        </Box>
    );
};
