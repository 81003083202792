enum TimeTrackingRecordFilterStatusEnum {
    ALL = 'ALL',
    PENDING = 'PENDING',
    SUBMITTED = 'SUBMITTED',
}

enum TimeTrackingRecordTypeEnum {
    ALL = 'ALL',
    WORKING_DAY = 'WORKING_DAY',
    NON_WORKING_DAY = 'NON_WORKING_DAY',
}

export { TimeTrackingRecordFilterStatusEnum, TimeTrackingRecordTypeEnum };
