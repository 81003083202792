import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { captureMessage } from '@sentry/react';
import { SelectAutocomplete } from 'workmotion-design-system';

import { getDatefromIso, getFormattedIsoDate } from 'app/shared/date-utils';
import {
    TimeTrackingRecordFilterStatusEnum,
    TimeTrackingRecordTypeEnum,
} from 'app/time-tracking/enums';
import {
    TIME_TRACKING_RECORD_FILTER_STATUS,
    TIME_TRACKING_RECORD_TYPE,
} from 'constant';
import { DateTimePicker } from 'design-components/date-picker';
import { en } from 'localization';

const {
    employeeTimeTracking: { filterPlaceholder },
} = en;

interface DateFilterProps {
    onMonthSelect?: (month: string) => void;
    initialSearchValue?: string;
}

interface SelectFilterProps<T> {
    filterEnum: Record<string, string>;
    filterLabels: Record<string, string>;
    initialValue: T;
    onChangeMessage: string;
    onChange: (value: T) => void;
    id: string;
}

interface StatusFilterProps {
    initialValue: TimeTrackingRecordFilterStatusEnum;
    onChange: (value: TimeTrackingRecordFilterStatusEnum) => void;
}

interface TypeFilterProps {
    initialValue: TimeTrackingRecordTypeEnum;
    onChange: (value: TimeTrackingRecordTypeEnum) => void;
}

type SelectOption<V = string> = {
    value: V;
    label: string;
};

export const DateFilter: React.FC<DateFilterProps> = ({
    onMonthSelect,
    initialSearchValue,
}) => {
    const selectMonth = useCallback(
        (date: Date) => {
            captureMessage('DateFilter: selectMonth');
            const isoDate = date && getFormattedIsoDate(date);

            onMonthSelect(isoDate);
        },
        [onMonthSelect]
    );

    return (
        <Box sx={{ width: 248 }}>
            <DateTimePicker
                selected={
                    initialSearchValue
                        ? getDatefromIso(initialSearchValue)
                        : null
                }
                monthPicker
                placeholder={filterPlaceholder}
                onChange={selectMonth}
            />
        </Box>
    );
};

const TimeTrackingSelectFilter: React.FC<SelectFilterProps<any>> = ({
    filterEnum,
    filterLabels,
    initialValue,
    onChangeMessage,
    onChange,
    id,
}) => {
    const options: SelectOption[] = Object.keys(filterLabels).map(key => ({
        label: filterLabels[key],
        value: filterEnum[key],
    }));

    const handleChange = useCallback(
        (selectedValue: string): void => {
            captureMessage(onChangeMessage);
            onChange(selectedValue as typeof initialValue);
        },
        [onChangeMessage, onChange]
    );

    return (
        <SelectAutocomplete
            options={options}
            value={initialValue}
            onChange={handleChange}
            id={id}
        />
    );
};

export const StatusFilter: React.FC<StatusFilterProps> = ({
    initialValue,
    onChange,
}) => (
    <TimeTrackingSelectFilter
        filterEnum={TimeTrackingRecordFilterStatusEnum}
        filterLabels={TIME_TRACKING_RECORD_FILTER_STATUS}
        initialValue={initialValue}
        onChangeMessage="StatusFilter: handleChangeStatus"
        onChange={onChange}
        id={'Status-Filter-input'}
    />
);

export const TypeFilter: React.FC<TypeFilterProps> = ({
    initialValue,
    onChange,
}) => (
    <TimeTrackingSelectFilter
        filterEnum={TimeTrackingRecordTypeEnum}
        filterLabels={TIME_TRACKING_RECORD_TYPE}
        initialValue={initialValue}
        onChangeMessage="TypeFilter: handleChangeType"
        onChange={onChange}
        id={'Type-Filter-input'}
    />
);
