import React from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DatePicker from 'react-datepicker';
import { palette } from 'workmotion-design-system';
import 'react-datepicker/dist/react-datepicker.css';

import {
    CustomDatePickerContainer,
    CustomLabel,
    CustomPlaceholder,
    StyledDatePickerWrapper,
} from './styled-components';

type PopperPlacement =
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';

interface DatePickerProps {
    id?: string;
    selected: Date | null;
    onChange: (selected: Date) => void;
    hasNoMinDate?: boolean;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    minDate?: Date;
    maxDate?: Date;
    popperPlacement?: PopperPlacement;
    hasError?: boolean;
    dataCy?: string;
    dataTestId?: string;
    placeholder?: string;
    monthPicker?: boolean;
}

const CustomInput = React.forwardRef(function CustomInput(props: any, _ref) {
    const { onClick, dataCy, value, placeholder } = props;

    return (
        <CustomDatePickerContainer onClick={onClick} data-cy={dataCy || ''}>
            {placeholder && !value && (
                <CustomPlaceholder>{placeholder}</CustomPlaceholder>
            )}

            {value && <CustomLabel>{value}</CustomLabel>}

            <DateRangeIcon
                style={{
                    color: palette.greyscale.sharkMouth,
                    fontSize: '1rem',
                }}
            />
        </CustomDatePickerContainer>
    );
});

export const DateTimePicker: React.FC<DatePickerProps> = ({
    selected,
    onChange,
    hasNoMinDate,
    onBlur,
    minDate,
    maxDate,
    popperPlacement,
    hasError,
    dataCy,
    dataTestId,
    placeholder,
    monthPicker,
    ...restProps
}) => (
    <StyledDatePickerWrapper data-has-error={hasError} data-testid={dataTestId}>
        <DatePicker
            {...restProps}
            selected={selected}
            onChange={onChange}
            tabIndex={0}
            placeholderText={placeholder}
            dateFormat={monthPicker ? 'yyyy-MM' : 'dd/MM/yyyy'}
            minDate={hasNoMinDate ? null : minDate}
            maxDate={maxDate}
            onBlur={onBlur}
            autoComplete="off"
            showYearDropdown
            popperPlacement={popperPlacement}
            customInput={<CustomInput dataCy={dataCy} />}
            showMonthYearPicker={monthPicker}
            showFullMonthYearPicker={monthPicker}
        />
    </StyledDatePickerWrapper>
);
