import React from 'react';

import { Box } from '@mui/material';
import { Spinner } from 'workmotion-design-system';

import { TimesheetListingResponseDTO } from 'types/timesheet';

import { NoTimesheets } from './no-timesheets';
import { spinnerContainer } from './styled-components';
import { Table } from './table';

export const TimesheetList: React.FC<{
    isLoading: boolean;
    timesheetData: TimesheetListingResponseDTO[];
}> = ({ timesheetData, isLoading }) => {
    if (isLoading) {
        return (
            <Box sx={spinnerContainer} data-testid="timesheet-loading">
                <Spinner />
            </Box>
        );
    }

    if (timesheetData?.length > 0) {
        return (
            <div data-testid="table-component">
                <Table timeSheetData={timesheetData} />
            </div>
        );
    }

    return <NoTimesheets data-testid="no-timesheets" />;
};
