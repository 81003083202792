import React from 'react';

import styled from 'styled-components';
import { Button, palette } from 'workmotion-design-system';

import { MenuType } from 'constant';
import { en } from 'localization';
import { ExportButton } from 'shared-components/export-button';
import { FiltersButton } from 'shared-components/filters-menu/filters-button';

import { Menu, MenuProps } from './menu';

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${palette.greyscale.antiFlashWhite};
    padding: 1.5rem;
`;

const StyledMenu = styled(Menu)`
    background: #fff;
    box-shadow: 0 0 16px rgba(82, 95, 127, 0.239216);
    border-radius: 0.5rem;
    min-width: 296px;
    z-index: 10;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    border-bottom: 1px solid ${palette.greyscale.antiFlashWhite};
    & > * {
        margin: 0.75rem;
    }
`;

interface FiltersMenuProps extends Omit<MenuProps, 'Button'> {
    onReset: () => void;
    onApply: () => void;
    menuType?: MenuType.EXPORT | MenuType.FILTER;
}

const FiltersMenu: React.FC<FiltersMenuProps> = ({
    children,
    isOpen,
    onClose,
    setIsMenuOpen,
    onReset,
    onApply,
    alignment,
    menuType = MenuType.FILTER,
}) => {
    const {
        sharedComponents: {
            filtersMenu: {
                applyButton,
                resetButton,
                exportButton,
                cancelButton,
            },
        },
    } = en;

    return (
        <StyledMenu
            isOpen={isOpen}
            Button={menuType === MenuType.FILTER ? FiltersButton : ExportButton}
            onClose={onClose}
            setIsMenuOpen={setIsMenuOpen}
            alignment={alignment}
        >
            <InputContainer>{children}</InputContainer>

            <ButtonsContainer>
                <Button
                    data-testid="filters-reset-button"
                    btnType="transparent"
                    style={{
                        width: 116,
                        height: 40,
                        border: `1px solid ${palette.greyscale.UCLABlue}`,
                        borderRadius: 8,
                    }}
                    onClick={onReset}
                >
                    {menuType === MenuType.FILTER ? resetButton : cancelButton}
                </Button>

                <Button
                    data-testid="filters-apply-button"
                    style={{ width: 116, height: 40, borderRadius: 8 }}
                    onClick={onApply}
                >
                    {menuType === MenuType.FILTER ? applyButton : exportButton}
                </Button>
            </ButtonsContainer>
        </StyledMenu>
    );
};

export { FiltersMenu };
