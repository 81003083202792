import React from 'react';

import { Fallback } from 'workmotion-design-system';

import { en } from 'localization';

import NoExpenses from './empty-timesheet-illustrations.png';

const {
    employeeTimesheet: {
        noTimesheets: { title, message },
    },
} = en;

export const NoTimesheets: React.FC = () => (
    <Fallback
        content={
            <div style={{ marginTop: '2rem' }}>
                <img src={NoExpenses} alt="No Timesheet Illustration" />
            </div>
        }
        title={title}
        subtitle={message}
    />
);
