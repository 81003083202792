import React from 'react';

import { Box } from '@mui/material';
import { palette, Spinner } from 'workmotion-design-system';

import { en } from '../../../../localization';
import { useTimeSheetDrafts } from '../../../../networking/timesheet-api/timesheet-network-requests';

export const TemplateHint: React.FC<{
    talentId: string;
    yearMonth: string;
}> = ({ talentId, yearMonth }) => {
    const {
        employeeTimesheet: { hint },
    } = en;

    const { isLoading, data } = useTimeSheetDrafts(talentId, yearMonth);

    return (
        <Box
            sx={{
                display: 'inline-block',
                fontSize: '0.75rem',
                margin: '1.5rem 0 1.5rem 0',
                color: palette.greyscale.UCLABlue,

                '& > a': {
                    color: palette.primary.cerisePink,
                    marginLeft: '0.5rem',
                },
            }}
        >
            <span>{hint.comment}</span>

            <a
                href={data?.downloadUrl}
                data-testid={'download-link'}
                target="_blank"
                rel="noreferrer"
            >
                {hint.action}
            </a>

            {isLoading && (
                <Spinner
                    data-testid={'spinner'}
                    size={12}
                    style={{ display: 'inline-block' }}
                />
            )}
        </Box>
    );
};
