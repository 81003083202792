import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import { getDatefromIso, getFormattedIsoDate } from 'app/shared/date-utils';
import { DateTimePicker } from 'design-components/date-picker';
import { en } from 'localization';

const {
    employeeTimesheet: { filterPlaceholder },
} = en;

interface FilterProps {
    onMonthSelect?: (month: string) => void;
    initialSearchValue?: string;
}

export const DateFilter: React.FC<FilterProps> = ({
    onMonthSelect,
    initialSearchValue,
}) => {
    const selectMonth = useCallback(
        (date: Date) => {
            const isoDate = date && getFormattedIsoDate(date);

            onMonthSelect(isoDate);
        },
        [onMonthSelect]
    );

    return (
        <Box sx={{ width: 248 }}>
            <DateTimePicker
                selected={
                    initialSearchValue
                        ? getDatefromIso(initialSearchValue)
                        : null
                }
                monthPicker
                placeholder={filterPlaceholder}
                onChange={selectMonth}
            />
        </Box>
    );
};
