import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner } from 'workmotion-design-system';

import { useDownloadTimeTrackingRecords } from 'networking/timesheet-api/time-tracking-network-requests';

import { spinnerContainer } from '../time-tracking-list/styled-components';

const ExportTimeTrackingRecords: React.FC = () => {
    const { fileKey } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useDownloadTimeTrackingRecords(fileKey);

    useEffect(() => {
        if (!isLoading && data) {
            window.location.href = data.downloadUrl;
            navigate('/time-tracking');
        }
    }, [data, isLoading, navigate]);

    return (
        isLoading && (
            <Box
                sx={spinnerContainer}
                data-testid="export-time-tracking-loader"
            >
                <Spinner />
            </Box>
        )
    );
};

export default ExportTimeTrackingRecords;
