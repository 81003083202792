import React from 'react';

import AddBreakIcon from './add_circle.svg';
import CopyToAllIcon from './add_to_photos.svg';
import {
    IconWrapper,
    StyledAddEntryWrapper,
    StyledCopyToAllWrapper,
} from '../styled-components';
interface ActionSectionProps {
    title: string;
    addBreakLabel: string;
    copyAll: string;
    hasCopyToAll: boolean;
    addBreak: (title: string) => void;
    copyToAll: (title: string) => void;
}

const ActionSection: React.FC<ActionSectionProps> = ({
    title,
    addBreakLabel,
    copyAll,
    hasCopyToAll,
    addBreak,
    copyToAll,
}: ActionSectionProps) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '1.5rem',
        }}
    >
        <StyledAddEntryWrapper data-testid="add-break-action">
            <IconWrapper
                onClick={() => addBreak(title)}
                data-testid="add-break-icon"
            >
                <AddBreakIcon />

                <span style={{ marginLeft: '0.5rem ' }}>{addBreakLabel}</span>
            </IconWrapper>
        </StyledAddEntryWrapper>

        {hasCopyToAll && (
            <StyledCopyToAllWrapper data-testid="copy-to-all-action">
                <IconWrapper
                    onClick={() => {
                        copyToAll(title);
                    }}
                    data-testid="copy-to-all-icon"
                >
                    <CopyToAllIcon />

                    <span style={{ marginLeft: '0.5rem ' }}>{copyAll}</span>
                </IconWrapper>
            </StyledCopyToAllWrapper>
        )}
    </div>
);

export default ActionSection;
