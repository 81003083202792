/* eslint-disable react-hooks/rules-of-hooks */
import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Box } from '@mui/material';
import { useFlags } from '@workmotion/feature-flags-library';
import { Button, Pagination } from 'workmotion-design-system';

import { DefaultFiltersMenu } from 'app/shared/default-menu';
import { EmployeeFilter } from 'app/time-sheet/manage-view/employee-filter';
import { useUserIdentity } from 'authentication/use-user-identity';
import {
    COUNTRY_FILTER,
    MONTH_FILTER,
    STATUS_FILTER,
    SUPPORTED_LISTING_PARAMS,
    TALENT_ID,
    TYPE_FILTER,
} from 'constant';
import { getCurrentCompanyId } from 'helpers/get-current-company';
import { usePagination } from 'helpers/use-pagination';
import { en } from 'localization';
import {
    useTimeTrackingRecords,
    useTalentTimeTrackingSchedule,
} from 'networking/timesheet-api/time-tracking-network-requests';
import { ApiPaginationData } from 'types';
import { EmployeeResponseDTO } from 'types/employee';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

import AddRecordModal from './add-time-tracking-record/add-record-modal';
import { ConfirmationBanner } from './banner';
import DefineScheduleModal from './define-time-tracking-schedule';
import DeleteRecordModal from './delete-time-tracking-record';
import { ExportRecordsMenu } from './export-records-menu';
import PreviewRecordModal from './preview-time-tracking-record/preview-record-modal';
import { ActionsContainer } from './styled-components';
import SubmitRecordConfirmationModal from './submit-records-confirmation-modal/index';
import { TimeTrackingList } from './time-tracking-list';
import { NoTimeTracking } from './time-tracking-list/no-time-tracking';
import { checkSelectedRecordsIncludesClockOut } from '../helpers';

export const EmployeeTimeTrackingView: React.FC = () => {
    const {
        employeeTimeTracking: {
            addRecord,
            defineSchedule,
            editSchedule,
            submitSelected,
        },
    } = en;

    const filters = [
        TALENT_ID,
        MONTH_FILTER,
        STATUS_FILTER,
        TYPE_FILTER,
        COUNTRY_FILTER,
        ...SUPPORTED_LISTING_PARAMS,
    ];

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [isDefineScheduleModalOpen, setDefineScheduleModalOpen] =
        useState<boolean>(false);

    const [isPreviewModalOpen, setIsPreviewModalOpen] =
        useState<boolean>(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [isConfirmationModalOpen, setConfirmationModalOpen] =
        useState<boolean>(false);

    const [selectedTimeTrackingRecord, setSelectedTimeTrackingRecord] =
        useState<TimeTrackingRecordResponseDTO>();

    const [selectedTimeTrackingRecordList, setSelectedTimeTrackingRecordList] =
        useState<TimeTrackingRecordResponseDTO[]>([]);

    const [isSubmitRecordsVisible, setSubmitRecordsVisible] =
        useState<boolean>(false);

    const {
        currentPage,
        requestParams,
        currentPageSize,
        handleChangePage,
        handleChangePageSize,
        handleChangeFilters,
        removeFilters,
        handleChangeFilter,
        removeFilter,
    } = usePagination(filters);

    const { talent423LttEnhancements } = useFlags();

    const {
        data: {
            isAdmin,
            isEmployee,
            isHrManager,
            isCompanyAdmin,
            talentId,
            companyId: userCompanyId,
            groups: userGroups,
        },
    } = useUserIdentity();

    const selectedCompanyId = getCurrentCompanyId();

    const companyId = useMemo(() => {
        if (isAdmin) {
            return selectedCompanyId;
        }

        return userCompanyId;
    }, [isAdmin, selectedCompanyId, userCompanyId]);

    const requestParamsFormatted = useMemo(
        () =>
            (isAdmin || isHrManager || isCompanyAdmin) && companyId
                ? { ...requestParams, companyId }
                : isAdmin
                ? { ...requestParams }
                : { ...requestParams, talentId },
        [
            companyId,
            isAdmin,
            isCompanyAdmin,
            isHrManager,
            requestParams,
            talentId,
        ]
    );

    const onEmployeeSelect = useCallback(
        (employee: EmployeeResponseDTO) => {
            handleChangeFilter(TALENT_ID, employee.id);
        },
        [handleChangeFilter]
    );

    const onEmployeeRemove = useCallback(
        () => removeFilter(TALENT_ID),
        [removeFilter]
    );

    const { data, isLoading, refetch } = useTimeTrackingRecords({
        ...requestParamsFormatted,
    });

    const { data: talentSchedule, isLoading: isTalentScheduleLoading } =
        talentId || isEmployee
            ? useTalentTimeTrackingSchedule(talentId)
            : { data: null, isLoading: false };

    const [pagesInfo, setPagInfo] = useState<ApiPaginationData>(data?.page);

    const talentDetails = data?.content[0]?.talentInfo;

    useEffect(() => {
        if (data?.page) setPagInfo(data?.page);
    }, [data?.page]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (selectedTimeTrackingRecordList.length > 0) {
            setSubmitRecordsVisible(true);
        } else {
            setSubmitRecordsVisible(false);
        }
    }, [selectedTimeTrackingRecordList.length]);

    return (
        <Box sx={{ paddingBottom: '2rem' }}>
            {isEmployee && talentDetails && (
                <ConfirmationBanner
                    name={`${talentDetails?.firstName} ${talentDetails?.lastName}`}
                    workingHours={talentDetails?.workingHours}
                    dataCy={'time-tracking-confirmation-banner'}
                />
            )}

            <ActionsContainer>
                {(isAdmin || isHrManager || isCompanyAdmin) && (
                    <EmployeeFilter
                        onSelect={onEmployeeSelect}
                        onRemove={onEmployeeRemove}
                    />
                )}

                {talent423LttEnhancements &&
                    userGroups?.includes('ExportLTTRecords') && (
                        <ExportRecordsMenu />
                    )}

                <DefaultFiltersMenu
                    handleChangeFilters={handleChangeFilters}
                    removeFilters={removeFilters}
                    requestParams={requestParamsFormatted}
                    withCountry={isAdmin}
                    withRecordStatus={
                        (isAdmin || isEmployee) && talent423LttEnhancements
                    }
                    withRecordType={isEmployee && talent423LttEnhancements}
                />

                {!isHrManager && !isCompanyAdmin && (
                    <Button
                        style={{
                            marginInlineStart: '1rem',
                        }}
                        data-testid="add-record-action"
                        name="add-record-action-btn"
                        onClick={() => setModalOpen(true)}
                        btnType="lightGreen"
                    >
                        {addRecord}
                    </Button>
                )}

                {isEmployee &&
                    talent423LttEnhancements &&
                    !isTalentScheduleLoading && (
                        <Button
                            style={{
                                marginInlineStart: '1rem',
                            }}
                            data-testid="define-scedule-submit-action"
                            name="submit-action-btn"
                            onClick={() => setDefineScheduleModalOpen(true)}
                        >
                            {talentSchedule &&
                            Object.keys(talentSchedule).length === 0
                                ? defineSchedule
                                : editSchedule}
                        </Button>
                    )}

                {(isAdmin || isEmployee) &&
                    talent423LttEnhancements &&
                    isSubmitRecordsVisible && (
                        <Button
                            style={{
                                marginInlineStart: '1rem',
                            }}
                            data-testid="submit-selcted-records-action"
                            name="submit-record-action-btn"
                            btnType="subtle"
                            onClick={() => {
                                const isSubmittedRecordshasClockOut =
                                    checkSelectedRecordsIncludesClockOut(
                                        selectedTimeTrackingRecordList
                                    );

                                if (isSubmittedRecordshasClockOut) {
                                    setConfirmationModalOpen(true);
                                }
                            }}
                        >
                            {submitSelected}
                        </Button>
                    )}
            </ActionsContainer>

            {!data?.content[0]?.id && !isLoading && (
                <NoTimeTracking data-testid="no-time-tracking-records" />
            )}

            {data?.content[0]?.id && (
                <TimeTrackingList
                    isLoading={isLoading}
                    timeTrackingRecords={data?.content}
                    setSelectedRecord={setSelectedTimeTrackingRecord}
                    setModalOpen={setModalOpen}
                    setIsPreview={setIsPreviewModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    selectedTimeTrackingRecordList={
                        selectedTimeTrackingRecordList
                    }
                    setSelectedTimeTrackingRecordList={
                        setSelectedTimeTrackingRecordList
                    }
                />
            )}

            {data?.content[0]?.id && !!data?.page?.totalElements && (
                <Pagination
                    total={pagesInfo?.totalElements}
                    currentPage={parseInt(currentPage, 10)}
                    onChange={(selected: number) => handleChangePage(selected)}
                    pageSize={parseInt(currentPageSize)}
                    onPageSizeChanged={handleChangePageSize}
                />
            )}

            <Suspense fallback={<div />}>
                <AddRecordModal
                    modalIsOpen={isModalOpen}
                    closeModal={() => {
                        setModalOpen(false);
                        setSelectedTimeTrackingRecord(null);
                    }}
                    selectedTimeTrackingRecord={selectedTimeTrackingRecord}
                    contractStartDate={talentDetails?.contractStartDate}
                />
            </Suspense>

            <Suspense fallback={<div />}>
                <DefineScheduleModal
                    modalIsOpen={isDefineScheduleModalOpen}
                    closeModal={() => {
                        setDefineScheduleModalOpen(false);
                    }}
                    selectedSchedule={
                        talentSchedule && Object.keys(talentSchedule).length > 0
                            ? talentSchedule
                            : null
                    }
                />
            </Suspense>

            <Suspense fallback={<div />}>
                <PreviewRecordModal
                    modalIsOpen={isPreviewModalOpen}
                    closeModal={() => {
                        setIsPreviewModalOpen(false);
                        setSelectedTimeTrackingRecord(null);
                    }}
                    selectedTimeTrackingRecord={selectedTimeTrackingRecord}
                />
            </Suspense>

            <Suspense fallback={<div />}>
                <DeleteRecordModal
                    modalIsOpen={isDeleteModalOpen}
                    closeModal={() => {
                        setIsDeleteModalOpen(false);
                        setSelectedTimeTrackingRecord(null);
                    }}
                    selectedTimeTrackingRecord={selectedTimeTrackingRecord}
                />
            </Suspense>

            <Suspense fallback={<div />}>
                <SubmitRecordConfirmationModal
                    modalIsOpen={isConfirmationModalOpen}
                    closeModal={() => {
                        setConfirmationModalOpen(false);
                        setSelectedTimeTrackingRecordList([]);
                    }}
                    selectedTimeTrackingRecordList={
                        selectedTimeTrackingRecordList
                    }
                />
            </Suspense>
        </Box>
    );
};
