import React from 'react';

import { DeleteForeverIcon } from 'workmotion-design-system';

import { TimeTrackingBreakRecordResponseDTO } from 'types/timeTracking';

import { BreakWrapper } from '../../styled-components';
import { ScheduleType } from '../constant';
import { RecordEndTime } from '../record-end-time';
import { RecordStartTime } from '../record-start-time';
import {
    IconWrapper,
    StyledBreakHeaderContainer,
    StyledBreakTitle,
    StyledRemoveIcon,
} from '../styled-components';
interface BreakSectionProps {
    day?: ScheduleType;
    title?: string;
    breakLabel?: string;
    breakStartTime?: {
        label: string;
        fieldName: string;
    };
    breakEndTime?: {
        label: string;
        fieldName: string;
    };
    onBreakChange?: (field: string, value: string, breakIndex: number) => void;
    removeBreak?: (breakIndex: number) => void;
    setIsDisabled?: (isDisabled: boolean) => void;
}

const BreakSection: React.FC<BreakSectionProps> = ({
    day,
    title,
    breakLabel,
    breakStartTime,
    breakEndTime,
    onBreakChange,
    removeBreak,
    setIsDisabled,
}: BreakSectionProps) => (
    <>
        {day[title]?.breaks?.map(
            (
                breakItem: TimeTrackingBreakRecordResponseDTO,
                breakIndex: number
            ) => (
                <React.Fragment key={`break-${breakIndex}`}>
                    <StyledBreakHeaderContainer>
                        <StyledBreakTitle>
                            <p>{`${breakLabel} ${breakIndex + 1}`}</p>
                        </StyledBreakTitle>

                        <StyledRemoveIcon>
                            <IconWrapper
                                onClick={() => removeBreak(breakIndex)}
                            >
                                <DeleteForeverIcon
                                    width={'1rem'}
                                    height={'1rem'}
                                />
                            </IconWrapper>
                        </StyledRemoveIcon>
                    </StyledBreakHeaderContainer>

                    <BreakWrapper key={`break-${breakIndex}`}>
                        <RecordStartTime
                            label={breakStartTime.label}
                            id={`${breakIndex}-break-start-time`}
                            fieldValue={breakItem?.startTime}
                            dataCy={'break-start-time-field'}
                            onUpdate={time =>
                                onBreakChange(
                                    breakStartTime.fieldName,
                                    time,
                                    breakIndex
                                )
                            }
                            setIsDisabled={setIsDisabled}
                        />

                        <RecordEndTime
                            label={breakEndTime.label}
                            id={`${breakIndex}-break-end-time`}
                            fieldValue={breakItem?.endTime}
                            dataCy={'break-end-time-field'}
                            onUpdate={time =>
                                onBreakChange(
                                    breakEndTime.fieldName,
                                    time,
                                    breakIndex
                                )
                            }
                            setIsDisabled={setIsDisabled}
                        />
                    </BreakWrapper>
                </React.Fragment>
            )
        )}
    </>
);

export default BreakSection;
