import React, { useCallback } from 'react';

import { useField, useFormikContext } from 'formik';

import { CreateTimeTrackingRecord } from './formik-values-setup';
import { FieldErrorMessage } from './label';
import { StyledFieldContainer, StyledLabel } from './modal-field-components';
import { TimePicker } from '../time-picker';

const TimePickerRecord = ({
    fieldName,
    label,
    id,
    dataCy,
    fieldValue = '',
    disabled = false,
    onChange,
}) => {
    const [field, meta, helpers] = useField(fieldName);
    const { value, name } = field;
    const showError = meta.touched && meta.error;

    const { setFieldValue } = useFormikContext<CreateTimeTrackingRecord>();

    const handleOnChange = useCallback(
        time => {
            setFieldValue(name, time);
            onChange(time);
        },
        [name, setFieldValue, onChange]
    );

    return (
        <StyledFieldContainer>
            <StyledLabel test-id={dataCy}>{label}</StyledLabel>

            <TimePicker
                {...field}
                key={id}
                id={id}
                label={label}
                value={fieldValue || value}
                onChange={handleOnChange}
                hasError={showError}
                placeholder="HH:MM"
                disabled={disabled}
                onBlur={() => helpers.setTouched(true)}
            />

            {showError && <FieldErrorMessage>{meta.error}</FieldErrorMessage>}
        </StyledFieldContainer>
    );
};

export { TimePickerRecord };
