import React from 'react';

import { List, ListItem, Typography } from '@mui/material';
import { InfoTip, palette } from 'workmotion-design-system';

import { en } from 'localization';

import { StyledAction } from '../styled-components';

const TypographyStyled = ({ children, marginTop = '0' }) => (
    <Typography
        style={{
            color: palette.greyscale.UCLABlue,
            fontWeight: '500',
            lineHeight: '1.375rem',
            fontSize: '0.875rem',
            fontFamily: 'Work Sans',
            marginTop,
        }}
    >
        {children}
    </Typography>
);

const StyledActionColored = ({ children, color }) => (
    <StyledAction color={color}>{children}</StyledAction>
);

export const InfoDescription: React.FC = () => {
    const {
        employeeTimeTracking: {
            sideMenuInfo: {
                information,
                infoTipText,
                description: {
                    descriptionPart1,
                    descriptionPart2,
                    descriptionPart3,
                    descriptionPart4,
                    descriptionPart5,
                },
            },
        },
    } = en;

    return (
        <List
            data-testid="description"
            sx={{ color: palette.greyscale.UCLABlue }}
        >
            {Object.keys(information).map(key => (
                <div key={key}>
                    <TypographyStyled marginTop="1rem">
                        {descriptionPart1}

                        <StyledActionColored color={palette.semantic.toyBlue}>
                            {descriptionPart2}
                        </StyledActionColored>

                        {descriptionPart3}
                    </TypographyStyled>

                    <List sx={{ listStyleType: 'number', pl: 2 }}>
                        {information[key].typesDescription.map(
                            (item: {
                                name: string;
                                pendingAction: string;
                                scheduleAction: string;
                                descriptionFirstPart: string;
                                descriptionSecondPart: string;
                                pendingStatus: string;
                                submittedStatus: string;
                            }) => (
                                <ListItem
                                    key={item.name}
                                    sx={{
                                        display: 'list-item',
                                        paddingLeft: '0.5rem',
                                        marginLeft: '0.5rem',
                                    }}
                                >
                                    <TypographyStyled>
                                        {item.descriptionFirstPart}

                                        <StyledActionColored
                                            color={palette.semantic.lemonCurry}
                                        >
                                            {item.pendingAction}
                                        </StyledActionColored>

                                        <StyledActionColored
                                            color={palette.semantic.toyBlue}
                                        >
                                            {item.scheduleAction}
                                        </StyledActionColored>

                                        {item.descriptionSecondPart}

                                        <StyledActionColored
                                            color={palette.semantic.lemonCurry}
                                        >
                                            {item.pendingStatus}
                                        </StyledActionColored>

                                        <StyledActionColored
                                            color={
                                                palette.primary.genericViridian
                                            }
                                        >
                                            {item.submittedStatus}
                                        </StyledActionColored>

                                        {'.'}
                                    </TypographyStyled>
                                </ListItem>
                            )
                        )}
                    </List>

                    <TypographyStyled>{descriptionPart4}</TypographyStyled>

                    <TypographyStyled marginTop="1rem">
                        {descriptionPart5}
                    </TypographyStyled>

                    <TypographyStyled marginTop="2rem">
                        <InfoTip text={infoTipText} />
                    </TypographyStyled>
                </div>
            ))}
        </List>
    );
};
