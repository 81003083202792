import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import RcTimePicker from 'rc-time-picker';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import 'rc-time-picker/assets/index.css';

const TimePickerWrapper = ({
    className,
    id,
    ...rest
}: {
    [x: string]: any;
    id?: string;
    className?: any;
}) => (
    <div className={className}>
        <RcTimePicker
            {...rest}
            popupClassName={className}
            hideDisabledOptions
            minuteStep={5}
            showSecond={false}
            format="HH:mm"
            id={id}
            allowEmpty={false}
            inputIcon={
                <AccessTimeIcon
                    sx={{
                        color: palette.greyscale.sharkMouth,
                        fontSize: '1.1rem',
                        position: 'absolute',
                        cursor: 'pointer',
                        top: '0.7rem',
                        right: '0.5rem',
                    }}
                />
            }
        />
    </div>
);

const StyledTimePicker = styled(TimePickerWrapper)`
    z-index: 1301 !important;

    .rc-time-picker {
        width: 100%;
    }

    .rc-time-picker-input {
        border-color: ${palette.greyscale.antiFlashWhite};
        border-radius: 0.5rem;
        padding: 1rem;
        color: ${palette.greyscale.persianBlue};
        position: relative;
        font-size: 0.75rem;
        font-family: Work Sans;
        width: 100%;
        height: 40px;
        background-color: ${palette.greyscale.ghostWhite};

        &:focus {
            border: 1px solid ${palette.greyscale.antiFlashWhite} !important;
            outline: none;
        }

        &::placeholder {
            color: ${palette.greyscale.sharkMouth};
        }
    }

    .rc-time-picker-panel-inner {
        border-color: ${palette.greyscale.antiFlashWhite};
        border-radius: 0.5rem;
        box-shadow: 0 1px 5px #eaeaea;
    }

    .rc-time-picker-panel-input-wrap {
        background-color: ${palette.greyscale.ghostWhite};
        height: 40px;
        border-color: ${palette.greyscale.antiFlashWhite};
        border-right: none;
        border-radius: 0.5rem 0 0 0;
        border-top: 1px solid ${palette.greyscale.antiFlashWhite};
        border-left: 1px solid ${palette.greyscale.antiFlashWhite};
    }

    .rc-time-picker-panel-input {
        background-color: ${palette.greyscale.ghostWhite};
        font-size: 0.75rem;
        font-family: Work Sans;
        padding: 0 0.6rem;
        height: 100%;

        ::placeholder {
            color: ${palette.greyscale.sharkMouth};
        }
    }

    .rc-time-picker-panel-inner {
        margin-top: 0.2rem;
        border-radius: 0.5rem;
        border: none;
    }

    .rc-time-picker-panel-combobox {
        box-shadow: 0 1px 5px #eaeaea;
    }
`;

const TimePicker: React.FC<{
    value: any;
    onChange: (time) => void;
    id: string;
    placeholder: string;
    [x: string]: any;
}> = ({ value, onChange, placeholder, id, ...rest }) => (
    <StyledTimePicker
        value={value ? moment(value, 'HH:mm') : null}
        onChange={time => onChange(time?.format('HH:mm'))}
        placeholder={placeholder}
        id={id}
        {...rest}
    />
);

export { TimePicker };
