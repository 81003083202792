import type { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const Link = styled.a({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
});

export const LinkName = styled.span({
    color: palette.greyscale.persianBlue,
    fontSize: '.875rem',
    overflow: 'hidden',
    maxWidth: '15rem',
    fontWeight: 400,
    textDecoration: 'none',
    marginInlineStart: 4,
});

export const mobileActionsContainer: SxProps = {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
};

export const mobileIconWrapper: SxProps = {
    backgroundColor: palette.greyscale.antiFlashWhite,
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    '&:first-of-type': {
        marginInlineEnd: '1.5rem',
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.greyscale.ghostWhite,
    },
};

export const spinnerContainer: SxProps = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '10rem 0',
};

export const TableCell = styled.div({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: palette.greyscale.persianBlue,
});

export const StyledInfoWrapper = styled.div({
    display: 'flex',
    padding: '20px 0',
    borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
});

export const StyledInfoLabel = styled.div({
    lineHeight: '1rem',
    fontSize: '0,875rem',
    fontWeight: 400,
    color: palette.greyscale.UCLABlue,
});

export const StyledInfoDetail = styled.div({
    lineHeight: '1rem',
    fontWeight: 500,
    fontSize: '0,875rem',
    color: palette.greyscale.persianBlue,
});
