import React, { useCallback } from 'react';

import { addBreadcrumb } from '@sentry/react';
import { useField, useFormikContext } from 'formik';
import { FormField } from 'workmotion-design-system';

import { getFormattedIsoDate, getDatefromIso } from 'app/shared/date-utils';
import { DatePicker } from 'design-components/mui-date-picker/mui-datepicker';
import { en } from 'localization';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { CreateTimesheetSchema } from './formik-values-setup';

const {
    employeeTimesheet: {
        uploadModal: {
            modalFields: {
                month: { label, fieldName },
            },
        },
    },
} = en;

export const MonthPicker: React.FC<{
    timesheetsList: TimesheetListingResponseDTO[];
    setSubmitDisabled: (disabled: boolean) => void;
}> = ({ timesheetsList, setSubmitDisabled }) => {
    const [field, meta] = useField(fieldName);
    const { value, name } = field;

    const showError = !!(meta.touched && meta.error);
    const { setFieldValue } = useFormikContext<CreateTimesheetSchema>();

    const handleChange = useCallback(
        newSelected => {
            const selectedDate = getFormattedIsoDate(newSelected);

            addBreadcrumb({
                type: 'info',
                message: `MonthPicker: newMonth ${selectedDate}`,
            });

            setFieldValue(name, selectedDate);

            if (
                timesheetsList?.findIndex(
                    timesheet => timesheet.yearMonth === selectedDate
                ) >= 0
            ) {
                setSubmitDisabled(true);
            } else {
                setSubmitDisabled(false);
            }
        },
        [setFieldValue, name, timesheetsList, setSubmitDisabled]
    );

    return (
        <FormField
            label={label}
            required
            errorMessage={meta.error}
            showError={showError}
        >
            <DatePicker
                {...field}
                value={getDatefromIso(value)}
                onChange={handleChange}
                shouldDisplayDayCalendar={false}
                hasError={showError}
                maxDate={new Date()}
                minDate={getDatefromIso('2017-01-01')}
                data-testid="month-picker"
            />
        </FormField>
    );
};
