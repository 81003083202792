import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import { palette, Table as TableComponent } from 'workmotion-design-system';

import { formatUTCDate } from 'app/shared/date-utils';
import { en } from 'localization';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { Link, LinkName, TableCell } from './styled-components';
import { ActionItems } from '../../action-item';

const {
    employeeTimesheet: {
        table: {
            columns: { monthCell, submittedDateCell, receiptCell },
        },
    },
} = en;

type TableProps = {
    setSelectedTimesheet: (timesheet: TimesheetListingResponseDTO) => void;
    setModalOpen: (isOpen: boolean) => void;
    content: TimesheetListingResponseDTO[];
};

export const Table: React.FC<TableProps> = ({ content, ...props }) => {
    const tableColumns = [
        {
            cell: monthCell,
        },
        {
            cell: submittedDateCell,
        },
        {
            cell: receiptCell,
        },
        {
            cell: '',
        },
    ];

    const tableRows = content.map(timesheet => ({
        timesheet,

        cells: [
            <TableCell key={timesheet.id}>
                {formatUTCDate(timesheet.yearMonth, 'MONTH').toUpperCase()}
            </TableCell>,
            <TableCell key={timesheet.id}>
                {timesheet.createdAt
                    ? formatUTCDate(timesheet.createdAt, 'DATE').toUpperCase()
                    : '-'}
            </TableCell>,
            <Link
                href={timesheet.downloadUrl}
                target="_blank"
                rel="noreferrer noopener"
                key={timesheet.id}
            >
                <DescriptionIcon
                    style={{
                        color: palette.greyscale.UCLABlue,
                        width: 14,
                    }}
                />

                <LinkName>{timesheet.fileName}</LinkName>
            </Link>,
            <ActionItems timesheet={timesheet} key={timesheet.id} {...props} />,
        ],
    }));

    return (
        <div data-testid="table">
            <TableComponent
                backgroundColor={`${palette.secondary.white}`}
                columns={tableColumns}
                rows={tableRows}
                withHover
            />
        </div>
    );
};
