import React, { useState, useCallback, useEffect } from 'react';

import { format } from 'date-fns';

import { DateFilter } from 'app/time-sheet/employee-view/filter';
import {
    StatusFilter,
    TypeFilter,
} from 'app/time-tracking/employee-view/filter';
import {
    TimeTrackingRecordFilterStatusEnum,
    TimeTrackingRecordTypeEnum,
} from 'app/time-tracking/enums';
import { COUNTRY_OPTIONS } from 'constant';
import { UsePaginationReturn } from 'helpers/use-pagination';
import { FiltersMenu } from 'shared-components/filters-menu';
import { QueryParameters } from 'types';

import { CountryFilter } from '../country-filter';

export interface DefaultFiltersMenuProps {
    handleChangeFilters: UsePaginationReturn['handleChangeFilters'];
    removeFilters: UsePaginationReturn['removeFilters'];
    requestParams: QueryParameters;
    withCountry: boolean;
    withRecordStatus?: boolean;
    withRecordType?: boolean;
}

const DefaultFiltersMenu: React.FC<DefaultFiltersMenuProps> = ({
    handleChangeFilters,
    removeFilters,
    requestParams,
    withCountry,
    withRecordStatus,
    withRecordType,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    const [yearMonth, setYearMonth] = useState<string>(currentDate);

    const [countryIsoCode, setCountryIsoCode] = useState<string>('all');

    const [recordStatus, setRecordStatus] =
        useState<TimeTrackingRecordFilterStatusEnum>(
            TimeTrackingRecordFilterStatusEnum.ALL
        );

    const [recordType, setRecordType] = useState<TimeTrackingRecordTypeEnum>(
        TimeTrackingRecordTypeEnum.ALL
    );

    const onMonthSelect = useCallback((date: string) => {
        setYearMonth(date);
    }, []);

    const onStatusSelect = useCallback((value: string) => {
        setRecordStatus(value as TimeTrackingRecordFilterStatusEnum);
        setIsMenuOpen(true);
    }, []);

    const onTypeSelect = useCallback((value: string) => {
        setRecordType(value as TimeTrackingRecordTypeEnum);
        setIsMenuOpen(true);
    }, []);

    const onCountryChange = useCallback((value: string) => {
        setCountryIsoCode(value);
        setIsMenuOpen(true);
    }, []);

    const applyFilters = useCallback(() => {
        let params;

        if (yearMonth) {
            params = { ...params, yearMonth };
        }
        if (countryIsoCode && countryIsoCode !== 'all')
            params = { ...params, countryIsoCode };

        if (countryIsoCode && countryIsoCode === 'all')
            removeFilters(['countryIsoCode']);

        if (
            recordStatus &&
            recordStatus !== TimeTrackingRecordFilterStatusEnum.ALL
        ) {
            params = { ...params, status: recordStatus };
        }

        if (recordStatus === TimeTrackingRecordFilterStatusEnum.ALL) {
            removeFilters(['status']);
        }

        if (recordType && recordType !== TimeTrackingRecordTypeEnum.ALL) {
            params = { ...params, recordType: recordType };
        }

        if (recordType === TimeTrackingRecordTypeEnum.ALL) {
            removeFilters(['recordType']);
        }

        handleChangeFilters(params);

        setIsMenuOpen(false);
    }, [
        countryIsoCode,
        handleChangeFilters,
        recordStatus,
        recordType,
        removeFilters,
        yearMonth,
    ]);

    const onMenuClose = useCallback(() => {}, []);

    const resetFilters = useCallback(() => {
        setYearMonth(currentDate);
        setCountryIsoCode('all');
        setRecordStatus(TimeTrackingRecordFilterStatusEnum.ALL);
        setRecordType(TimeTrackingRecordTypeEnum.ALL);
        setIsMenuOpen(false);

        removeFilters(['yearMonth', 'countryIsoCode', 'status', 'recordType']);
    }, [currentDate, removeFilters]);

    useEffect(() => {
        if (requestParams.yearMonth) setYearMonth(requestParams.yearMonth);
        if (requestParams.countryIsoCode)
            setCountryIsoCode(requestParams.countryIsoCode);
        if (requestParams.status)
            setRecordStatus(
                requestParams.status as TimeTrackingRecordFilterStatusEnum
            );
        if (requestParams.type)
            setRecordType(requestParams.type as TimeTrackingRecordTypeEnum);
    }, [
        requestParams.countryIsoCode,
        requestParams.status,
        requestParams.type,
        requestParams.yearMonth,
    ]);

    return (
        <FiltersMenu
            isOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            alignment="bottom-right"
            onReset={resetFilters}
            onApply={applyFilters}
            onClose={onMenuClose}
        >
            {withRecordType && (
                <TypeFilter initialValue={recordType} onChange={onTypeSelect} />
            )}

            {withRecordStatus && (
                <StatusFilter
                    initialValue={recordStatus}
                    onChange={onStatusSelect}
                />
            )}

            {withCountry && (
                <CountryFilter
                    value={countryIsoCode}
                    onCountryChange={onCountryChange}
                    options={COUNTRY_OPTIONS}
                />
            )}

            <DateFilter
                onMonthSelect={onMonthSelect}
                initialSearchValue={yearMonth}
            />
        </FiltersMenu>
    );
};

export { DefaultFiltersMenu };
