import React from 'react';

import { Avatar } from 'workmotion-design-system';

import { Talent } from 'types/timesheet';

const TalentName: React.FC<{ talent: Talent }> = ({ talent }) => (
    <Avatar
        text={{
            title: `${talent.firstName.replaceAll(
                '-',
                ''
            )} ${talent.lastName.replaceAll('-', '')}`,
            placeholder: `${talent.firstName} ${talent.lastName}`,
        }}
        size="small"
        key={talent.id}
    ></Avatar>
);

export default TalentName;
