import type { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const Link = styled.a({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
});

export const LinkName = styled.span({
    color: palette.greyscale.persianBlue,
    fontSize: '.875rem',
    overflow: 'hidden',
    maxWidth: '15rem',
    fontWeight: 400,
    textDecoration: 'none',
    marginInlineStart: 4,
});

export const mobileActionsContainer: SxProps = {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
};

export const mobileIconWrapper: SxProps = {
    backgroundColor: palette.greyscale.antiFlashWhite,
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    '&:first-of-type': {
        marginInlineEnd: '1.5rem',
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.greyscale.ghostWhite,
    },
};

export const spinnerContainer: SxProps = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '10rem 0',
};

export const TableCell = styled.div({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: palette.greyscale.persianBlue,
});

export const ActionItemsCellContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});
