import React, { useCallback } from 'react';

import { TimePickerRecord } from './time-picker';

const RecordStartTime = ({
    id,
    onUpdate,
    name,
    label,
    disabled = false,
    dataCy = '',
    fieldValue = '',
}) => {
    const handleUpdateTime = useCallback(
        updatedEndTime => {
            onUpdate(updatedEndTime);
        },
        [onUpdate]
    );

    return (
        <TimePickerRecord
            fieldName={name}
            label={label}
            id={`${id}+time-tracking-start-time`}
            dataCy={dataCy}
            fieldValue={fieldValue}
            onChange={handleUpdateTime}
            disabled={disabled}
        />
    );
};

export { RecordStartTime };
