import React from 'react';

import { useFlags } from '@workmotion/feature-flags-library';
import {
    CountryFlag,
    Modal,
    ModalButtonsFooter,
} from 'workmotion-design-system';

import {
    convertMinutesToHoursAndMinutes,
    formatDateString,
} from 'app/shared/date-utils';
import { TimeTrackingRecordTypeMapper } from 'app/time-tracking/helpers';
import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

import { StatusLabel } from '../time-tracking-list/status-label';
import {
    StyledInfoDetail,
    StyledInfoLabel,
    StyledInfoWrapper,
} from '../time-tracking-list/styled-components';

type ModalProps = {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedTimeTrackingRecord: TimeTrackingRecordResponseDTO;
};

const PreviewRecordModal: React.FC<ModalProps> = ({
    modalIsOpen,
    closeModal,
    selectedTimeTrackingRecord,
}) => {
    const {
        employeeTimeTracking: {
            previewTimeTrackingModal: {
                title,
                fields: {
                    talent,
                    timeIn,
                    timeOut,
                    type,
                    breaks,
                    workingDuration,
                    recordDate,
                    status,
                    country,
                },
            },
        },
    } = en;

    const { talent423LttEnhancements } = useFlags();

    const {
        data: { isAdmin, isEmployee },
    } = useUserIdentity();

    return (
        <Modal
            data-testid="preview-record-header"
            isOpen={modalIsOpen}
            closeModal={closeModal}
            header={title}
            footer={
                <ModalButtonsFooter
                    placement="flex-end"
                    buttonsInfo={[
                        {
                            text: 'Close',
                            onClick: closeModal,
                        },
                    ]}
                />
            }
        >
            <div>
                <section>
                    {talent423LttEnhancements && (
                        <StyledInfoWrapper>
                            <StyledInfoLabel data-testid="preview-record-status-label">
                                {status.label}
                            </StyledInfoLabel>

                            <StyledInfoDetail data-testid="preview-record-status">
                                <StatusLabel
                                    status={selectedTimeTrackingRecord?.status}
                                />
                            </StyledInfoDetail>
                        </StyledInfoWrapper>
                    )}

                    {!isEmployee && (
                        <StyledInfoWrapper>
                            <StyledInfoLabel data-testid="preview-record-talent-name-label">
                                {talent.label}
                            </StyledInfoLabel>

                            <StyledInfoDetail data-testid="preview-record-talent-name">
                                {`${selectedTimeTrackingRecord?.talentInfo?.firstName} ${selectedTimeTrackingRecord?.talentInfo?.lastName} `}
                            </StyledInfoDetail>
                        </StyledInfoWrapper>
                    )}

                    {isAdmin && (
                        <StyledInfoWrapper>
                            <StyledInfoLabel data-testid="preview-record-country-label">
                                {country.label}
                            </StyledInfoLabel>

                            <StyledInfoDetail data-testid="preview-record-country-name">
                                <CountryFlag
                                    countryIsoCode={
                                        selectedTimeTrackingRecord?.countryIsoCode
                                    }
                                    customStyledFlagIcon={{
                                        width: '1.5rem',
                                        height: '1.5rem',
                                        borderRadius: '50%',
                                        marginRight: '0.4rem',
                                    }}
                                    labelStyles={{
                                        fontSize: '0.875rem',
                                        display: 'contents',
                                    }}
                                />
                            </StyledInfoDetail>
                        </StyledInfoWrapper>
                    )}

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-date-label">
                            {recordDate.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-date">
                            {formatDateString(selectedTimeTrackingRecord?.date)}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-type-label">
                            {type.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-type">
                            {TimeTrackingRecordTypeMapper(
                                selectedTimeTrackingRecord?.recordType
                            )}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-time-in-label">
                            {timeIn.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-time-in">
                            {selectedTimeTrackingRecord?.startTime ?? '-'}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-time-out-label">
                            {timeOut.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-time-out">
                            {selectedTimeTrackingRecord?.endTime ?? '-'}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-breaks-label">
                            {breaks.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-breaks">
                            {selectedTimeTrackingRecord?.breaksDurationMinutes
                                ? convertMinutesToHoursAndMinutes(
                                      selectedTimeTrackingRecord?.breaksDurationMinutes
                                  )
                                : '-'}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>

                    <StyledInfoWrapper>
                        <StyledInfoLabel data-testid="preview-record-working-duration-label">
                            {workingDuration.label}
                        </StyledInfoLabel>

                        <StyledInfoDetail data-testid="preview-record-working-duration">
                            {selectedTimeTrackingRecord?.workingDurationMinutes
                                ? convertMinutesToHoursAndMinutes(
                                      selectedTimeTrackingRecord?.workingDurationMinutes
                                  )
                                : '-'}
                        </StyledInfoDetail>
                    </StyledInfoWrapper>
                </section>
            </div>
        </Modal>
    );
};

export default PreviewRecordModal;
