//this is a copy-pasting solution from onboarding-frontend and we use it in contract changes
import { parse, format } from 'date-fns';

type FormatOption = 'MONTH' | 'DATE';

const formatOptions = new Map<FormatOption, Record<string, string>>([
    ['MONTH', { timeZone: 'UTC', month: 'long', year: 'numeric' }],
    [
        'DATE',
        { timeZone: 'UTC', month: 'short', year: 'numeric', day: '2-digit' },
    ],
]);

const parseFormat = (formatOpt: FormatOption): Record<string, string> =>
    formatOptions.get(formatOpt) || {};

export const formatUTCDate = (
    dateIso: string,
    viewType: FormatOption = 'DATE'
): string => {
    if (!dateIso) return null;
    const date = new Date(Date.parse(dateIso));

    const options = parseFormat(viewType);

    const formatter = new Intl.DateTimeFormat('en-GB', options);

    return formatter.format(date).toUpperCase();
};

export const getDatefromIso = (date: string): Date => {
    if (date) {
        return parse(date, 'yyyy-MM-dd', new Date());
    }

    return null;
};

export const getFormattedIsoDate = (date: Date): string => {
    if (date) {
        return format(date, 'yyyy-MM-dd');
    }

    return null;
};

export const getDayAndMonth = (input: Date | string): string => {
    const date =
        typeof input === 'string'
            ? parse(input, 'yyyy-MM-dd', new Date())
            : input;

    return format(date, 'EEE, d MMM yyyy');
};

export const getFirstDayofMonth = (date: Date): string => {
    if (date) {
        return format(date, 'yyyy-MM-01');
    }

    return null;
};

const formatDuration = (hours: number, minutes: number): string => {
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours} hr ${formattedMinutes} mins`;
};

export const convertMinutesToHoursAndMinutes = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return formatDuration(hours, remainingMinutes);
};

export const formatDateString = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };

    const formattedDate = date
        .toLocaleDateString('en-US', options)
        .toUpperCase();

    const [month, day, year] = formattedDate.split(' ');

    return `${day} ${month} ${year}`;
};
