import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ConfirmationCardContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `solid 1px ${palette.primary.genericViridian}`,
    borderRadius: '.5rem',
    backgroundColor: palette.tertiary.aquaHaze,
    margin: '1.5rem 0',
    padding: '1.5rem',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1.5rem',
        padding: '1.5rem 1rem',
    },
});

export const ConfirmationCardTitle = styled.h1({
    color: palette.primary.genericViridian,
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 600,
    marginBottom: '.75rem',
});

export const ConfirmationCardLearnMore = styled.h6({
    color: palette.primary.genericViridian,
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 600,
    marginBottom: '.75rem',
});

export const ConfirmationCardContent = styled.h2({
    color: palette.greyscale.UCLABlue,
    fontSize: '.875rem',
    lineHeight: '1rem',
    marginBottom: '0.75rem',
});

export const ConfirmationCardContentInfo = styled.h2({
    color: palette.greyscale.UCLABlue,
    fontSize: '.875rem',
    lineHeight: '1rem',
});

export const CardContentBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (max-width: 600px)': {
        maxWidth: '100%',
    },
};

export const ButtonsBox = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    gap: '1rem',

    '@media(max-width:600px)': {
        flexDirection: 'column',
        gap: '1rem',
        button: {
            minWidth: '100%',
        },
    },
};

export const ConfirmationCardWorkingHours = styled.span({
    color: palette.primary.genericViridian,
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 400,
    marginBottom: '.75rem',
});
