import React from 'react';

import { RecordDatePicker } from './record-date-picker';

const RecordDate = ({
    name,
    label = '',
    value = '',
    minDate,
    disabled = false,
}) => (
    <RecordDatePicker
        fieldName={name}
        label={label}
        id="time-tracking-date-field"
        dataCy="time-tracking-date-picker-field"
        maxDate={new Date()}
        fieldValue={value}
        disabled={disabled}
        minDate={minDate}
    />
);

export { RecordDate };
