import React from 'react';

import { Box } from '@mui/material';
import { Spinner } from 'workmotion-design-system';

import { useWindowSize } from 'helpers/use-window-size';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { MobileList } from './mobile-list';
import { NoTimesheets } from './no-timesheets';
import { spinnerContainer } from './styled-components';
import { Table } from './table';

type TimesheetListProps = {
    isLoading: boolean;
    content: TimesheetListingResponseDTO[];
    setSelectedTimesheet: (timesheet: TimesheetListingResponseDTO) => void;
    setModalOpen: (isOpen: boolean) => void;
};

export const TimesheetList: React.FC<TimesheetListProps> = ({
    isLoading,
    content,
    setSelectedTimesheet,
    setModalOpen,
}) => {
    const { isMobileSize } = useWindowSize();

    if (isLoading) {
        return (
            <Box sx={spinnerContainer} data-testid="timesheet-loading">
                <Spinner />
            </Box>
        );
    }

    if (isMobileSize && content?.length) {
        return (
            <div data-testid="mobile-table-component">
                <MobileList
                    setSelectedTimesheet={setSelectedTimesheet}
                    setModalOpen={setModalOpen}
                    content={content}
                />
            </div>
        );
    }

    if (!isMobileSize && content?.length) {
        return (
            <div data-testid="table-component">
                <Table
                    setSelectedTimesheet={setSelectedTimesheet}
                    setModalOpen={setModalOpen}
                    content={content}
                />
            </div>
        );
    }

    return <NoTimesheets data-testid="no-timesheets" />;
};
