import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledTable = styled.table({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 20px',
});

export const StyledTableHeadRow = styled.tr({
    background: '#EAECF0',
    height: 54,
    textAlign: 'center',
});

export const StyledTableHeadCell = styled.th({
    verticalAlign: 'middle',
    color: '#5A6487',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '11px',
    textTransform: 'uppercase',
});

export const StyledSectionWrapper = styled.section({
    padding: '125px 0',
    minHeight: 456,
    backgroundColor: '#F5F6FA',
    border: '1px solid #D9DCE3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const StyledTitle = styled.h4({
    marginTop: 18,
    color: '#00234B',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '28px',
    marginBottom: 8,
});

export const StyledSubTitle = styled.p({
    color: '#5A6487',
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 500,
    marginBottom: 28,
});

export const StyledTableBodyRow = styled.tr({
    background: '#FBFBFC',
    height: 75,
    marginTop: 20,
    textAlign: 'center',
    outline: '1px solid #D9DCE3',
});

export const StyledTableCell = styled.td({
    fontSize: 15,
    color: '#00234B',
    lineHeight: '17px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    borderRadius: 3,
    verticalAlign: 'middle',
    padding: '0 10px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 150,
});

export const StyledActionName = styled.span({
    '@media(max-width: 1490px)': {
        display: 'none',
    },
});

export const StyledEditContainer = styled.div({
    textTransform: 'uppercase',
    color: palette.primary.genericViridian,
    fontSize: 14,
    lineHeight: '11px',
    letterSpacing: -0.06,
    margin: '0 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 8px',
    borderRadius: 3,
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#E2EEEE',
    },
});

export const StyledTableLinkContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const StyledTableLinkName = styled.a({
    color: '#00234B',
    maxWidth: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
});
