import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const MainContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
});

export const MainText = styled.h1({
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.5rem',
    color: palette.greyscale.persianBlue,
    marginBottom: '0.5rem',
});

export const SubText = styled.h2({
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    color: palette.greyscale.UCLABlue,
    marginBottom: '2rem',
});

export const ModalButtonsFooterCustomStyle = {
    padding: '2rem 0 0',
    margin: '0 0',
    borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
};

export const errorBox = {
    color: palette.semantic.red,
    marginBottom: '2rem',
};
