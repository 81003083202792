const enSomethingWentWrong =
    'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.';

export const en = {
    generic: {
        pageNotFoundScreen: {
            title: 'Page not found',
            subTitle1: 'The page you were looking for could not be found.',
            subTitle2:
                'It might have been removed, renamed, or did not exist in the first place.',
        },
        genricError: enSomethingWentWrong,
    },
    sharedComponents: {
        errorFallback: {
            somethingWentWrong: 'Hmm.. Something went wrong.',
            weHaveBeenNotified:
                'We have been notified with the problem and we will be investigating this as fast as we can',
            retry: 'Retry',
        },
        filtersMenu: {
            applyButton: 'APPLY',
            resetButton: 'RESET',
            exportButton: 'Export',
            cancelButton: 'Cancel',
            placeholders: {
                startDatePlaceholder: 'Start date',
                endDatePlaceholder: 'End date',
                payrollCycleFilterPlaceholder: 'Payroll cycle',
                dateTypePlaceholder: 'Date type',
            },
        },
    },

    employeeTimesheet: {
        submitTimesheetAction: 'Submit timesheet',
        downloadTemplateAction: 'Download template',
        filterPlaceholder: 'Filter by month',
        confirmationCard: {
            title: (name: string) => `Have a good day ${name},`,
            content: (hours: number, month: string): string =>
                `Can you confirm that if you have worked ${hours} hours each week in ${month}?`,
            cancelButton: 'No, I would upload my own timesheet',
            contentInfo:
                'Please note that you need to edit the timesheet before submitting in case you have taken any vacation days or there are any national holidays this month.',
            confirmButton: 'Confirm',
            submittedMonthMessage: (month: string) =>
                `Your timesheet for the ${month} was submitted. If you need to make any change please edit the submitted timesheet.`,
        },
        table: {
            columns: {
                monthCell: 'Month',
                submittedDateCell: 'Submitted date',
                receiptCell: 'Receipt',
            },
            actionItems: {
                edit: 'Edit',
                download: 'Download sheet',
            },
        },
        hint: {
            comment: 'Download the template & fill it then upload it again.',
            action: 'Download',
        },
        uploadModal: {
            modalTitle: 'Upload timesheet',
            mainText: 'Please upload your timesheet',
            subText:
                'The template must be downloaded, filled out, and re-uploaded.',

            filePlaceholder: 'Attach file',
            linkToDownload: 'Download',
            uploadFileBtn: 'Upload File',
            uploadFileError: 'you need to attach timesheet file',

            cancelBtn: 'Cancel',
            saveBtn: 'Submit',
            successMessage: 'Your timesheet was uploaded successfully.',
            modalFields: {
                month: {
                    fieldName: 'payroll-month',
                    label: 'Month',
                },
                timesheetFile: { fieldName: 'timesheet-file', label: 'Upload' },
            },
            validationMessages: {
                uploadFileError: 'you need to attach timesheet file',
                selectedMonthRequired: 'Month is required',
            },
        },
        submitWithMonthModal: {
            modalTitle: 'Upload timesheet',
            mainText: 'Select the month then upload your timesheet',
            subText:
                'The template must be downloaded, filled out, and re-uploaded.',
            cancelBtn: 'Cancel',
            saveBtn: 'Submit',
            successMessage: 'Your timesheet was submitted successfully.',
            uploadFileError: 'you need to attach timesheet file',
        },
        noTimesheets: {
            title: 'No timesheets added yet',
            message:
                'You will find the list of timesheets here once we start submitting timesheets',
        },
    },
    pagination: {
        dropdownPrefix: 'Show',
        entityName: 'Timesheet',
        getPagesRangeText: (
            fromPage: number,
            toPage: number,
            total: number,
            entity: string
        ): string => `${fromPage} - ${toPage} of ${total} ${entity}`,
    },
    managedTimeSheetList: {
        table: {
            columns: [
                'TALENT',
                'COUNTRY',
                'MONTH',
                'SUBMITTED DATE',
                'TIMESHEET',
                '',
            ],
            actionItems: {
                edit: 'Edit',
                download: 'Download sheet',
            },
        },
        employeeFilter: {
            employeeFilterPlaceholder: 'Search for employee',
            noEmployeesFound: 'No employees found',
        },
        downloadReportBtn: 'Download Report',
    },
    employeeTimeTracking: {
        addRecord: 'Add Record',
        defineSchedule: 'Define Schedule',
        editSchedule: 'Edit Schedule',
        submitSelected: 'Submit Selected',
        downloadTemplateAction: 'Download',
        filterPlaceholder: 'Filter by month',
        exportMessage: `Your query is running. Once it's complete, we will send you an email with a link to download the report`,
        updateRecordsSuccessMessage: 'Records has been submitted successfully!',
        confirmationCard: {
            title: (name: string) => `Hello ${name},`,
            content: `Welcome to WorkMotion's live time tracking feature. Your weekly contractual working hours are `,
            hours: ` hours.`,
            contentInfo: 'How does time tracking work? ',
        },
        table: {
            columns: {
                nameCell: 'Talent',
                countryCell: 'Country',
                dateCell: 'Date',
                type: 'Type',
                clockIn: 'Clock In',
                clockOut: 'Clock Out',
                breaks: 'Breaks',
                workingDuration: 'Working Duration',
                recordStatus: 'Status',
            },
            actionItems: {
                edit: 'Edit',
                download: 'Download sheet',
                preview: 'Preview',
                remove: 'Delete',
            },
        },
        hint: {
            comment: 'Download the template & fill it then upload it again.',
            action: 'Download',
        },

        noTimeTrackingRecords: {
            title: 'This is where you will see your time tracking records',
            message: 'Simply click on Add Record to create a new record.',
        },
        sideMenuInfo: {
            informationTitle: 'How does time tracking work?',
            description: {
                descriptionPart1:
                    'You are now able to define your working schedule by clicking on ',
                descriptionPart2: 'Define Schedule ',
                descriptionPart3:
                    'where you can select your weekly working days & hours, after that:',
                descriptionPart4:
                    'You are still able to go back and edit your records, to update your clock in, clock out or break times if needed.',
                descriptionPart5:
                    'Your time-off requests (vacations, sick leaves, etc.) will be automatically added based on approved requests from the time-off feature, as well as public holidays.',
            },

            learnMore: 'Learn More',
            title: 'Time Tracking',
            descriptionTitle: 'How does time tracking work?',
            infoTipText:
                'When defining your working schedule, please try to choose slightly different working hours for each day even if by 5 minutes, to avoid any compliance issues.',
            information: {
                info: {
                    typesDescription: [
                        {
                            name: 'Step 1 ',
                            descriptionFirstPart:
                                'Your working day records will be automatically created on a daily basis based on the defined schedule',
                        },
                        {
                            name: 'Step 2 ',
                            descriptionFirstPart:
                                'All automatically created records will be created with status ',
                            pendingStatus: 'Pending',
                        },
                        {
                            name: 'Step 3 ',
                            descriptionFirstPart: 'You can submit all ',
                            pendingAction: 'Pending ',
                            descriptionSecondPart:
                                'days by simply selecting them from the checkboxes on the left hand-side',
                        },
                        {
                            name: 'Step 4 ',
                            descriptionFirstPart:
                                'Once you submit your records, the status will be changed to ',
                            submittedStatus: 'Submitted',
                        },
                        {
                            name: 'Step 5 ',
                            descriptionFirstPart:
                                'You are also able to edit your working schedule by clicking on ',
                            scheduleAction: 'Edit Schedule ',
                            descriptionSecondPart:
                                'if needed, and the changes will be effective the next day',
                        },
                    ],
                },
            },
        },
        createTimeTrackingModal: {
            add: 'Add Record',
            edit: 'Edit Record',
            saveChangeButton: 'Save Changes',
            cancelButtonLabel: 'Cancel',
            submitButtonLabel: 'Submit',
            addNewBreak: 'Add Break',
            addSuccessMessage: 'Record has been created successfully!',
            updateSuccessMessage: 'Record has been updated successfully!',
            somethingWentWrong:
                'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
            fields: {
                startTime: {
                    label: 'Clock in*',
                    fieldName: 'startTime',
                },
                endTime: {
                    label: 'Clock out*',
                    fieldName: 'endTime',
                },
                breakStartTime: {
                    label: 'Start time*',
                    fieldName: 'breakStartTime',
                },
                breakEndTime: {
                    label: 'End time*',
                    fieldName: 'breakEndTime',
                },
                date: {
                    label: 'Select day*',
                    fieldName: 'date',
                },
                talentName: {
                    label: 'Talent Name*',
                    fieldName: 'talentId',
                },
                breaks: {
                    label: 'Breaks',
                    fieldName: 'breaks',
                },
            },
            validationMessages: {
                clockInRequired: 'Clock In Time is required',
                dateRequired: 'Date is required',
                talentNameRequired: 'Talent Name is required',
            },
        },
        previewTimeTrackingModal: {
            title: 'Time Tracking Details',
            fields: {
                talent: {
                    label: 'Talent',
                },
                recordDate: {
                    label: 'Date',
                },
                type: {
                    label: 'Type',
                },
                timeIn: {
                    label: 'Time in',
                },
                timeOut: {
                    label: 'Time out',
                },
                breaks: {
                    label: 'Breaks',
                },
                workingDuration: {
                    label: 'Working duration',
                },
                status: {
                    label: 'Status',
                },
                country: {
                    label: 'Country',
                },
            },
        },
        deleteTimeTrackingModal: {
            title: 'Delete confirmation',
            subtitle:
                'Are you sure you want to delete {{name}}? This process cannot be undone.',
            cancelButton: 'Discard',
            deleteButton: 'Confirm',
            deleteSuccessMessage: 'Record has been deleted successfully!',
            deleteErrorMessage: enSomethingWentWrong,
        },
        submitTimeTrackingRecordsConfirmationModal: {
            title: 'Confirmation',
            subtitle: `By submitting this form, you confirm that you have
worked these hours, Are you sure you want to continue?`,
            cancelButton: 'Cancel',
            confirmButton: 'Confirm',
            sumbitSuccessMessage: 'Records has been submitted successfully!',
            submitErrorMessage:
                'Please check your submitted records must have Clock Out time.',
        },
        defineTimeTrackingScheduleModal: {
            title: 'Define schedule',
            infoTip: {
                text: 'Please define your weekly working schedule by selecting your working days, and start, end, and break times. Your daily working days records will be automatically created based on the defined schedule.',
            },
            saveChangeButton: 'Save Changes',
            cancelButtonLabel: 'Cancel',
            submitButtonLabel: 'Save Schedule',
            breakLabel: 'Break',
            addBreakLabel: 'Add break',
            copyAll: 'Copy to all',
            addSuccessMessage: 'Schedule has been created successfully!',
            updateSuccessMessage: 'Schedule has been updated successfully!',
            fields: {
                startTime: {
                    label: 'Clock in*',
                    fieldName: 'startTime',
                },
                endTime: {
                    label: 'Clock out*',
                    fieldName: 'endTime',
                },
                breakStartTime: {
                    label: 'Start time*',
                    fieldName: 'startTime',
                },
                breakEndTime: {
                    label: 'End time*',
                    fieldName: 'endTime',
                },
            },
            validationMessages: {
                requiredField: 'Field is required',
            },
        },
    },
};
