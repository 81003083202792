/* eslint-disable sonarjs/no-redundant-jump */
import { toast } from 'react-toastify';

import {
    TimeTrackingRecordTypeDTO,
    TimeTrackingRecordResponseDTO,
} from 'types/timeTracking';

export const TimeTrackingRecordTypeMapper = (
    type: TimeTrackingRecordTypeDTO
): string => {
    switch (type) {
        case 'WORKING_DAY':
            return 'Working day';
        case 'VACATION':
            return 'Vacation';
        case 'SICK_LEAVE':
            return 'Sick leave';
        case 'PARENTAL':
            return 'Parental';
        case 'MATERNAL':
            return 'Maternal';
        case 'UNPAID_LEAVE':
            return 'Unpaid leave';
        case 'SPECIAL_LEAVE':
            return 'Special leave';
        case 'PUBLIC_HOLIDAY':
            return 'Public holiday';
        case 'OTHERS':
            return 'Others';
    }
};

export const checkSelectedRecordsIncludesClockOut = (
    selectedTimeTrackingRecordList: TimeTrackingRecordResponseDTO[]
) => {
    let hasrecordsWithoutEndTime = true;

    const recordsWithoutEndTime = selectedTimeTrackingRecordList.filter(
        record => !record?.endTime
    );

    if (recordsWithoutEndTime.length > 0) {
        hasrecordsWithoutEndTime = false;

        toast(
            ` ${recordsWithoutEndTime
                .map(record => record?.date)
                .join(', ')},  must  ${
                recordsWithoutEndTime.length > 1 ? 'have' : 'has'
            } Clock Out.`,
            {
                type: 'error',
            }
        );
    } else {
        hasrecordsWithoutEndTime = true;
    }

    return hasrecordsWithoutEndTime;
};
