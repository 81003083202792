import { QueryParameters } from 'types';
import {
    AddOrUpdateTimeTrackingRecordRequest,
    TimeTrackingRecordExportRequest,
    TimeTrackingRecordScheduleRequest,
    TimeTrackingRecordSubmitRequest,
} from 'types/timeTracking';

import { createTimesheetClient as createTimeTrackingClient } from './open-api-client';

export async function getTimeTrackingRecords(
    params: QueryParameters,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.getRecords(params as any);

    return response.data;
}

export async function updateTimeTrackingRecords(
    data: AddOrUpdateTimeTrackingRecordRequest,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.addOrUpdateRecord({}, { ...data });

    return response.data;
}

export async function addTimeTrackingRecords(
    data: AddOrUpdateTimeTrackingRecordRequest,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.addRecord({}, { ...data });

    return response.data;
}

export async function generateTimeTrackingReport(
    params: QueryParameters,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const reponse = client.generateTimeTrackingXlsxReport(params as any);

    return (await reponse).data;
}

export async function deleteTimeTrackingRecord(
    recordId: string,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.deleteRecord(recordId, {});

    return response.data;
}

export async function getTimeTrackingSchedule(talentId: any, token: string) {
    const client = await createTimeTrackingClient(token);

    const response = await client.getTalentSchedule(talentId);

    return response.data;
}

export async function addTalentTimeTrackingSchedule(
    talentId: string,
    data: TimeTrackingRecordScheduleRequest,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.addTalentSchedule(talentId, { ...data });

    return response.data;
}

export async function submitTimeTrackingStatus(
    talentId: string,
    data: string[],
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.submitRecords(talentId, data);

    return response.data;
}

export async function submitTimeTrackingStatusV2(
    data: TimeTrackingRecordSubmitRequest,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.submitRecordsV2({}, { ...data });

    return response.data;
}

export async function exportTimeTrackingRecordsV2(
    data: TimeTrackingRecordExportRequest,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.exportRecordsInXlsxV2({}, { ...data });

    return response.data;
}

export async function downloadTimeTrackingRecordsV2(
    fileKey: string,
    token: string
) {
    const client = await createTimeTrackingClient(token);

    const response = await client.getTimeTrackingXlsxFile(fileKey);

    return response.data;
}
