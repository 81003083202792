import React, { useRef, useState, useCallback, useMemo } from 'react';

import type { MenuState } from '@szhsin/react-menu';
import { useQuery } from '@tanstack/react-query';
import { useDebouncedCallback } from 'use-debounce';
import { TextField } from 'workmotion-design-system';

import { useUserIdentity } from 'authentication/use-user-identity';
import { getCurrentCompanyId } from 'helpers/get-current-company';
import { en } from 'localization';
import { useEmployeeRequests } from 'networking/employee-api/employee-network-requests';
import { ApiPaginationData, QueryParameters } from 'types';
import { EmployeeResponseDTO, GetEmployeesParams } from 'types/employee';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

import { SearchResultsMenu } from './search-results-menu';

const PAGE_SIZE = 10;

interface EmployeeFilterProps {
    onSelect?: (employee: EmployeeResponseDTO) => void;
    onRemove?: () => void;
    onUpdate?: (id: string) => void;
    onBlur?: () => void;
    initialSearchValue?: string;
    requestParams?: Partial<GetEmployeesParams>;
    label?: string;
    name?: string;
    selectedTimeTrackingRecord?: TimeTrackingRecordResponseDTO;
    isAddingRecordModal?: boolean;
}

const EmployeeFilter: React.FC<EmployeeFilterProps> = ({
    onSelect,
    onRemove,
    initialSearchValue,
    requestParams,
    onUpdate,
    onBlur,
    label,
    name,
    selectedTimeTrackingRecord,
    isAddingRecordModal = false,
}) => {
    const {
        managedTimeSheetList: {
            employeeFilter: { employeeFilterPlaceholder },
        },
    } = en;

    const [defaultParams, setDefaultParams] =
        useState<GetEmployeesParams>(null);

    const [isLoading, setIsLoading] = useState(false);

    const { getEmployees } = useEmployeeRequests();

    const [pageInfo, setPageInfo] = useState<ApiPaginationData>();

    const [employees, setEmployees] = useState<EmployeeResponseDTO[] | null>(
        null
    );

    const [searchValue, setSearchValue] = useState<string>(
        initialSearchValue || ''
    );

    const { data: employeeData } = useQuery({
        queryKey: ['employees', defaultParams, isAddingRecordModal],
        queryFn: () => getEmployees(defaultParams as QueryParameters),
        onSuccess: responseData => {
            setPageInfo(responseData.page);
            setEmployees(responseData.content);
        },
        onSettled: () => setIsLoading(false),
        enabled: !!defaultParams,
    });

    const [menuState, setMenuState] = useState<MenuState>('closed');

    const inputContainerRef = useRef<HTMLDivElement | null>(null);

    const {
        data: { companyId: userCompanyId, isAdmin },
    } = useUserIdentity();

    const selectedCompanyId = getCurrentCompanyId();

    const companyId = useMemo(() => {
        if (isAdmin) {
            return selectedCompanyId;
        }

        return userCompanyId;
    }, [isAdmin, selectedCompanyId, userCompanyId]);

    const fetchEmployees = useCallback(
        (searchTerm: string, pageNumber = 0) => {
            setEmployees(null);

            if (searchTerm && searchTerm.length >= 3) {
                setMenuState('open');
                setIsLoading(true);

                setDefaultParams({
                    searchTerm,
                    size: PAGE_SIZE,
                    page: pageNumber,
                    includeState: false,
                    includeSummary: false,
                    orderBy: 'st_filter.rank',
                    direction: 'desc',
                    ...requestParams,
                    companyId: companyId ? companyId : '',
                });

                return employeeData?.content;
            }

            return null;
        },
        [requestParams, companyId, employeeData?.content]
    );

    const debouncedFetchItems = useDebouncedCallback(
        (value: string) => setEmployees(fetchEmployees(value)),
        300
    );

    const onChange = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(value);

            const isValueValid = value && value.length >= 3;

            setMenuState(prev => {
                if (!isValueValid && prev === 'open') {
                    return 'closed';
                }

                return prev;
            });

            setEmployees(null);

            if (!isValueValid) {
                return;
            }

            debouncedFetchItems(value);
        },
        [debouncedFetchItems]
    );

    const closeMenu = useCallback(() => {
        setMenuState('closed');
    }, []);

    const openMenuOnFocus = useCallback(() => {
        if (employees?.length) {
            setMenuState('open');
        }
    }, [employees?.length]);

    const handleSelectEmployee = useCallback(
        (employee: EmployeeResponseDTO) => {
            setEmployees([employee]);
            setSearchValue(`${employee.firstName} ${employee.lastName}`);

            if (onSelect) {
                onSelect(employee);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                isAddingRecordModal && onUpdate(employee?.id);
            }
            closeMenu();
        },
        [closeMenu, onSelect, onUpdate, isAddingRecordModal]
    );

    const onKeypressHandler = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const keyCode = event.which || event.keyCode;

            if (
                (event.key === 'Enter' || keyCode === 13) &&
                searchValue.length === 0
            ) {
                onRemove();
            }
        },
        [searchValue, onRemove]
    );

    return (
        <div
            onBlur={closeMenu}
            onFocus={openMenuOnFocus}
            style={
                isAddingRecordModal
                    ? { marginBottom: '0.5rem' }
                    : { marginRight: 'auto', width: '20%', minWidth: 250 }
            }
            data-testid="talent-search"
        >
            <div
                ref={inputContainerRef}
                style={{ position: 'relative', height: 40 }}
            >
                <TextField
                    label={label}
                    name={name}
                    value={searchValue}
                    onChange={onChange}
                    onKeyPress={onKeypressHandler}
                    placeholder={
                        (selectedTimeTrackingRecord &&
                            `${selectedTimeTrackingRecord?.talentInfo?.firstName} ${selectedTimeTrackingRecord?.talentInfo?.lastName}`) ||
                        employeeFilterPlaceholder
                    }
                    data-testid="employee-filter-search-input"
                    onBlur={onBlur}
                    disabled={
                        isAddingRecordModal && !!selectedTimeTrackingRecord
                    }
                />
            </div>

            <SearchResultsMenu
                anchorRef={inputContainerRef}
                menuState={menuState}
                results={employees}
                isLoading={isLoading}
                pageInfo={pageInfo}
                searchTerm={searchValue}
                fetchEmployees={fetchEmployees}
                handleSelectEmployee={handleSelectEmployee}
                setEmployees={setEmployees}
            />
        </div>
    );
};

export { EmployeeFilter };
