import React from 'react';

import { TimeWrapper } from '../../styled-components';
import { ScheduleType } from '../constant';
import { RecordEndTime } from '../record-end-time';
import { RecordStartTime } from '../record-start-time';

interface TimeSectionProps {
    day?: ScheduleType;
    title?: string;
    startTime?: {
        label: string;
        fieldName: string;
    };
    endTime?: {
        label: string;
        fieldName: string;
    };
    onDayChange?: (field: string, value: string) => void;
    setIsDisabled?: (isDisabled: boolean) => void;
}

const TimeSection: React.FC<TimeSectionProps> = ({
    day,
    title,
    startTime,
    endTime,
    onDayChange,
    setIsDisabled,
}: TimeSectionProps) => (
    <TimeWrapper key="record-time">
        <RecordStartTime
            label={startTime.label}
            id={`${day[title]}-day-start-time`}
            dataCy={`${day[title]}-day-start-time-field`}
            fieldValue={day[title].startTime}
            onUpdate={time => onDayChange(startTime.fieldName, time)}
            setIsDisabled={setIsDisabled}
        />

        <RecordEndTime
            label={endTime.label}
            id={`${day[title]}-day-end-time`}
            dataCy={`${day[title]}-day-end-time-field`}
            fieldValue={day[title].endTime}
            onUpdate={time => onDayChange(endTime.fieldName, time)}
            setIsDisabled={setIsDisabled}
        />
    </TimeWrapper>
);

export default TimeSection;
