import React, { useCallback, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
    DeleteForeverIcon,
    Modal,
    ModalButtonsFooter,
    Spinner,
} from 'workmotion-design-system';

import { EmployeeFilter } from 'app/time-sheet/manage-view/employee-filter';
import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import {
    useAddTimeTrackingRecords,
    useUpdateTimeTrackingRecords,
} from 'networking/timesheet-api/time-tracking-mutations';
import {
    AddOrUpdateTimeTrackingRecordRequest,
    TimeTrackingBreakRecordResponseDTO,
    TimeTrackingRecordResponseDTO,
} from 'types/timeTracking';

import AddBreakIcon from './add_circle.svg';
import {
    CreateTimeTrackingSchema,
    getInitialValues,
} from './formik-values-setup';
import { FieldErrorMessage } from './label';
import { RecordDate } from './record-date';
import { RecordEndTime } from './record-end-time';
import { RecordStartTime } from './record-start-time';
import {
    IconWrapper,
    MainContainer,
    ModalButtonsFooterCustomStyle,
    StyledAddEntryWrapper,
    StyledBreakHeaderContainer,
    StyledBreakTitle,
    StyledRemoveIcon,
} from './styled-components';
import { BreakWrapper, TimeWrapper } from '../styled-components';

type ModalProps = {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedTimeTrackingRecord: TimeTrackingRecordResponseDTO;
    contractStartDate?: string;
};

const AddRecordModal: React.FC<ModalProps> = ({
    modalIsOpen,
    closeModal,
    selectedTimeTrackingRecord,
    contractStartDate,
}) => {
    const {
        employeeTimeTracking: {
            createTimeTrackingModal: {
                fields: {
                    startTime,
                    endTime,
                    date,
                    talentName,
                    breaks,
                    breakStartTime,
                    breakEndTime,
                },
                submitButtonLabel,
                cancelButtonLabel,
                addNewBreak,
                addSuccessMessage,
                updateSuccessMessage,
                add,
                edit,
                validationMessages: { talentNameRequired },
            },
        },
    } = en;

    const { isLoading: isUpdatingRecordLoading, mutate: updateRecord } =
        useUpdateTimeTrackingRecords();

    const { isLoading: isAddingRecordLoading, mutate: addRecord } =
        useAddTimeTrackingRecords();

    const {
        data: { isAdmin, talentId, isEmployee },
    } = useUserIdentity();

    const [talentFieldTouched, setTalentFieldTouched] = useState(false);

    const handelCloseModal = useCallback(() => {
        setTalentFieldTouched(false);
        closeModal();
    }, [closeModal]);

    const handleRecordOperation = useCallback(
        (operation, successMessage, timeTrackingRecordData) => {
            operation(timeTrackingRecordData, {
                onSuccess: () => {
                    toast(successMessage, {
                        type: 'success',
                    });

                    handelCloseModal();
                },
            });
        },
        [handelCloseModal]
    );

    const handleSubmitTimeTrackingRecord = useCallback(
        async (data: any) => {
            if (!isEmployee && !data.talentId) {
                setTalentFieldTouched(true);

                return;
            }

            const timeTrackingRecordData: AddOrUpdateTimeTrackingRecordRequest =
                {
                    talentId: isEmployee ? talentId : data.talentId,
                    date: data.date,
                    startTime: data.startTime,
                    endTime: data.endTime || null,
                    breaks: data?.breaks?.map(breakItem => ({
                        startTime: breakItem.startTime,
                        endTime: breakItem.endTime,
                    })),
                };

            const recordOperation = selectedTimeTrackingRecord
                ? updateRecord
                : addRecord;

            const successMessage = selectedTimeTrackingRecord
                ? updateSuccessMessage
                : addSuccessMessage;

            handleRecordOperation(
                recordOperation,
                successMessage,
                timeTrackingRecordData
            );
        },
        [
            isEmployee,
            talentId,
            selectedTimeTrackingRecord,
            updateRecord,
            addRecord,
            updateSuccessMessage,
            addSuccessMessage,
            handleRecordOperation,
        ]
    );

    const handleAddBreakClick = useCallback(
        (values, handleChange) => {
            const newBreak = { startTime: '10:00', endTime: '10:30' };

            handleChange({
                target: {
                    name: breaks.fieldName,
                    value: values.breaks
                        ? [...values.breaks, newBreak]
                        : [newBreak],
                },
            });
        },
        [breaks.fieldName]
    );

    const handleDeleteBreakClick = useCallback(
        (values, breakIndex, handleChange) => {
            const updatedBreaks = [...values.breaks];
            updatedBreaks.splice(breakIndex, 1);

            handleChange({
                target: {
                    name: breaks.fieldName,
                    value: updatedBreaks,
                },
            });
        },
        [breaks.fieldName]
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            closeModal={handelCloseModal}
            header={selectedTimeTrackingRecord ? edit : add}
            headerCustomStyle={{
                zIndex: '1500',
            }}
        >
            <Formik
                initialValues={getInitialValues(selectedTimeTrackingRecord)}
                onSubmit={handleSubmitTimeTrackingRecord}
                validationSchema={CreateTimeTrackingSchema}
            >
                {({ values, handleChange }) => (
                    <Form>
                        <MainContainer>
                            {isAdmin && (
                                <div style={{ marginBottom: '3rem' }}>
                                    <EmployeeFilter
                                        label={talentName.label}
                                        name={talentName.fieldName}
                                        selectedTimeTrackingRecord={
                                            selectedTimeTrackingRecord
                                        }
                                        onSelect={() => {}}
                                        onRemove={() =>
                                            setTalentFieldTouched(true)
                                        }
                                        isAddingRecordModal={!isEmployee}
                                        onUpdate={(id: string) => {
                                            handleChange({
                                                target: {
                                                    name: talentName.fieldName,
                                                    value: id,
                                                },
                                            });
                                        }}
                                        onBlur={() =>
                                            setTalentFieldTouched(true)
                                        }
                                    />

                                    {talentFieldTouched && !values.talentId && (
                                        <div
                                            style={{
                                                marginTop: '1.75rem',
                                                marginBottom: '-1.75rem',
                                            }}
                                        >
                                            <FieldErrorMessage>
                                                {talentNameRequired}
                                            </FieldErrorMessage>
                                        </div>
                                    )}
                                </div>
                            )}

                            <RecordDate
                                name={date.fieldName}
                                value={values.date}
                                label={date.label}
                                disabled={!!selectedTimeTrackingRecord}
                                minDate={contractStartDate}
                            />

                            <TimeWrapper key="record-start-time">
                                <RecordStartTime
                                    name={startTime.fieldName}
                                    label={startTime.label}
                                    id={`${values?.id}-record-start-time`}
                                    dataCy={'record-start-time-field'}
                                    fieldValue={values.startTime}
                                    onUpdate={(updatedStartTime: string) => {
                                        handleChange({
                                            target: {
                                                name: startTime.fieldName,
                                                value: updatedStartTime,
                                            },
                                        });
                                    }}
                                />

                                <RecordEndTime
                                    id={`${values?.id}-record-end-time`}
                                    name={endTime.fieldName}
                                    label={endTime.label}
                                    fieldValue={values.endTime}
                                    dataCy={'record-end-time-field'}
                                    onUpdate={(updatedEndTime: string) => {
                                        handleChange({
                                            target: {
                                                name: endTime.fieldName,
                                                value: updatedEndTime,
                                            },
                                        });
                                    }}
                                />
                            </TimeWrapper>

                            {values.breaks &&
                                values.breaks.length > 0 &&
                                values.breaks?.map(
                                    (
                                        breakItem: TimeTrackingBreakRecordResponseDTO,
                                        breakIndex: number
                                    ) => (
                                        <React.Fragment
                                            key={`break-${breakIndex}`}
                                        >
                                            <StyledBreakHeaderContainer>
                                                <StyledBreakTitle>
                                                    <p>{`Break  ${
                                                        breakIndex + 1
                                                    }`}</p>
                                                </StyledBreakTitle>

                                                <StyledRemoveIcon>
                                                    <IconWrapper
                                                        onClick={() =>
                                                            handleDeleteBreakClick(
                                                                values,
                                                                breakIndex,
                                                                handleChange
                                                            )
                                                        }
                                                    >
                                                        <DeleteForeverIcon
                                                            width={'1rem'}
                                                            height={'1rem'}
                                                        />
                                                    </IconWrapper>
                                                </StyledRemoveIcon>
                                            </StyledBreakHeaderContainer>

                                            <BreakWrapper
                                                key={`break-${breakIndex}`}
                                            >
                                                <RecordStartTime
                                                    name={
                                                        breakStartTime.fieldName
                                                    }
                                                    label={breakStartTime.label}
                                                    id={`${values?.id}${breakIndex}-break-start-time`}
                                                    fieldValue={
                                                        breakItem.startTime
                                                    }
                                                    dataCy={
                                                        'break-start-time-field'
                                                    }
                                                    onUpdate={(
                                                        updatedStartTime: string
                                                    ) => {
                                                        const updatedBreaks = [
                                                            ...values.breaks,
                                                        ];

                                                        updatedBreaks[
                                                            breakIndex
                                                        ].startTime =
                                                            updatedStartTime;

                                                        handleChange({
                                                            target: {
                                                                name: 'breaks',
                                                                value: updatedBreaks,
                                                            },
                                                        });
                                                    }}
                                                />

                                                <RecordEndTime
                                                    id={`${values?.id}${breakIndex}-break-end-time`}
                                                    name={
                                                        breakEndTime.fieldName
                                                    }
                                                    label={breakEndTime.label}
                                                    fieldValue={
                                                        breakItem.endTime
                                                    }
                                                    dataCy={
                                                        'break-end-time-field'
                                                    }
                                                    onUpdate={(
                                                        updatedEndTime: string
                                                    ) => {
                                                        const updatedBreaks = [
                                                            ...values.breaks,
                                                        ];

                                                        updatedBreaks[
                                                            breakIndex
                                                        ].endTime =
                                                            updatedEndTime;

                                                        handleChange({
                                                            target: {
                                                                name: breaks.fieldName,
                                                                value: updatedBreaks,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </BreakWrapper>
                                        </React.Fragment>
                                    )
                                )}

                            <StyledAddEntryWrapper>
                                <IconWrapper
                                    onClick={() =>
                                        handleAddBreakClick(
                                            values,
                                            handleChange
                                        )
                                    }
                                    data-testid="add-break-icon"
                                >
                                    <AddBreakIcon />

                                    <span style={{ marginLeft: '0.5rem ' }}>
                                        {addNewBreak}
                                    </span>
                                </IconWrapper>
                            </StyledAddEntryWrapper>

                            <ModalButtonsFooter
                                placement="space-between"
                                buttonsInfo={[
                                    {
                                        text: cancelButtonLabel,
                                        btnType: 'secondaryGrey',
                                        onClick: handelCloseModal,
                                        disabled:
                                            isAddingRecordLoading ||
                                            isUpdatingRecordLoading,
                                        dataCy: 'cancel-btn',
                                    },
                                    {
                                        text:
                                            isAddingRecordLoading ||
                                            isUpdatingRecordLoading ? (
                                                <Spinner size={30} />
                                            ) : (
                                                submitButtonLabel
                                            ),
                                        type: 'submit',
                                        dataCy: 'submit-btn',
                                        name: 'submit-edit-btn',
                                        disabled:
                                            isAddingRecordLoading ||
                                            isUpdatingRecordLoading,
                                    },
                                ]}
                                customStyle={ModalButtonsFooterCustomStyle}
                            />
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddRecordModal;
