import React, { useEffect, useState } from 'react';

import { useFlags } from '@workmotion/feature-flags-library';
import {
    ActionMenuItems,
    Checkbox,
    CountryFlag,
    DeleteForeverIcon,
    EditIcon,
    PreviewIcon,
    palette,
} from 'workmotion-design-system';

import {
    convertMinutesToHoursAndMinutes,
    getDayAndMonth,
} from 'app/shared/date-utils';
import TalentName from 'app/time-sheet/manage-view/timesheet-list/talent-name';
import { TimeTrackingRecordTypeMapper } from 'app/time-tracking/helpers';
import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import {
    ActionItemsCellContainer,
    TableCell,
    TableRow,
} from 'shared-components/table-components/table-components';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

import { StatusLabel } from './status-label';

const {
    employeeTimeTracking: {
        table: {
            actionItems: { edit, preview, remove },
        },
    },
} = en;

const TimeTrackingRecordItem: React.FC<{
    record: TimeTrackingRecordResponseDTO;
    setSelectedRecord?: (record: TimeTrackingRecordResponseDTO) => void;
    setModalOpen?: (isOpen: boolean) => void;
    setIsPreview?: (isPreview: boolean) => void;
    setIsDeleteModalOpen?: (isDelete: boolean) => void;
    selectedTimeTrackingRecordList?: TimeTrackingRecordResponseDTO[];
    handleRecordChecked?: (
        record: TimeTrackingRecordResponseDTO,
        isChecked: boolean
    ) => void;
    setSelectedTimeTrackingRecordList?: (
        selectedTimeTrackingRecordList: any
    ) => void;
}> = ({
    record,
    setModalOpen,
    setSelectedRecord,
    setIsPreview,
    setIsDeleteModalOpen,
    selectedTimeTrackingRecordList,
    setSelectedTimeTrackingRecordList,
}) => {
    const {
        id,
        talentId,
        talentInfo,
        date,
        recordType,
        startTime,
        endTime,
        breaksDurationMinutes,
        workingDurationMinutes,
        status,
        countryIsoCode,
    } = record;

    const {
        data: { isEmployee, isHrManager, isCompanyAdmin, isAdmin },
    } = useUserIdentity();

    const { talent423LttEnhancements } = useFlags();
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const handleEdit = () => {
        setModalOpen(true);

        setSelectedRecord(record);
    };

    const handlePreview = () => {
        setIsPreview(true);
        setSelectedRecord(record);
    };

    const handleDelete = () => {
        setIsDeleteModalOpen(true);
        setSelectedRecord(record);
    };

    const handleRecordCheck = () => {
        setIsSelected(!isSelected);

        if (isSelected) {
            setSelectedTimeTrackingRecordList(
                selectedTimeTrackingRecordList?.filter(
                    item => item.id !== record.id
                )
            );
        } else {
            setSelectedTimeTrackingRecordList((prev: any) => [...prev, record]);
        }
    };

    useEffect(() => {
        setIsSelected(
            selectedTimeTrackingRecordList.some(item => item.id === record.id)
        );
    }, [record.id, selectedTimeTrackingRecordList]);

    return (
        <TableRow isSelected={isSelected} data-is-clickable>
            {(isAdmin || isEmployee) && talent423LttEnhancements && (
                <TableCell key={`${record?.id + record?.date}-check-box`}>
                    {recordType === 'WORKING_DAY' && status === 'PENDING' && (
                        <Checkbox
                            id={record?.id + record?.date}
                            checkboxSize={12}
                            onClick={handleRecordCheck}
                            checked={isSelected}
                        />
                    )}
                </TableCell>
            )}

            {!isEmployee && (
                <TableCell key={`${id + date}-talent-name`}>
                    <TalentName
                        key={`${id + date}-name`}
                        talent={{
                            id: talentId,
                            firstName: talentInfo?.firstName,
                            lastName: talentInfo?.lastName,
                        }}
                    />
                </TableCell>
            )}

            {isAdmin && (
                <TableCell key={`${id + date}-country`}>
                    {countryIsoCode ? (
                        <CountryFlag
                            countryIsoCode={countryIsoCode}
                            customStyledFlagIcon={{
                                width: '1.5rem',
                                height: '1.5rem',
                                borderRadius: '50%',
                                marginRight: '0.4rem',
                            }}
                            labelStyles={{
                                fontSize: '0.875rem',
                                display: 'contents',
                            }}
                        />
                    ) : (
                        '-'
                    )}
                </TableCell>
            )}

            <TableCell key={`${id + date}-date`}>
                {date ? getDayAndMonth(date)?.toUpperCase() : '-'}
            </TableCell>

            {!isAdmin && (
                <TableCell key={`${id + date}-recordType`}>
                    {recordType
                        ? TimeTrackingRecordTypeMapper(recordType)
                        : '-'}
                </TableCell>
            )}

            <TableCell key={`${id + date}-startTime`}>
                {startTime ?? '-'}
            </TableCell>

            <TableCell key={`${id + date}-endTime`}>{endTime ?? '-'}</TableCell>

            <TableCell key={`${id + date}-breaksDuration`}>
                {breaksDurationMinutes
                    ? convertMinutesToHoursAndMinutes(
                          breaksDurationMinutes
                      ).toUpperCase()
                    : '-'}
            </TableCell>

            <TableCell key={`${id + date}-workingDuration`}>
                {workingDurationMinutes
                    ? convertMinutesToHoursAndMinutes(
                          workingDurationMinutes
                      ).toUpperCase()
                    : '-'}
            </TableCell>

            {talent423LttEnhancements && (
                <TableCell key={`${id + date}-status`}>
                    {status ? <StatusLabel status={status} /> : '-'}
                </TableCell>
            )}

            <TableCell>
                {record?.recordType === 'WORKING_DAY' && (
                    <ActionItemsCellContainer>
                        <ActionMenuItems
                            menuDataCy="action-dropdown"
                            menuItems={[
                                !isHrManager &&
                                    !isCompanyAdmin && {
                                        label: edit,
                                        icon: <EditIcon />,
                                        itemDataCy: 'time-tracking-edit-option',
                                        action: handleEdit,
                                    },

                                (isAdmin ||
                                    (talent423LttEnhancements &&
                                        isEmployee &&
                                        record.status === 'PENDING')) && {
                                    label: (
                                        <div
                                            style={{
                                                color: palette.semantic.red,
                                            }}
                                        >
                                            {remove}
                                        </div>
                                    ),
                                    icon: (
                                        <DeleteForeverIcon
                                            style={{
                                                color: palette.semantic.red,
                                            }}
                                        />
                                    ),
                                    itemDataCy: 'time-tracking-delete-option',
                                    action: handleDelete,
                                },
                                !isEmployee && {
                                    label: preview,
                                    icon: <PreviewIcon />,
                                    itemDataCy: 'time-tracking-preview-option',
                                    action: handlePreview,
                                },
                            ]}
                        />
                    </ActionItemsCellContainer>
                )}
            </TableCell>
        </TableRow>
    );
};

export default TimeTrackingRecordItem;
