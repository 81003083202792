import { useCallback } from 'react';

import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAccessToken } from 'authentication/use-access-token';
import { useUserIdentity } from 'authentication/use-user-identity';
import { handleRequestError } from 'helpers/handle-request-error';
import { en } from 'localization';
import {
    AddOrUpdateTimeTrackingRecordRequest,
    TimeTrackingRecordExportRequest,
    TimeTrackingRecordScheduleRequest,
    TimeTrackingRecordSubmitRequest,
} from 'types/timeTracking';

import {
    addTalentTimeTrackingSchedule,
    addTimeTrackingRecords,
    deleteTimeTrackingRecord,
    exportTimeTrackingRecordsV2,
    generateTimeTrackingReport,
    submitTimeTrackingStatus,
    submitTimeTrackingStatusV2,
    updateTimeTrackingRecords,
} from './time-tracking-client';

const {
    employeeTimeTracking: {
        createTimeTrackingModal: { somethingWentWrong },
    },
} = en;

export const useUpdateTimeTrackingRecords = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (data: AddOrUpdateTimeTrackingRecordRequest) =>
            updateTimeTrackingRecords(data, accessToken),
        [accessToken]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useAddTimeTrackingRecords = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (data: AddOrUpdateTimeTrackingRecordRequest) =>
            addTimeTrackingRecords(data, accessToken),
        [accessToken]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useExportTimeTrackingReport = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const mutationFn = useCallback(
        async ({
            yearMonth,
            countryIsoCode,
            companyId,
            talentId,
        }: {
            yearMonth?: string;
            countryIsoCode?: string;
            companyId: string;
            talentId?: string;
        }) =>
            generateTimeTrackingReport(
                { yearMonth, countryIsoCode, companyId, talentId },
                accessToken
            ),
        [accessToken]
    );

    return useMutation({
        mutationFn,
    });
};

export const useDeleteTimeTrackingRecord = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (recordId: string) =>
            deleteTimeTrackingRecord(recordId, accessToken),
        [accessToken]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useAddTimeTrackingTalentSchedule = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const {
        data: { talentId },
    } = useUserIdentity();

    const mutationFn = useCallback(
        async (data: TimeTrackingRecordScheduleRequest) =>
            addTalentTimeTrackingSchedule(talentId, data, accessToken),
        [accessToken, talentId]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingSchedule'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useSubmitTimeTrackingRecordsStatus = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const {
        data: { talentId },
    } = useUserIdentity();

    const mutationFn = useCallback(
        async (data: string[]) =>
            submitTimeTrackingStatus(talentId, data, accessToken),
        [accessToken, talentId]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useSubmitTimeTrackingRecordsStatusV2 = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (data: TimeTrackingRecordSubmitRequest) =>
            submitTimeTrackingStatusV2(data, accessToken),
        [accessToken]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};

export const useExportTimeTrackingRecordsV2 = () => {
    const {
        accessToken: { accessToken },
    } = useAccessToken();

    const queryClient = useQueryClient();

    const mutationFn = useCallback(
        async (data: TimeTrackingRecordExportRequest) =>
            exportTimeTrackingRecordsV2(data, accessToken),
        [accessToken]
    );

    return useMutation({
        mutationFn,
        onError: e => {
            handleRequestError(e, somethingWentWrong);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries(['TimeTrackingRecords'])
                .catch(error => {
                    captureException(error, {
                        tags: { request: 'invalidateQueries' },
                    });
                });
        },
    });
};
