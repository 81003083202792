import React, { useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dropdown } from 'workmotion-design-system';

import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import { TimesheetListingResponseDTO } from 'types/timesheet';

const {
    employeeTimesheet: {
        table: {
            actionItems: { edit, download },
        },
    },
} = en;

export const ActionItems: React.FC<{
    timesheet: TimesheetListingResponseDTO;
    setSelectedTimesheet?: (timesheet: TimesheetListingResponseDTO) => void;
    setModalOpen?: (isOpen: boolean) => void;
}> = ({ timesheet, setModalOpen, setSelectedTimesheet }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const {
        data: { isEmployee },
    } = useUserIdentity();

    const onDropdownClick = () => {
        setShowDropdown(prevState => !prevState);
    };

    const handleEdit = () => {
        setModalOpen(true);

        setSelectedTimesheet(timesheet);
    };

    const handleDownload = () => {
        window.open(timesheet.downloadUrl, '_blank');
    };

    const dropdownItems = [
        {
            title: download,
            onClick: handleDownload,
            icon: (
                <DownloadIcon
                    style={{
                        fontSize: '0.75rem',
                        marginInlineEnd: '0.75rem',
                    }}
                    name="download-action-item"
                />
            ),
        },
    ];

    if (isEmployee)
        dropdownItems.push({
            title: edit,
            onClick: handleEdit,
            icon: (
                <EditIcon
                    style={{
                        fontSize: '0.75rem',
                        marginInlineEnd: '0.75rem',
                    }}
                    name="edit-action-item"
                />
            ),
        });

    return (
        <Dropdown
            showDropdown={showDropdown}
            onDropdownControllerClick={onDropdownClick}
            dropdownController={<MoreVertIcon />}
            dropdownItems={dropdownItems}
        />
    );
};
