/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import {
    InfoTip,
    Modal,
    ModalButtonsFooter,
    Spinner,
} from 'workmotion-design-system';

import { en } from 'localization';
import { useAddTimeTrackingTalentSchedule } from 'networking/timesheet-api/time-tracking-mutations';
import {
    TimeRackingRecordScheduleResponse,
    TimeTrackingRecordScheduleRequest,
} from 'types/timeTracking';

import { ScheduleType, scheduleArray } from './constant';
import { Day } from './day-section';
import { DaysSchedule } from './day-section/days-box';
import {
    MainContainer,
    ModalButtonsFooterCustomStyle,
} from './styled-components';
import {
    formatScheduleArray,
    handleAddBreak,
    handleAddDay,
    handleBreakInputChange,
    handleCopyToAll,
    handleDayInputChange,
    handleRemoveBreak,
    handleRemoveDay,
    mapScheduleRequest,
    mapScheduleResponse,
} from './utils';

type ModalProps = {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedSchedule: TimeRackingRecordScheduleResponse | null;
};

const DefineScheduleModal: React.FC<ModalProps> = ({
    modalIsOpen,
    closeModal,
    selectedSchedule,
}) => {
    const {
        employeeTimeTracking: {
            defineTimeTrackingScheduleModal: {
                title,
                infoTip: { text },
                submitButtonLabel,
                cancelButtonLabel,
                updateSuccessMessage,
                addSuccessMessage,
            },
        },
    } = en;

    const { isLoading: isAddingScheduleLoading, mutate: addScheulde } =
        useAddTimeTrackingTalentSchedule();

    const [schedule, setSchedule] = useState<ScheduleType[]>([]);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const onAddDay = useCallback(
        (day: string) => handleAddDay(schedule, setSchedule, day),
        [schedule, setSchedule]
    );

    const onRemoveDay = useCallback(
        (day: string) => {
            handleRemoveDay(schedule, setSchedule, day);
        },
        [schedule, setSchedule]
    );

    const onCopyToAll = useCallback(
        (currentDay: string) => {
            handleCopyToAll(schedule, setSchedule, currentDay);
        },
        [schedule, setSchedule]
    );

    const onAddBreak = useCallback(
        (day: string) => {
            handleAddBreak(schedule, setSchedule, day);
        },
        [schedule, setSchedule]
    );

    const onRemoveBreak = useCallback(
        (day: string, breakIndex: number) => {
            handleRemoveBreak(schedule, setSchedule, day, breakIndex);
        },
        [schedule, setSchedule]
    );

    const onDayInputChange = useCallback(
        (day: string, field: string, value: string) => {
            handleDayInputChange(schedule, setSchedule, day, field, value);
        },
        [schedule, setSchedule]
    );

    const onBreakInputChange = useCallback(
        (
            day: string,
            breakField: string,
            value: string,
            breakIndex: number
        ) => {
            handleBreakInputChange(
                schedule,
                setSchedule,
                day,
                breakField,
                value,
                breakIndex
            );
        },
        [schedule, setSchedule]
    );

    const filteredSchedule = schedule.filter(
        day => day[Object.keys(day)[0]].isSelected
    );

    const daysTitles = useMemo(() => [], [filteredSchedule]);

    const handleSubmitSchedule = () => {
        const updatedSchedule = mapScheduleRequest(filteredSchedule);

        addScheulde(
            updatedSchedule as unknown as TimeTrackingRecordScheduleRequest,
            {
                onSuccess: () => {
                    toast(
                        selectedSchedule
                            ? updateSuccessMessage
                            : addSuccessMessage,
                        {
                            type: 'success',
                        }
                    );

                    closeModal();
                },
            }
        );
    };

    const handleCloseModal = () => {
        setSchedule([]);
        closeModal();
    };

    useEffect(() => {
        const remainingDays = schedule.filter(
            selectedDay => selectedDay[Object.keys(selectedDay)[0]].isSelected
        );

        setIsDisabled(remainingDays.length === 0);
    }, [schedule, selectedSchedule]);

    useEffect(() => {
        if (selectedSchedule) {
            const mappedScheduleArray = mapScheduleResponse(selectedSchedule);
            setSchedule(mappedScheduleArray as unknown as ScheduleType[]);
        } else {
            const formattedScheduleArray = formatScheduleArray(scheduleArray);

            setSchedule(formattedScheduleArray);
        }
    }, [modalIsOpen, selectedSchedule]);

    const scheduleMap = useMemo(
        () =>
            filteredSchedule.map((day, index) => {
                const dayTitle = Object.keys(day)[0];

                daysTitles.push(dayTitle);

                return (
                    <Day
                        key={index}
                        day={day}
                        title={dayTitle}
                        hasCopyToAll={index === 0}
                        copyToAll={() => onCopyToAll(dayTitle)}
                        addBreak={() => onAddBreak(dayTitle)}
                        removeBreak={(breakIndex: number) =>
                            onRemoveBreak(dayTitle, breakIndex)
                        }
                        onDayChange={(field: string, value: string) => {
                            onDayInputChange(dayTitle, field, value);
                        }}
                        onBreakChange={(
                            field: string,
                            value: string,
                            breakIndex: number
                        ) => {
                            onBreakInputChange(
                                dayTitle,
                                field,
                                value,
                                breakIndex
                            );
                        }}
                        setIsDisabled={setIsDisabled}
                    />
                );
            }),
        [
            filteredSchedule,
            daysTitles,
            onCopyToAll,
            onAddBreak,
            onRemoveBreak,
            onDayInputChange,
            onBreakInputChange,
            setIsDisabled,
        ]
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            closeModal={handleCloseModal}
            header={selectedSchedule ? 'Edit Schedule' : title}
            headerCustomStyle={{
                zIndex: '1500',
            }}
        >
            <MainContainer>
                <InfoTip tipType={'info'} text={text} />

                <DaysSchedule
                    addDay={(daytTitle: string) => onAddDay(daytTitle)}
                    removeDay={(daytTitle: string) => onRemoveDay(daytTitle)}
                    days={daysTitles}
                />

                {scheduleMap}

                <ModalButtonsFooter
                    placement="space-between"
                    buttonsInfo={[
                        {
                            text: cancelButtonLabel,
                            btnType: 'secondaryGrey',
                            onClick: handleCloseModal,
                            dataCy: 'cancel-btn',
                            disabled: isAddingScheduleLoading,
                        },
                        {
                            text: isAddingScheduleLoading ? (
                                <Spinner size={30} />
                            ) : (
                                submitButtonLabel
                            ),
                            onClick: handleSubmitSchedule,
                            type: 'submit',
                            dataCy: 'submit-btn',
                            name: 'submit-edit-btn',
                            disabled: isDisabled || isAddingScheduleLoading,
                        },
                    ]}
                    customStyle={ModalButtonsFooterCustomStyle}
                />
            </MainContainer>
        </Modal>
    );
};

export default DefineScheduleModal;
