import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Button, Spinner } from 'workmotion-design-system';

export const DownloadBtn: React.FC<{
    download: () => void;
    title?: string;
    isLoading: boolean;
    dataCy?: string;
}> = ({ download, isLoading = false, title = 'DOWNLOAD', dataCy }) => (
    <Button
        onClick={download}
        btnType="subtle"
        data-cy={dataCy}
        data-testid={dataCy}
        style={{
            paddingLeft: '0.5rem',
            paddingRight: '0.1rem',
        }}
        prefixIcon={() =>
            isLoading ? (
                <Spinner size={16} data-testid="loading-state" />
            ) : (
                <DownloadIcon />
            )
        }
    >
        {title}
    </Button>
);
