import React, { useCallback } from 'react';

import * as Sentry from '@sentry/react';
import { addBreadcrumb } from '@sentry/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useTimesheetFileAction } from 'networking/timesheet-api/timesheet-mutations';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import {
    submitTimesheetValidation,
    getUploadTimesheetInitialValues,
} from './formik-values-setup';
import {
    MainContainer,
    MainText,
    SubText,
    ModalButtonsFooterCustomStyle,
} from './styled-components';
import { TemplateHint } from './template-hint';
import { TimesheetsUploader } from './timesheet-uploader';
import { useUserIdentity } from '../../../../authentication/use-user-identity';

const {
    employeeTimesheet: {
        uploadModal: {
            modalTitle,
            mainText,
            subText,
            cancelBtn,
            saveBtn,
            successMessage,
            modalFields: { month, timesheetFile },
        },
    },
} = en;

type ModalProps = {
    modalIsOpen: boolean;
    closeModal: () => void;
    selectedTimesheet: TimesheetListingResponseDTO;
    timesheetMonth?: string;
};

export const CurrentMonthUploadModal: React.FC<ModalProps> = ({
    modalIsOpen,
    closeModal,
    selectedTimesheet,
    timesheetMonth,
}) => {
    const { isLoading, mutate } = useTimesheetFileAction(!!selectedTimesheet);

    const {
        data: { talentId },
    } = useUserIdentity();

    const handleSubmitTimesheet = useCallback(
        (timesheetData: any) => {
            Sentry.addBreadcrumb({
                type: 'info',
                message: `edit timesheet for ${timesheetData[month.fieldName]}`,
            });

            const timesheetPlayload = {
                yearMonth: timesheetData[month.fieldName],
                timesheetFile: timesheetData[timesheetFile.fieldName][0],
                timesheetId: selectedTimesheet ? selectedTimesheet.id : null,
            };

            mutate(timesheetPlayload, {
                onSuccess: () => {
                    toast(successMessage, {
                        type: 'success',
                    });

                    addBreadcrumb({
                        message: `success  - timesheet submitted`,
                        level: 'log',
                    });

                    closeModal();
                },
            });
        },
        [closeModal, mutate, selectedTimesheet]
    );

    const handleCancelBtn = useCallback(() => {
        closeModal();
    }, [closeModal]);

    return (
        <Modal
            {...{
                isOpen: modalIsOpen,
                closeModal: handleCancelBtn,
                header: modalTitle,
            }}
        >
            <Formik
                initialValues={getUploadTimesheetInitialValues(timesheetMonth)} //here should be a date of current month or selected timesheet
                onSubmit={handleSubmitTimesheet}
                validationSchema={submitTimesheetValidation}
            >
                {() => (
                    <Form>
                        <MainContainer>
                            <MainText>{mainText}</MainText>

                            <SubText>{subText}</SubText>

                            <TimesheetsUploader />

                            <TemplateHint
                                talentId={talentId}
                                yearMonth={timesheetMonth}
                            />

                            <ModalButtonsFooter
                                placement="space-between"
                                buttonsInfo={[
                                    {
                                        text: cancelBtn,
                                        btnType: 'secondaryGrey',
                                        onClick: handleCancelBtn,
                                        disabled: isLoading,
                                        dataCy: 'cancel-btn',
                                    },
                                    {
                                        text: isLoading ? (
                                            <Spinner size={30} />
                                        ) : (
                                            saveBtn
                                        ),

                                        type: 'submit',
                                        dataCy: 'submit-btn',
                                        name: 'submit-edit-btn',
                                    },
                                ]}
                                customStyle={ModalButtonsFooterCustomStyle}
                            />
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
