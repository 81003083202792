import React, { useCallback } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { MobileTable, palette, Button } from 'workmotion-design-system';

import { formatUTCDate } from 'app/shared/date-utils';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { Link, LinkName, mobileActionsContainer } from './styled-components';
type MobileListProps = {
    setSelectedTimesheet: (timesheet: TimesheetListingResponseDTO) => void;
    setModalOpen: (isOpen: boolean) => void;
    content: TimesheetListingResponseDTO[];
};

export const MobileList: React.FC<MobileListProps> = ({
    setSelectedTimesheet,
    setModalOpen,
    content,
}) => {
    const handleEdit = useCallback(
        id => {
            setSelectedTimesheet(
                content.find(timesheet => timesheet.id === id)
            );

            setModalOpen(true);
        },
        [setSelectedTimesheet, setModalOpen, content]
    );

    const handleDownload = (downloadUrl: string) => {
        window.open(downloadUrl, '_blank');
    };

    const items = content.map(
        ({ id, yearMonth, createdAt, downloadUrl, fileName }) => ({
            id,
            title: <>{formatUTCDate(yearMonth, 'MONTH').toUpperCase()}</>,
            avatar: (
                <DateRangeIcon
                    style={{
                        color: palette.primary.genericViridian,
                        width: 16,
                    }}
                />
            ),
            details: [
                {
                    id,
                    label: 'Submitted date',
                    item: (
                        <>
                            {createdAt
                                ? formatUTCDate(createdAt, 'DATE').toUpperCase()
                                : '-'}
                        </>
                    ),
                },
                {
                    label: 'TimeSheet',
                    item: (
                        <>
                            <Link
                                href={downloadUrl}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <DescriptionIcon
                                    style={{
                                        color: palette.greyscale.UCLABlue,
                                        width: 14,
                                    }}
                                />

                                <LinkName>{fileName}</LinkName>
                            </Link>
                        </>
                    ),
                },
                {
                    label: 'Action',
                    item: (
                        <Box sx={mobileActionsContainer} component="li">
                            <Button
                                btnType="subtle"
                                size="icon"
                                onClick={() => handleEdit(id)}
                                data-testid="edit-btn"
                            >
                                <EditIcon
                                    style={{
                                        color: palette.greyscale.UCLABlue,
                                        fontSize: '1.3rem',
                                    }}
                                />
                            </Button>

                            <Button
                                btnType="subtle"
                                size="icon"
                                onClick={() => handleDownload(downloadUrl)}
                                data-testid="download-btn"
                            >
                                <DownloadIcon
                                    style={{
                                        color: palette.greyscale.UCLABlue,
                                        fontSize: '1.3rem',
                                    }}
                                />
                            </Button>
                        </Box>
                    ),
                },
            ],
        })
    );

    return <MobileTable items={items} header="Timesheet" />;
};
