import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { useFlags } from '@workmotion/feature-flags-library';
import { Checkbox, Spinner } from 'workmotion-design-system';

import { useUserIdentity } from 'authentication/use-user-identity';
import { en } from 'localization';
import {
    HeaderCell,
    TableHeadRow,
    Table,
} from 'shared-components/table-components/table-components';
import { TimeTrackingRecordResponseDTO } from 'types/timeTracking';

import { spinnerContainer } from './styled-components';
import TimeTrackingRecordItem from './time-tracking-record-item';

type TimeTrackingListProps = {
    isLoading: boolean;
    timeTrackingRecords: TimeTrackingRecordResponseDTO[];
    setSelectedRecord: (record: TimeTrackingRecordResponseDTO) => void;
    setModalOpen: (isOpen: boolean) => void;
    setIsPreview: (isPreview: boolean) => void;
    setIsDeleteModalOpen: (isPreview: boolean) => void;
    selectedTimeTrackingRecordList?: TimeTrackingRecordResponseDTO[];
    setSelectedTimeTrackingRecordList?: (
        selectedTimeTrackingRecordList: TimeTrackingRecordResponseDTO[]
    ) => void;
};

export const TimeTrackingList: React.FC<TimeTrackingListProps> = ({
    isLoading,
    timeTrackingRecords,
    setSelectedRecord,
    setIsDeleteModalOpen,
    setIsPreview,
    setModalOpen,
    selectedTimeTrackingRecordList,
    setSelectedTimeTrackingRecordList,
}) => {
    const {
        employeeTimeTracking: {
            table: {
                columns: {
                    nameCell,
                    countryCell,
                    dateCell,
                    type,
                    clockIn,
                    clockOut,
                    breaks,
                    workingDuration,
                    recordStatus,
                },
            },
        },
    } = en;

    const { talent423LttEnhancements } = useFlags();

    const {
        data: { isEmployee, isAdmin },
    } = useUserIdentity();

    const pendingTimeTrackingRecords = timeTrackingRecords?.filter(
        recored => recored.status === 'PENDING'
    );

    const handleSelectAllCheckbox = useCallback(
        (event): void => {
            if (event.target.checked) {
                setSelectedTimeTrackingRecordList(pendingTimeTrackingRecords);
            } else {
                setSelectedTimeTrackingRecordList([]);
            }
        },
        [pendingTimeTrackingRecords, setSelectedTimeTrackingRecordList]
    );

    const shouldCheckSelectAllCheckbox = useCallback(
        (): boolean =>
            pendingTimeTrackingRecords.length > 0 &&
            selectedTimeTrackingRecordList.length ===
                pendingTimeTrackingRecords?.length,
        [
            selectedTimeTrackingRecordList.length,
            pendingTimeTrackingRecords?.length,
        ]
    );

    if (isLoading) {
        return (
            <Box sx={spinnerContainer} data-testid="time-tracking-loading">
                <Spinner />
            </Box>
        );
    }

    if (timeTrackingRecords?.length) {
        return (
            <>
                <Table
                    data-cy="time-tracking-table-component"
                    data-testid="time-tracking-table-component"
                >
                    <thead>
                        <TableHeadRow>
                            {talent423LttEnhancements &&
                                (isAdmin || isEmployee) && (
                                    <HeaderCell>
                                        <Checkbox
                                            id="time-tracking-select-all-checkbox"
                                            checkboxSize={12}
                                            onClick={handleSelectAllCheckbox}
                                            checked={shouldCheckSelectAllCheckbox()}
                                        />
                                    </HeaderCell>
                                )}

                            {!isEmployee && <HeaderCell>{nameCell}</HeaderCell>}

                            {isAdmin && talent423LttEnhancements && (
                                <HeaderCell>{countryCell}</HeaderCell>
                            )}

                            <HeaderCell>{dateCell}</HeaderCell>

                            {!isAdmin && <HeaderCell>{type}</HeaderCell>}

                            <HeaderCell>{clockIn}</HeaderCell>

                            <HeaderCell>{clockOut}</HeaderCell>

                            <HeaderCell>{breaks}</HeaderCell>

                            <HeaderCell>{workingDuration}</HeaderCell>

                            {talent423LttEnhancements && (
                                <HeaderCell>{recordStatus}</HeaderCell>
                            )}

                            <HeaderCell style={{ borderRadius: 0 }} />
                        </TableHeadRow>
                    </thead>

                    <tbody>
                        {timeTrackingRecords?.map(record => (
                            <TimeTrackingRecordItem
                                key={
                                    record?.id + record?.talentId + record?.date
                                }
                                record={record}
                                setSelectedRecord={setSelectedRecord}
                                setModalOpen={setModalOpen}
                                setIsDeleteModalOpen={setIsDeleteModalOpen}
                                setIsPreview={setIsPreview}
                                selectedTimeTrackingRecordList={
                                    selectedTimeTrackingRecordList
                                }
                                setSelectedTimeTrackingRecordList={
                                    setSelectedTimeTrackingRecordList
                                }
                            />
                        ))}
                    </tbody>
                </Table>
            </>
        );
    }
};
