import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import {
    CountryFlag,
    palette,
    Table as TableComponent,
} from 'workmotion-design-system';

import { formatUTCDate } from 'app/shared/date-utils';
import { ActionItems } from 'app/time-sheet/action-item';
import { ActionItemsCellContainer } from 'app/time-sheet/employee-view/timesheet-list/styled-components';
import { en } from 'localization';
import { TimesheetListingResponseDTO } from 'types/timesheet';

import { Link, LinkName, TableCell } from './styled-components';
import TalentName from './talent-name';

export const Table: React.FC<{
    timeSheetData: TimesheetListingResponseDTO[];
}> = ({ timeSheetData }) => {
    const {
        managedTimeSheetList: {
            table: { columns },
        },
    } = en;

    const tableRows = timeSheetData.map(
        ({
            id,
            yearMonth: monthDate,
            createdAt,
            downloadUrl,
            fileName,
            talent,
            countryIsoCode,
        }) => ({
            id,
            cells: [
                <TalentName key={id} talent={talent} />,
                <CountryFlag
                    countryIsoCode={countryIsoCode}
                    key={id}
                    customStyledFlagIcon={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '50%',
                    }}
                />,
                <TableCell key={id}>
                    {monthDate
                        ? formatUTCDate(monthDate, 'MONTH').toUpperCase()
                        : '-'}
                </TableCell>,
                <TableCell key={id}>
                    {createdAt
                        ? formatUTCDate(createdAt, 'DATE').toUpperCase()
                        : '-'}
                </TableCell>,
                <Link
                    href={downloadUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={id}
                >
                    <DescriptionIcon
                        style={{
                            color: palette.greyscale.UCLABlue,
                            width: 14,
                        }}
                    />

                    <LinkName>{fileName}</LinkName>
                </Link>,
                <ActionItemsCellContainer key={id}>
                    <ActionItems
                        timesheet={{
                            id,
                            yearMonth: monthDate,
                            createdAt,
                            downloadUrl,
                            fileName,
                            talent,
                            countryIsoCode,
                        }}
                    />
                </ActionItemsCellContainer>,
            ],
        })
    );

    return (
        <TableComponent
            backgroundColor={`${palette.secondary.white}`}
            columns={columns.map(column => ({ cell: column }))}
            rows={tableRows}
            withHover
        />
    );
};
