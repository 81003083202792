import type { FormikValues } from 'formik';
import {
    string as yupString,
    array as yupArray,
    object as yupObject,
} from 'yup';

import { en } from 'localization';

const {
    employeeTimesheet: {
        uploadModal: {
            validationMessages: { uploadFileError, selectedMonthRequired },
            modalFields: { month, timesheetFile },
        },
    },
} = en;

export interface CreateTimesheetSchema extends FormikValues {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [month.fieldName]: string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [timesheetFile.fieldName]: Array<any>;
}

export const getUploadTimesheetInitialValues = (
    selectedMonth?: string
): CreateTimesheetSchema => ({
    [month.fieldName]: selectedMonth || '',
    [timesheetFile.fieldName]: [],
});

export const submitTimesheetValidation = yupObject().shape({
    [month.fieldName]: yupString().required(selectedMonthRequired),
    [timesheetFile.fieldName]: yupArray().min(1, uploadFileError),
});
