import React, { useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import MUISpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { palette } from 'workmotion-design-system';

const speedDialStyles = {
    position: 'fixed',
    bottom: 16,
    right: 16,
    '& .MuiSpeedDial-fab': {
        backgroundColor: palette.tertiary.aquaHaze,
        borderRadius: '12px',
        '&:active': {
            backgroundColor: palette.tertiary.aquaHaze,
        },
        '&:hover': {
            backgroundColor: palette.tertiary.aquaHaze,
        },
    },
    zIndex: theme => (theme as any).zIndex.drawer + 1,
};

const actionIconStyles = {
    borderRadius: '12px',
    '& .MuiSpeedDialAction-fab': {
        borderRadius: '12px',
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: palette.secondary.white,
        fontSize: '0.75rem',
        minWidth: '260px',
        textAlign: 'right',
        textTransform: 'uppercase',
    },
};

const iconStyles = {
    backgroundColor: palette.tertiary.aquaHaze,
    color: palette.primary.genericViridian,
};

interface ActionItem {
    icon?: any;
    name: string;
    callback?: () => void;
}

interface SpeedDialProps {
    actionItems?: ActionItem[];
}

export const SpeedDial: React.FC<SpeedDialProps> = ({ actionItems }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Backdrop
                open={open}
                sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            />

            <MUISpeedDial
                ariaLabel="Speed dial"
                sx={speedDialStyles}
                icon={<SpeedDialIcon sx={iconStyles} />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
            >
                {actionItems &&
                    actionItems.map(action => (
                        <SpeedDialAction
                            key={action?.name}
                            icon={action?.icon}
                            tooltipTitle={action?.name}
                            tooltipOpen
                            onClick={() => {
                                setOpen(false);

                                action?.callback();
                            }}
                            sx={actionIconStyles}
                        />
                    ))}
            </MUISpeedDial>
        </>
    );
};
