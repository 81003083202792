import { MenuButton } from '@szhsin/react-menu';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ActionsContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem',
    background: palette.secondary.white,
    borderRadius: '0.5rem 0.5rem 0 0',
    zIndex: 1,
    padding: '1rem',
    justifyContent: 'flex-end',
});

export const TimeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
`;

export const BreakWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledAction = styled.span<{ color: string }>`
    color: ${({ color }) => color};
    font-weight: 400;
    line-height: 1.375rem;
    font-size: 0.875rem;
    font-family: 'Work Sans';
`;

export const StyledStatusLabel = styled(MenuButton)({
    width: '6.75rem',
    height: '1.5rem',
    borderRadius: '0.75rem',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '0.88rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    background: 'rgba(0, 130, 110, 0.1)',
    color: palette.primary.midnightGreen,
    '&[data-status="pending"]': {
        background: palette.secondary.ivoryWillow,
        color: palette.semantic.lemonCurry,
    },
    '&[data-status="submitted"]': {
        background: palette.secondary.softMint,
        color: palette.semantic.persianGreen,
    },
});
