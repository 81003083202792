import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

import { RequestError } from 'types';

const handleRequestError = (
    err: RequestError,
    backupErrorMessage: string
): void => {
    Sentry.captureException(err);

    const apiMessage =
        err.response?.data?.message || err.response?.data?.detail;

    const message = apiMessage || backupErrorMessage;

    toast(message, {
        type: 'error',
    });
};

export { handleRequestError };
