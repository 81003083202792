import { QueryParameters } from 'types';
import { TimesheetGenerationRequest } from 'types/timesheet';

import { createTimesheetClient } from './open-api-client';

export async function uploadTimesheet(
    talentId: string,
    yearMonth: string,
    file: File,
    token: string
) {
    const client = await createTimesheetClient(token);

    const formData = new FormData();

    formData.append(
        'yearMonth',
        new Blob([JSON.stringify(yearMonth)], { type: 'application/json' })
    );

    formData.append(
        'talentId',
        new Blob([JSON.stringify(talentId)], { type: 'application/json' })
    );

    formData.append('timesheetFile', file);

    const response = await client.uploadTimesheet({}, formData as never);

    return response.data;
}

export async function generateAndUploadTimesheet(
    playload: TimesheetGenerationRequest,
    token: string
) {
    const client = await createTimesheetClient(token);

    const response = await client.generateAndUploadTimesheet({}, playload);

    return response.data;
}

export async function editTimesheet(
    timesheetId: string,
    timesheetFile: File,
    token: string
) {
    const client = await createTimesheetClient(token);

    const formData = new FormData();

    formData.append('timesheetFile', timesheetFile);

    const response = await client.updateTimesheet(
        { timesheetId },
        formData as never
    );

    return response.data;
}

export async function generateTemplate(
    playload: TimesheetGenerationRequest,
    token: string
) {
    const client = await createTimesheetClient(token);

    const response = await client.generateTimesheetDraft({}, { ...playload });

    return response.data;
}

export async function getTimeSheets(params: QueryParameters, token: string) {
    const client = await createTimesheetClient(token);

    const response = await client.getTimesheets(params as any);

    return response.data;
}

//seems like this duplicate  generateTemplate
export async function generateTimeSheetTemplates(
    talentId: string,
    yearMonth: string,
    token: string
) {
    const client = await createTimesheetClient(token);

    const response = await client.generateTimesheetDraft(
        {},
        { talentId, yearMonth }
    );

    return response.data;
}

export async function generateTimesheetReport(
    params: QueryParameters,
    token: string
) {
    const client = await createTimesheetClient(token);

    const response = await client.generateTimesheetZipReport({}, params);

    return response.data;
}
