import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Drawer, palette } from 'workmotion-design-system';

export const StyledSideMenuContainer = styled.div({
    padding: '0 2rem 2rem 2rem',
});

interface InfoSideMenuProps {
    sideMenuTitle: string;
    children: React.ReactNode;
}

const InfoSideMenu: React.FC<InfoSideMenuProps> = ({
    sideMenuTitle,
    children,
}) => {
    const [isMenuOpened, setMenuOpened] = useState(false);

    const toggleDrawer = (open: boolean) => () => {
        setMenuOpened(open);
    };

    return (
        <>
            <Link
                style={{
                    color: palette.primary.genericViridian,
                    fontSize: '14px',
                    lineHeight: '16.42px',
                    textDecoration: 'none',
                }}
                to={''}
                onClick={toggleDrawer(true)}
                data-testid="learn-more-icon"
            >
                {'Learn more'}
            </Link>

            <Drawer
                anchor="right"
                open={isMenuOpened}
                onClose={toggleDrawer(false)}
                title={sideMenuTitle}
                attrs={{
                    title: {
                        style: { color: palette.greyscale.UCLABlue },
                    },
                }}
                data-testid="time-tracking-side-drawer"
            >
                <StyledSideMenuContainer>{children}</StyledSideMenuContainer>
            </Drawer>
        </>
    );
};

export { InfoSideMenu };
