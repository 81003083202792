import { palette } from 'workmotion-design-system';

export const DatePickerStyle = {
    backgroundColor: palette.greyscale.ghostWhite,
    '& .MuiPickersCalendarHeader-label': {
        fontSize: '0.875rem',
        borderRadius: '8px',
        height: '40px',
    },
    '& .MuiPickersDay-root, & .PrivatePickersMonth-root, & .PrivatePickersYear-yearButton':
        {
            backgroundColor: palette.greyscale.ghostWhite,
            borderRadius: '4px',
            padding: 0,
            fontSize: '0.75rem',
            '&.Mui-selected': {
                backgroundColor: palette.primary.genericViridian,

                '&:focus': {
                    color: palette.secondary.white,
                    backgroundColor: palette.primary.genericViridian,
                },
                '&:hover': {
                    borderColor: palette.tertiary.aquaHaze,
                    backgroundColor: palette.tertiary.aquaHaze,
                },
            },
            '&:hover': {
                borderColor: palette.tertiary.aquaHaze,
                backgroundColor: palette.tertiary.aquaHaze,
            },
            '&:focus': {
                color: palette.secondary.white,
                backgroundColor: palette.primary.genericViridian,
            },
        },
};

export const DeleteIconStyles = {
    color: palette.semantic.red,
    fontSize: '1rem',
    cursor: 'pointer',
};

export const ErrorMessageStyles = {
    color: palette.semantic.red,
    fontSize: '0.75rem',
    fontFamily: 'Work Sans, sans serif',
    fontWeight: 400,
    marginTop: '4px',
};

export const GapBetweenDatePickers = {
    color: palette.greyscale.persianBlue,
    margin: '0 8px',
};
