import React, { useCallback, useEffect, useState } from 'react';

import { en } from 'localization';

import { FieldErrorMessage } from './../label';
import { StyledFieldContainer, StyledLabel } from './../modal-field-components';
import { TimeWrapper } from '../../styled-components';
import { TimePicker } from '../../time-picker';
import { ScheduleType } from '../constant';
import { RecordEndTime } from '../record-end-time';
import { RecordStartTime } from '../record-start-time';

interface TimeSectionProps {
    day?: ScheduleType;
    title?: string;
    startTime?: {
        label: string;
        fieldName: string;
    };
    endTime?: {
        label: string;
        fieldName: string;
    };
    onDayChange?: (field: string, value: string) => void;
    setIsDisabled?: (isDisabled: boolean) => void;
}

const {
    employeeTimeTracking: {
        defineTimeTrackingScheduleModal: {
            validationMessages: { requiredField },
        },
    },
} = en;

const TimePickerRecord = ({
    label,
    id,
    dataCy,
    fieldValue = '',
    disabled = false,
    onChange,
    setIsDisabled,
}) => {
    const [error, setError] = useState<boolean>(false);

    const handleOnChange = useCallback(
        newTime => {
            onChange(newTime);
        },
        [onChange]
    );

    useEffect(() => {
        setError(!fieldValue);
        setIsDisabled(error);
    }, [fieldValue, setIsDisabled, error]);

    return (
        <StyledFieldContainer>
            <StyledLabel test-id={dataCy}>{label}</StyledLabel>

            <TimePicker
                key={id}
                id={id}
                label={label}
                value={fieldValue}
                onChange={handleOnChange}
                hasError={error}
                placeholder="HH:MM"
                disabled={disabled}
            />

            {error && <FieldErrorMessage>{requiredField}</FieldErrorMessage>}
        </StyledFieldContainer>
    );
};

export { TimePickerRecord };

const TimeSection: React.FC<TimeSectionProps> = ({
    day,
    title,
    startTime,
    endTime,
    onDayChange,
    setIsDisabled,
}: TimeSectionProps) => (
    <TimeWrapper key="record-time">
        <RecordStartTime
            label={startTime.label}
            id={`${day[title]}-day-start-time`}
            dataCy={`${day[title]}-day-start-time-field`}
            fieldValue={day[title].startTime}
            onUpdate={time => onDayChange(startTime.fieldName, time)}
            setIsDisabled={setIsDisabled}
        />

        <RecordEndTime
            label={endTime.label}
            id={`${day[title]}-day-end-time`}
            dataCy={`${day[title]}-day-end-time-field`}
            fieldValue={day[title].endTime}
            onUpdate={time => onDayChange(endTime.fieldName, time)}
            setIsDisabled={setIsDisabled}
        />
    </TimeWrapper>
);

export default TimeSection;
